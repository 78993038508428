import React, { useEffect, useState } from "react";
import { FormGroup, FormText, Input, Label } from "reactstrap";
import style from "./style.module.css";
import { api } from "../../components/util/Data";
import { formValidation } from "../../components/util/validation";
import { getCookie } from "../../components/util/Cokkies";
import SelectJS from "../../components/elements/SelectJS";
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Button } from "react-bootstrap";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

function ProductUpdate() {
  const location = useLocation()
  console.log(location.state);
  const navigate = useNavigate()
  const [formData, setFormData] = useState(location.state);
  const [imageUrl, setImageUrl] = useState("");
  const [catGroupList, setCatGroupList] = useState([]);
  const [catList, setCatList] = useState([]);
  const [filterCat, setFilterCat] = useState([]);
  const [subCatList, setSubCatList] = useState([]);
  const [filterSubCat, setFilterSubCat] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [fileSelected, setFileSelected] = useState(false);
  let token = getCookie("token");

  useEffect(() => {
    axios
      .post(api.url + "/get-all-categories", { type: "all" })
      .then(function (response) {
        // console.log(response.data);
        setCatGroupList(response.data.categorygroup.map((el) => { return { value: el._id, label: el.categoryGroup, type: "catGroup" } }))
        setCatList(response.data.category.map((el) => { return { value: el._id, label: el.category, parent: el.categoryGroup._id, type: "category" } }))
        setSubCatList(response.data.subcategories.map((el) => { return { value: el._id, label: el.subCategory, parent: el.category._id, type: "subCat" } }))
      })
      .catch(function (error) {
        console.log(error);
      });

      setFormData({
        ...formData, preImage: location.state.image, category: location.state.category._id, categoryGroup: location.state.categoryGroup._id, subCategory: location.state.subCategory._id, subCatKey: location.state.subCategory.subCategory, catKey: location.state.category.category, catGroupKey: location.state.categoryGroup.categoryGroup
      });
  }, []);

  const getSelected = (select) => {
    if (select.type === "catGroup") {
      setFormData({ ...formData, categoryGroup: select.value, catGroupKey: select.label, category: "", subCategory: "" });
      setFilterCat(catList.filter((el) => el.parent === select.value))
    } else if (select.type === "category") {
      setFormData({ ...formData, category: select.value, catKey: select.label, subCategory: "" });
      setFilterSubCat(subCatList.filter((el) => el.parent === select.value))
    } else if (select.type === "company") {
      setFormData({ ...formData, company: select.value });
    } else {
      setFormData({ ...formData, subCategory: select.value, subCatKey: select.label });
    }
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const uploadLogoImg = (e) => {
    const file = e.target.files[0]
    var reader = new FileReader();
    const form = new FormData();
    if (e.target.files && file) {
      form.append("productFile", file, file.name);
      setUploadFiles(form);
      // processFile(file);
      setImageUrl(URL.createObjectURL(file));
      reader.readAsDataURL(file);
      if (file.size > 200000) {
        alert("Size sould less then 200 kb ");
        setFileSelected(false);
        setFormData({ ...formData, image: formData.preImage });
      } else {
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            var height = this.height;
            var width = this.width;
            if (width / height !== 1) {
              alert("Image's height & width sould be same");
              setFileSelected(false);
              setFormData({ ...formData, image: formData.preImage });
            } else {
              setFormData({ ...formData, image: file.name });
              setFileSelected(true);
            }
          };
        };
      }
    } else {
      setFileSelected(false);
      setFormData({ ...formData, image: formData.preImage });
    }
  };

  const submitHandler = async () => {
    if (formValidation([formData.category, formData.subCategory, formData.categoryGroup, formData.productName])) {
      if(fileSelected){
        axios
          .post(api.url + "/upload-productImage", uploadFiles)
          .then(function (response) {
            if (response.data.success) {
              updateProduct(formData, response.data.newFileName);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }else {
        updateProduct(formData, formData.preImage);
      }
    } else {
      alert("Please fill the required fields");
    }
  };

  const updateProduct = (data, newName) => {
    let newData = data;
    newData = { ...newData, image: newName };

    try {
      axios
        .post(api.url + "/update-product", newData, { headers: { authorization: "Bearer " + token } })
        .then(function (response) {
          if (response.data.success) {
            alert("Add Successfull");
            setFormData({});
            setFileSelected(false)
            navigate("/admin/products-list")
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const selectChange = (e, key) => {
    setFormData({ ...formData, [key]: e.target.value });
  };

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Update Product</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        {/* Add Categories */}
        <div>
          <Grid container spacing={1} className="form">
            <Grid item md={12} className={"w-100"}>
              <FormGroup>
                <Label className={style.formLabel} for="image">
                  Product Image <small className="text-warning">Image width and height should be same</small>
                </Label>
                <Input
                  bsSize="sm"
                  className={style.fileInput + " py-2"}
                  name="image"
                  accept="image/*"
                  onChange={(e) => uploadLogoImg(e)}
                  type="file"
                />
                <FormText><strong>Selected Image :  {formData.image} </strong></FormText>
              </FormGroup>
            </Grid>

            <Grid item md={6} className={"w-100 pt-4"}>
              <SelectJS options={catGroupList} placeholder={"Select Category Group...*"} defValue={{ value: formData.categoryGroup._id, label: formData.categoryGroup.categoryGroup}} selectedOption={getSelected} />
            </Grid>

            <Grid item md={6} className={"w-100 pt-4"}>
              <SelectJS options={filterCat} placeholder={"Select Category...*"} defValue={{value: formData.category._id, label: formData.category.category}} selectedOption={getSelected} />
            </Grid>

            <Grid item md={6} className={"w-100 pt-4"}>
              <SelectJS options={filterSubCat} placeholder={"Select Sub Category...*"} defValue={{value: formData.subCategory._id,label: formData.subCategory.subCategory}} selectedOption={getSelected} />
            </Grid>

            <Grid item md={6} className={"w-100 "}>
              <FormControl fullWidth size="small" className="my-3">
                <InputLabel id="business-type">Make it featured product</InputLabel>
                <Select
                  labelId="business-type"
                  id="business-type-select"
                  value={formData.featuredProduct}
                  label="Business Type"
                  required
                  onChange={(e) => selectChange(e, "featuredProduct")}
                >
                  <MenuItem value={false}>No</MenuItem>
                  <MenuItem value={true}>Yes</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item md={6} className={"w-100"}>
              <TextField size="small" className={style.formInput} name="productName" type="text" required label="Product Name" margin="normal" defaultValue={formData.productName} onChange={(e) => changeHandler(e)} variant="outlined" />
            </Grid>

            <Grid item md={6} className={"w-100"}>
              <TextField size="small" className={style.formInput} name="additionalLabel" type="text" label="Additional Label" margin="normal" defaultValue={formData.additionalLabel} onChange={(e) => changeHandler(e)} variant="outlined" />
            </Grid>

            <Grid item sm={12} className={"w-100 pt-4"}>
              <TextField className={style.formInput} maxRows={4} required name="productDetails" defaultValue={formData.productDetails} onChange={(e) => changeHandler(e)} label="Product Details" multiline />
            </Grid>
            <Grid item className={"w-100 pt-4"}>
              <Button onClick={(e) => submitHandler(e)} className="btn btn-sm px-3 ml-2">
                Update Product
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default ProductUpdate;
