import Slider from "react-slick";
import style from './style.module.css'
import { useRef } from "react";


const Testimonials = (props) => {

    const slider = useRef(null);
    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <>
            <Slider ref={slider} {...settings} className="style-4 dot-style-1 dot-style-1-position-1">
                {props.testimonials.map((item, i) => {
                    if (i < 5) {
                        return <div key={i} className={style.testimonialItem}>
                            <h5>{item.company}</h5>
                            <p>{item.message}</p>
                            <h6>{item.username}</h6>
                            <strong>{item.designation}</strong>
                        </div>
                    }
                })}
            </Slider>
        </>
    );
};

export default Testimonials;
