import React, { useEffect, useState } from "react";
import { FormText, Input } from "reactstrap";
import style from "./style.module.css";
import { Grid, IconButton, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { getCookie } from "../../components/util/Cokkies";
import { api } from "../../components/util/Data";
import DataTableComponent from "../../components/elements/DataTableComponent";
import { Button } from "react-bootstrap";
import axios from "axios";
import SelectJS from "../../components/elements/SelectJS";

function AddCategories() {
  let token = getCookie("token");
  const [catGroupList, setCatGroupList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [formData, setFormData] = useState({ category: "" });
  const [isSearch, setIsSearch] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [pending, setPending] = useState(true);
  const [filterItems, setFilterItems] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [updateQuery, setUpdateQuery] = useState(false);
  const [updateImage, setUpdateImage] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [tableOptions, setTableOptions] = useState({ perPage: 10, pageNo: 1, sortDirection: "desc", sortField: "createdAt" });
  const [fileSelected, setFileSelected] = useState(false);

  useEffect(() => {
    setPending(true)
    axios
      .post(api.url + "/get-categories-for-admin", { page: tableOptions.pageNo, sortDirection: tableOptions.sortDirection, sortField: tableOptions.sortField, perPage: tableOptions.perPage, key: filterValue })
      .then(function (response) {
        // console.log(response);
        response.data.category.forEach((item, index) => {
          item.serial = index + 1 + (tableOptions.perPage * tableOptions.pageNo - tableOptions.perPage);
        });
        setCategoriesList(response.data.category);
        setFilterItems(response.data.category);
        setTotalRows(response.data.total);
        setPending(false)
        setIsSearch(false)
        setCatGroupList(response.data.categorygroup.map((el) => { return { value: el._id, label: el.categoryGroup } }))
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [isSearch, tableOptions, updateQuery]);

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      width: "65px",
    },
    {
      name: <strong>CATEGORY</strong>,
      selector: (row) => row.category,
    },
    {
      name: <strong>CATEGORY GROUP</strong>,
      selector: (row) => row.categoryGroup.categoryGroup,
    },
    {
      name: <strong>IMAGE</strong>,
      selector: (row) => (
        <div>
          <img
            style={{ height: "50px" }}
            src={api.categoryImg + row.image}
            alt={row.category}
          />
        </div>
      ),
    },
    {
      name: <strong>ACTION</strong>,
      selector: (row) => (
        <>
          <IconButton onClick={(e) => {
            setUpdateQuery(true); setFormData({ "_id": row._id, "categoryGroup": row.categoryGroup._id, "keyWords": row.keyWords, "category": row.category, "image": row.image, "key": row.categoryGroup.categoryGroup })
          }} className={style.actionBtn} size="small">
            <EditIcon fontSize="small" />
          </IconButton>
          {" "}
          <IconButton onClick={(e) => deletehandle(row._id, row.image)} className={style.actionBtn} color="error" size="small">
            <DeleteIcon fontSize="small" />
          </IconButton>
        </>
      ),
      width: "100px",
    },
  ];

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const updateFuction = (e) => {
    if (updateImage) {
      submitHandler(e)
    } else {
      addCategory(formData, formData.image)
    }
  }

  const deletehandle = (id, image) => {
    try {
      axios
        .post(api.url + "/delete-category", { id: id, type: "category", image: image }, { headers: { authorization: "Bearer " + token } })
        .then(function (response) {
          // console.log(response);
          if (response.data.success) {
            alert("Delete Successfull");
            setFilterItems(categoriesList.filter((el) => {
              return id !== el._id
            }));
            setCategoriesList(categoriesList.filter((el) => {
              return id !== el._id
            }));
          }
        })
        .catch(function (error) {
          // console.log(error);
          alert(error.response.data.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (formData.category && formData.categoryGroup) {
      if (!fileSelected) {
        alert("Please add image")
      } else {
        axios
          .post(api.url + "/upload-category-img", uploadFiles)
          .then(function (response) {
            if (response.data.success) {
              addCategory(formData, response.data.newFileName);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    } else {
      alert("Please fill the required fields");
    }
  };

  const addCategory = (data, newFile) => {
    let newData = data;
    newData = { ...newData, image: newFile };
    axios
      .post(api.url + (updateQuery ? "/update-category" : "/add-category"), newData, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        console.log(response);
        if (response.data.success) {
          if (updateQuery) {
            alert("Update Successfull");
          } else {
            alert("Add Successfull");
            formData["_id"] = response.data.catId;

            setCategoriesList([...categoriesList, { serial: categoriesList.length + 1, _id: response.data.catId, category: formData.category, image: response.data.img, categoryGroup: { categoryGroup: formData.key } }]);

            setFilterItems([...filterItems, { serial: filterItems.length + 1, _id: response.data.catId, category: formData.category, image: response.data.img, categoryGroup: { categoryGroup: formData.key } }]);
            setFileSelected(false)
            setUpdateImage(false)
            setUploadFiles([])
            setFormData({});
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        alert(error.response);
      });
  }

  const reduceImg = (e) => {
    var reader = new FileReader();
    reader.onload = (e) => {
      var img = document.createElement("img");
      img.onload = () => {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var MAX_WIDTH = 500;
        var MAX_HEIGHT = 500;
        var width = img.width;
        var height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        var dataurl = canvas.toDataURL("image/png");
        setImageUrl(dataurl);
        setFileSelected(false)
        setUpdateImage(false)
      }
      img.src = e.target.result;
    }
    reader.readAsDataURL(e);
  }

  const uploadLogoImg = (e) => {
    const file = e.target.files[0]
    let postPhoto = [];
    var reader = new FileReader();
    const form = new FormData();
    if (e.target.files && file) {
      postPhoto.push(file.name);
      form.append("categoryfile", file, file.name);
      setUploadFiles(form);
      // processFile(file);
      setImageUrl(URL.createObjectURL(file));
      reader.readAsDataURL(file);
      if (file.size > 200000) {
        alert("Size sould less then 200 kb ");
        reduceImg(file)
      } else {
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            var height = this.height;
            var width = this.width;
            if (width / height !== 1) {
              alert("Image's height & width sould be same");
              reduceImg(file)
            } else {
              if (postPhoto.length) {
                setFormData({ ...formData, image: postPhoto[0] });
                setUpdateImage(true)
                setFileSelected(true);
              }
            }
          };
        };
      }
    } else {
      setFileSelected(false);
      setUpdateImage(false)
    }
  };

  const selectedOptionOutput = value => {
    setFormData({ ...formData, categoryGroup: value.value, key: value.label });
  }

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Add Category</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        {/* Add Categories */}
        <Grid container spacing={1} className="form">
          <Grid item className={"w-100 pt-4"}>
            {catGroupList.length ? <SelectJS options={catGroupList} placeholder={"Select Category Group..."} defValue={formData.country} selectedOption={selectedOptionOutput} /> : <SelectJS options={["loading"]} placeholder={"Select Category Group..."} defValue={""} selectedOption={selectedOptionOutput} />}
            <FormText>Selected : {formData.key}</FormText>
          </Grid>

          <Grid item md={12} className={"w-100"}>
            <Input
              bsSize="sm"
              className={style.fileInput + " py-2 my-2"}
              name="image"
              accept="image/*"
              onChange={(e) => uploadLogoImg(e)}
              type="file"
            />
            <FormText>selected Image : <a href={api.categoryImg + formData.image} target="_" >{formData.image}</a> </FormText>
          </Grid>
          <Grid item md={12} className={"w-100"}>
            <TextField size="small" className={style.formInput} name="category" type="text" required label="Category" margin="normal" value={formData.category} onChange={(e) => changeHandler(e)} variant="outlined" />
          </Grid>
          <Grid item>
            {updateQuery ? <Button onClick={(e) => updateFuction(e)} className="btn btn-sm px-3 ml-2">
              Update Category
            </Button> : <Button onClick={(e) => submitHandler(e)} className="btn btn-sm px-3 ml-2">
              Add Category
            </Button>}
          </Grid>
        </Grid>

        {/* Table */}
        <p className="m-0 text-dark py-2">
          {" "}
          <strong>Category List :</strong>
        </p>

        <DataTableComponent
          pageChange={(e) => { setTableOptions({ ...tableOptions, pageNo: e }) }}
          perRowsChange={(e) => setTableOptions({ ...tableOptions, perPage: e.perPage, pageNo: 1 })}
          setHandleSort={(e) => setTableOptions({ ...tableOptions, sortDirection: e.sortDirection, sortField: e.sortField })}
          expandable={false}
          inputFilterValue={filterValue}
          setSearchTrue={(e) => setIsSearch(true)}
          inputFilter={(e) => setFilterValue(e)}
          columnsData={columns}
          dataLoad={pending}
          totalRowsData={totalRows}
          data={filterItems} />
      </div>
    </div>
  );
}

export default AddCategories;
