import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import moment from "moment";
import EditIcon from '@mui/icons-material/Edit';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from "react-router-dom";
import { getCookie } from "../../components/util/Cokkies";
import { api } from "../../components/util/Data";
import DataTableComponent from "../../components/elements/DataTableComponent";
import axios from "axios";

function CompanyList() {
  const [pending, setPending] = useState(true);
  const [filterValue, setFilterValue] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [filterItems, setFilterItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [tableOptions, setTableOptions] = useState({ perPage: 10, pageNo: 1, sortDirection: "desc", sortField: "createdAt" });
  let token = getCookie("token");

  const planArray = [{ plan: "Guest", leads: 0 }, { plan: "Economy", leads: 10 }, { plan: "Gold", leads: 30 }, { plan: "Platinum", leads: 60 }, { plan: "Platinum Pro", leads: 80 }, { plan: "Global", leads: 100 }]

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      width: "65px",
    },
    {
      name: <strong>FULL NAME</strong>,
      selector: (row) => row.firstName + " " + row.lastName,
      sortable: true,
      width: "150px",
      sortField: "firstName"
    },
    {
      name: <strong>COMPANY NAME</strong>,
      selector: (row) => row.company,
      sortable: true,
      width: "200px",
      sortField: "company"
    },
    {
      name: <strong>CONTACT</strong>,
      selector: (row) => row.contact,
      sortable: true,
      width: "150px",
      sortField: "contact"
    },
    {
      name: <strong>PLAN</strong>,
      selector: (row) => planArray[row.membershipPlan].plan,
      sortable: true,
      width: "100px",
      sortField: "membershipPlan"
    },
    {
      name: <strong>DATE</strong>,
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      sortable: true,
      width: "100px",
      sortField: "createdAt"
    },
    {
      name: <strong>ACTION</strong>,
      cell: (row) => (
        <>
          <Link to={"/admin/update-company"} state={row}>
            <IconButton>
              <EditIcon fontSize="small" color="info" />
            </IconButton>
          </Link>
          <Link to={"/admin/change-user-password"} state={row}>
            <IconButton>
              <VpnKeyIcon fontSize="small" color="success" />
            </IconButton>
          </Link>
          <IconButton onClick={(e) => window.confirm("Remove : " + row.company)? deleteCompany(row._id) : ""}>
            <DeleteIcon fontSize="small" color="error" />
          </IconButton>
        </>
      ),
    },
  ];

  const deleteCompany = (id) => {
    axios
    .post(api.url + "/delete-company", {id:id}, { headers: { authorization: "Bearer " + token } })
    .then(function (response) {
      console.log(response);
      if(response.data.success){
        alert("Deleted")
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  useEffect(() => {
    setPending(true)
    axios
      .post(api.url + "/get-company-for-admin", { page: tableOptions.pageNo, sortDirection: tableOptions.sortDirection, sortField: tableOptions.sortField, perPage: tableOptions.perPage, key: filterValue }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        response.data.users.forEach((item, index) => {
          item.serial = index + 1 + (tableOptions.perPage * tableOptions.pageNo - tableOptions.perPage);
        });
        setFilterItems(response.data.users);
        setTotalRows(response.data.total);
        setIsSearch(false)
        setPending(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [tableOptions, token, isSearch]);

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Users List</h4>
          <hr className="my-2" />
        </div>
      </div>

      <DataTableComponent
        pageChange={(e) => { setTableOptions({ ...tableOptions, pageNo: e }) }}
        perRowsChange={(e) => setTableOptions({ ...tableOptions, perPage: e.perPage, pageNo: 1 })}
        setHandleSort={(e) => setTableOptions({ ...tableOptions, sortDirection: e.sortDirection, sortField: e.sortField })}
        columnsData={columns}
        expandable={false}
        inputFilterValue={filterValue}
        setSearchTrue={(e) => setIsSearch(true)}
        inputFilter={(e) => setFilterValue(e)}
        dataLoad={pending}
        totalRowsData={totalRows}
        data={filterItems} />
    </div>
  );
}

export default CompanyList;


