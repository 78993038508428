import Slider from "react-slick";
import { api } from "../util/Data";
import { useRef } from "react";


const Intro2 = (props) => {

    const slider = useRef(null);
    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <>
            <Slider ref={slider} {...settings} className="style-4 dot-style-1 dot-style-1-position-1">
                {props.banners.map((item, i) => (
                    <div key={i}
                        className="single-animation-wrap banner-item"
                    >
                        <img style={{width:"100%", borderRadius:"30px"}} src={api.bannerUrl + item} alt="" />
                    </div>
                ))}
            </Slider>

            <div className="slider-arrow hero-slider-1-arrow">
                <span onClick={() => slider?.current?.slickPrev()} className="slider-btn slider-prev slick-arrow custom_prev_i1">
                    <i className="fi-rs-angle-left"></i>
                </span>
                <span onClick={() => slider?.current?.slickNext()} className="slider-btn slider-next slick-arrow custom_next_i1">
                    <i className="fi-rs-angle-right"></i>
                </span>
            </div>
        </>
    );
};

export default Intro2;
