import React from 'react'
import { useNavigate, useOutletContext } from "react-router-dom";
import { Button } from 'reactstrap';


export default function CatalogContact() {
  const [companyDetails] = useOutletContext();
  const navigate = useNavigate()
  return (
    <div className="col-xl-10 m-auto">
    <div className="my-4">
      <div className="row">
        <div className="col-md-6">
          <h4 className="section-title style-1 mb-15"><span className='text-muted'>Reach to us : </span></h4>
          <p><i style={{ width: "20px" }} className="fa-solid fa-location-dot"></i> {companyDetails.companyAddress}</p>
          <p><i style={{ width: "20px" }} className="fa-solid fa-user-tie"></i> {companyDetails.firstName + " " + companyDetails.lastName}</p>
          <p><i style={{ width: "20px" }} className="fa-solid fa-phone"></i> <span> xxxxx xxxxx </span> &nbsp; &nbsp; <i style={{ width: "20px" }} className="fa-regular fa-envelope"></i> <span>
            xxxxxxxxx@xxxxx.xxx </span>
          </p>
          <Button size='sm' className='mt-20' onClick={(e) => { navigate("/requirement") }}>View Contact</Button>
        </div>
        <div className="col-md-6">
        <h4 className="section-title style-1 mb-15"><span className='text-muted'>Contact Us - {companyDetails.company} </span></h4>

          <div className={"contact-form-style mt-30 "} id="contact-form" action="#" method="post">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="input-style mb-20">
                  <input className="py-4" name="name" placeholder="First Name" type="text" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="input-style mb-20">
                  <input className="py-4" name="telephone" placeholder="Your Phone" type="tel" />
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="textarea-style mb-30">
                  <textarea name="message" placeholder="Message"></textarea>
                </div>
                <button className="submit submit-auto-width px-4 py-2" type="submit">Send message</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}
