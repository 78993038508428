import React, { useState } from "react";
import style from "./style.module.css";
import { getCookie } from "../../components/util/Cokkies";
import { formValidation } from "../../components/util/validation";
import { api } from "../../components/util/Data";
import axios from "axios";
import { Grid, TextField } from "@mui/material";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function AddTestimonial() {
  const navigate = useNavigate()

  const [formData, setFormData] = useState({company:"", username: "", designation: "", message: ""});
  let token = getCookie("token");

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitHandler = async (e) => {
    if (formValidation([formData.company, formData.username, formData.designation, formData.message])) {
      axios
        .post(api.url + "/add-testimonial", formData, { headers: { authorization: "Bearer " + token } })
        .then(function (response) {
          if (response.data.success) {
            alert("Add Successfull");
            setFormData({company:"", username: "", designation: "", message: ""});
            navigate("/admin/leads-list")
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      alert("All fields are required");
    }
  };

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Add Testimonial</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        {/* Add Categories */}
        <div>
          <Grid container spacing={1} className="mt-4">
            <Grid item md={12} className={"w-100"}>
              <TextField size="small" className={style.formInput} name="company" type="text" label="Company" margin="normal" defaultValue={formData.company} onChange={(e) => changeHandler(e)} variant="outlined" />
            </Grid>
            <Grid item md={6} className={"w-100"}>
              <TextField size="small" className={style.formInput} name="username" type="text" label="Name" margin="normal" defaultValue={formData.username} onChange={(e) => changeHandler(e)} variant="outlined" />
            </Grid>
            <Grid item md={6} className={"w-100"}>
              <TextField size="small" className={style.formInput} name="designation" type="text" label="Designation" margin="normal" defaultValue={formData.designation} onChange={(e) => changeHandler(e)} variant="outlined" />
            </Grid>

            <Grid item md={12} className={"w-100 pt-4"}>
              <TextField className={style.formInput} maxRows={6} required name="message" defaultValue={formData.message} onChange={(e) => changeHandler(e)} label="Message" multiline />
            </Grid>

            <Grid item className={"w-100 pt-4"}>
              <Button onClick={(e) => submitHandler(e)} className="btn btn-sm px-3 ml-2">
                Add Testimonial
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default AddTestimonial;
