import { createSlice } from '@reduxjs/toolkit'


export const categoryLoged = createSlice({
  name: 'CategoryState',
  initialState: {
    catGroup: [],
    category: [],
    subCategory: []
  },
  reducers: {
    getCategoryLoged: (state, action) => {
      state.catGroup = action.payload.categoriesgroup
      state.category = action.payload.categories
      state.subCategory = action.payload.subCategories
    }
  },
})

export const { getCategoryLoged } = categoryLoged.actions

export default categoryLoged.reducer