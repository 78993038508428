import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import MobileMenu from "./MobileMenu";
import GoToTop from "./GoToTop";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { mainReduxFunction } from "../util/mainRedux";
import style from './style.module.css'

const Layout = () => {
    const disPatch = useDispatch()
    const [isToggled, setToggled] = useState(false);
    
    useEffect(() => {
        mainReduxFunction(disPatch)
    }, [disPatch])
    
    const toggleClick = () => {
        setToggled(!isToggled);
        isToggled
            ? document
                .querySelector("body")
                .classList.remove("mobile-menu-active")
            : document
                .querySelector("body")
                .classList.add("mobile-menu-active");
    };
    return (
        <>
            {isToggled && <div className="body-overlay-1" onClick={toggleClick}></div>}

            <Header headerStyle={"header-style-1"} isToggled={isToggled} toggleClick={toggleClick} />
            <MobileMenu isToggled={isToggled} toggleClick={toggleClick} />
            <div className={style.main}>
                {/* {parent ? <Breadcrumb parent={parent} sub={sub} subChild={subChild} noBreadcrumb={noBreadcrumb} /> : ""} */}
                <Outlet />
            </div>
            <GoToTop />
            <Footer />
        </>
    );
};

export default Layout;
