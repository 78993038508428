import React from 'react'
import Intro2Cat from '../../components/sliders/Intro2Cat'

export default function CatalogBanner({banners}) {
    return (
        
        <section className="home-slider position-relative row">
            <div className="home-slide-cover mt-15">
                <Intro2Cat banners={banners} />
            </div>
        </section>
    )
}
