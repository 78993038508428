import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { Grid, IconButton, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { getCookie } from "../../components/util/Cokkies";
import { api } from "../../components/util/Data";
import DataTableComponent from "../../components/elements/DataTableComponent";
import { Button } from "react-bootstrap";
import axios from "axios";

function AddCareers() {
    let token = getCookie("token");
    const [formData, setFormData] = useState({ description: "", experience: "", designation: "" });
    const [isSearch, setIsSearch] = useState(false);
    const [filterValue, setFilterValue] = useState('');
    const [pending, setPending] = useState(true);
    const [dataList, setDataList] = useState([]);
    const [filterItems, setFilterItems] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [tableOptions, setTableOptions] = useState({ perPage: 10, pageNo: 1, sortDirection: "desc", sortField: "createdAt" });

    useEffect(() => {
        setPending(true)
        axios
            .post(api.url + "/get-careers-for-admin", { page: tableOptions.pageNo, sortDirection: tableOptions.sortDirection, sortField: tableOptions.sortField, perPage: tableOptions.perPage, key: filterValue })
            .then(function (response) {
                console.log(response);
                if (response.data.careers.length) {
                    response.data.careers.forEach((item, index) => {
                        item.serial = index + 1 + (tableOptions.perPage * tableOptions.pageNo - tableOptions.perPage);
                    });
                }
                setDataList(response.data.careers);
                setFilterItems(response.data.careers);
                setTotalRows(response.data.total);
                setPending(false)
                setIsSearch(false)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [isSearch, tableOptions]);

    const columns = [
        {
            name: <strong>S.No.</strong>,
            selector: (row) => row.serial,
            width: "65px",
        },
        {
            name: <strong>Designation</strong>,
            selector: (row) => row.designation,
            width: "200px",
        },
        {
            name: <strong>Experience</strong>,
            selector: (row) => row.experience,
            width: "150px",
        },
        {
            name: <strong>Details</strong>,
            selector: (row) => row.description
        },
        {
            name: <strong>ACTION</strong>,
            selector: (row) => (
                <IconButton onClick={(e) => deletehandle(row._id)} className={style.actionBtn} color="error" size="small">
                    <DeleteIcon fontSize="small" />
                </IconButton>
            ),
            width: "100px",
        },
    ];

    const changeHandler = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const deletehandle = (id) => {
        try {
            axios
                .post(api.url + "/delete-career", { id: id }, { headers: { authorization: "Bearer " + token } })
                .then(function (response) {
                    // console.log(response);
                    if (response.data.success) {
                        alert("Delete Successfull");
                        setFilterItems(dataList.filter((el) => {
                            return id !== el._id
                        }));
                        setDataList(dataList.filter((el) => {
                            return id !== el._id
                        }));
                    }else{
                        alert("Denied");
                    }
                })
                .catch(function (error) {
                    // console.log(error);
                    alert(error.response.data.message);
                });
        } catch (error) {
            console.log(error);
        }
    };

    const submitHandler = (e) => {
        e.preventDefault();
        if (formData.designation && formData.description && formData.experience) {
            axios
                .post(api.url + "/add-career", formData, { headers: { authorization: "Bearer " + token } })
                .then(function (response) {
                    //   console.log(response);
                    alert("Add Successfull");

                    setDataList([...dataList, { serial: filterItems.length + 1, _id: response.data.careers._id, experience: response.data.careers.experience, designation: response.data.careers.designation, description: response.data.careers.description }]);

                    setFilterItems([...filterItems, { serial: filterItems.length + 1, _id: response.data.careers._id, experience: response.data.careers.experience, designation: response.data.careers.designation, description: response.data.careers.description }]);

                    setFormData({ description: "", experience: "", designation: "" })
                })
                .catch(function (error) {
                    console.log(error);
                    alert(error.response);
                });
        } else {
            alert("Please fill the all fields");
        }
    };

    return (
        <div>
            <div className="content-header p-0">
                <div className="container-fluid">
                    <h4 className="m-0 text-dark">Careers</h4>
                    <hr className="my-2" />
                </div>
            </div>
            <div className="container px-4">
                {/* Add Categories */}
                <Grid container spacing={1} className="form">
                    <Grid item md={6} className={"w-100"}>
                        <TextField size="small" className={style.formInput} name="designation" type="text" required label="Designation" margin="normal" value={formData.designation} onChange={(e) => changeHandler(e)} variant="outlined" />
                    </Grid>
                    <Grid item md={6} className={"w-100"}>
                        <TextField size="small" className={style.formInput} name="experience" type="text" required label="Experience" margin="normal" value={formData.experience} onChange={(e) => changeHandler(e)} variant="outlined" />
                    </Grid>
                    <Grid item md={12} className={"w-100"}>
                        <TextField className={style.formInput} maxRows={6} required name="description" defaultValue={formData.description} onChange={(e) => changeHandler(e)} label="Description" multiline />
                    </Grid>
                    <Grid item>
                        <Button onClick={(e) => submitHandler(e)} className="btn btn-sm px-3 ml-2">
                            Add Career
                        </Button>
                    </Grid>
                </Grid>

                {/* Table */}
                <p className="m-0 text-dark py-2">
                    {" "}
                    <strong>Careers List :</strong>
                </p>

                <DataTableComponent
                    pageChange={(e) => { setTableOptions({ ...tableOptions, pageNo: e }) }}
                    perRowsChange={(e) => setTableOptions({ ...tableOptions, perPage: e.perPage, pageNo: 1 })}
                    setHandleSort={(e) => setTableOptions({ ...tableOptions, sortDirection: e.sortDirection, sortField: e.sortField })}
                    expandable={false}
                    inputFilterValue={filterValue}
                    setSearchTrue={(e) => setIsSearch(true)}
                    inputFilter={(e) => setFilterValue(e)}
                    columnsData={columns}
                    dataLoad={pending}
                    totalRowsData={totalRows}
                    data={filterItems} />
            </div>
        </div>
    );
}

export default AddCareers;
