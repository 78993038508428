import { useState, useEffect } from "react";
import { api } from "../components/util/Data";
import axios from "axios";
import { getCookie, setCookie } from "../components/util/Cokkies";
import { setLocalStorage } from "../components/util/localStore";
import { Link } from "react-router-dom";


function Login() {
    let token = getCookie("token")
    const [formData, setFormData] = useState({})
    useEffect(() => {
        if (token) {
            window.location.replace("/admin")
        }
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [token])

    const changeHandler = (e)=>{
            setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const loginForm = (e) => {
        e.preventDefault();
        axios.post(api.url + '/login', formData)
          .then(function (response) {
            if (response.data) {
              setLocalStorage('user', { name: response.data.name, id: response.data.id, email: response.data.email, role: response.data.role })
              setCookie('token', response.data.token, 1)
              window.location.replace("/admin")
            }
          })
          .catch(function (error) {
            console.log(error);
            alert(error.response.data.message)
          })
     };

    return (
        <>
                <div className="page-content login-page pt-52 pb-20">
                    <div className="container p-4">
                        <div className="row">
                            <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
                                <div className="row">
                                    <div className="col-lg-6 pr-30 d-none d-lg-block bg-white border-radius-15">
                                        <img className="border-radius-15" src="assets/imgs/page/login-1.webp" alt="" />
                                    </div>
                                    <div className="col-lg-6 col-md-8 pt-40">
                                        <div className="login_wrap widget-taber-content background-white">
                                            <div className="padding_eight_all bg-white border-radius-15 p-4">
                                                <div className="heading_s1 mb-30">
                                                    <h1 className="mb-5">Login</h1>
                                                </div>
                                                <form onSubmit={loginForm}>
                                                    <div className="form-group">
                                                        <input type="text" required="" name="email" onChange={changeHandler} placeholder="Username or Email *" />
                                                    </div>
                                                    <div className="form-group">
                                                        <input required="" type="password" name="password" onChange={changeHandler} placeholder="Your password *" />
                                                    </div>
                                                    {/* <div className="login_footer form-group">
                                                    <div className="chek-form">
                                                        <input type="text" required="" name="email" placeholder="Security code *" />
                                                    </div>
                                                    <span className="security-code">
                                                        <b className="text-new">8</b>
                                                        <b className="text-hot">6</b>
                                                        <b className="text-sale">7</b>
                                                        <b className="text-best">5</b>
                                                    </span>
                                                </div> */}
                                                    {/* <div className="login_footer form-group mb-50">
                                                    <div className="chek-form">
                                                    <div className="custome-checkbox">
                                                            <input className="form-check-input" type="checkbox" name="checkbox" id="exampleCheckbox1" value="" />
                                                            <label className="form-check-label" htmlFor="exampleCheckbox1"><span>Remember me</span></label>
                                                            </div>
                                                    </div>
                                                    <a className="text-muted" href="#">Forgot password?</a>
                                                </div> */}
                                                    <div className="form-group">
                                                        <button className="btn btn-heading btn-block hover-up" name="login">Log in</button>
                                                    </div>
                                                </form>
                                                <p className="mb-30 mt-30">Don't have an account? <Link to="/join">Join now</Link></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
}

export default Login;
