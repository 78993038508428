import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CatCardItem from '../components/elements/CatCardItem';
import { api } from '../components/util/Data'
import axios from 'axios';

export default function GroupDirectory() {
    const params = useParams();
    const [groupData, setGroupData] = useState([])
    const [categoryData, setCategoryData] = useState([])
  
    useEffect(() => {
      axios
        .post(api.url + "/get-group-directory", { category: params.category })
        .then(function (response) {
        //   console.log(response.data);
       const newList = response.data.category.map((el)=>{
            const listing = response.data.subCategory.filter(ele=> ele.category === el._id)
            return {...el, items : listing}
        })
          setCategoryData(newList)
          setGroupData(response.data.group)
        })
        .catch(function (error) {
          console.log(error);
        });
    }, [params])
    

    return (
        <div className="my-4">
            <div className="archive-header-2 text-center">
                <h3 className="display-7 mb-50"><strong><span className='text-brand'>{groupData.categoryGroup}</span> Directory</strong></h3>
            </div>
            <div className="row">
                <div className="col-lg-11 mx-auto row">
                    {categoryData.map((ele, i) => {
                        return <div key={i} className="col-lg-3 col-md-6 col-sm-6"> <CatCardItem data={ele} /> </div>
                    })}
                        {/* <div className="col-md-3">
                            <div className="card-1">
                                <figure className=" img-hover-scale overflow-hidden">
                                    <Link to={"/products/" + makeLink(ele.subCategory)}>
                                        <img
                                            src={api.categoryImg + ele.image}
                                            alt={ele.subCategory}
                                        />
                                    </Link>
                                </figure>
                                <h6>
                                    <Link to={"/products/" + makeLink(ele.subCategory)}>
                                        {ele.subCategory}
                                    </Link>
                                </h6>
                            </div>
                        </div> */}
                </div>
            </div>
        </div>
    )
}
