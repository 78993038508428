import React, { useMemo, useState } from 'react'
import SelectJS from './SelectJS'
import countriesArray from './country'
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { formValidation } from '../util/validation';
import axios from 'axios';
import { api } from '../util/Data';

export default function InquiryForm(props) {
    const options = useMemo(() => countriesArray.map((el) => { return { label: el.name, value: el.name, code: el.callingCodes[0], flag: el.flags.svg } }), [])
    const [formData, setFormData] = useState({message : "", name:"", contact:"", email:""})

    const selectedOptionOutput = value => {
        setFormData({ ...formData, country: value.value, callCode: value.code });
    }

    const changeHandler = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const submitHandler = async (e) => {
        if (formValidation([formData.message, formData.name, formData.contact, formData.email, formData.country])) {
          axios
            .post(api.url + "/add-requirement", formData)
            .then(function (response) {
              if (response.data.success) {
                alert("We will contact you quickly");
                setFormData({message : "", name:"", contact:"", email:""})
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          alert("All fields are required");
        }
      };

    return (
        <div> <h4 className="section-title style-1 mb-30  animated animated">
            {props.formTitle}</h4>
            <div className="requirement-form mt-30">
                <div className="input-style mb-20">
                    <input className="form-control form-control-sm" placeholder="Name..." type="text" name="name" value={formData.name} onChange={(e) => changeHandler(e)} />
                </div>
                <div className="input-style mb-20">
                    <SelectJS sm={"sm"} options={options} placeholder={"Country...*"} defValue={formData.country} selectedOption={selectedOptionOutput} />
                </div>
                <div className="input-style mb-20 search-form">
                    <InputGroup className="mb-3">
                        <InputGroup.Text className='py-0' id="basic-addon1">+{formData.callCode}</InputGroup.Text>
                        <Form.Control
                            placeholder="Contact No..."
                            className="form-control form-control-sm"
                            aria-label="Username"
                            name="contact"
                            value={formData.contact}
                            type='number'
                            aria-describedby="basic-addon1"
                            onChange={(e) => changeHandler(e)}
                        />
                    </InputGroup>
                </div>
                <div className="input-style mb-20">
                    <input className="form-control form-control-sm" placeholder="Email..." name="email" type="email" value={formData.email} onChange={(e) => changeHandler(e)} />
                </div>
                <div className="input-style mb-20">
                    <textarea className="form-control form-control-sm" style={{ minHeight: "60px" }} name="message" value={formData.message} onChange={(e) => changeHandler(e)} placeholder="Subject..." ></textarea>
                </div>
                <button className="btn border-0 py-2" onClick={(e) => submitHandler(e)} type="submit">Submit</button>
            </div></div>
    )
}
