import { createSlice } from '@reduxjs/toolkit'


export const lazyloadSlice = createSlice({
  name: 'LazyLoad',
  initialState : {
    load: false,
  },
  reducers: {
    loaded: (state) => {
      state.load = true
    },
    unloaded: (state) => {
      state.load = false
    },
  },
})

export const { loaded, unloaded } = lazyloadSlice.actions

export default lazyloadSlice.reducer