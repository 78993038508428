import { getCookie } from "./Cokkies";
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2'

export function formValidation(data) {
  let isValid = true;
  data.forEach(el => {
    if (el === "" || el === undefined || el === false) {
      isValid = false;
    }
  });
  return isValid;
  // if (isValid) {
  //   return true;
  // } else {
  //   return false;
  // }
}

export function userValidate(data, tokenfor) {
  const token = getCookie(tokenfor);
  if (token !== undefined) {
    var decode = jwt_decode(token);
    const role = decode.id.split("-")[1];
    let isValid = false;

    data.forEach(el => {
      if (el === role) {
        isValid = true;
      }
    });
    return isValid;
  // if (isValid) {
  //   return true;
  // } else {
  //   return false;
  // }
  }
}

export function alcUserValidate(data) {
  const token = getCookie("alcToken");
  if (token !== undefined) {
    var decode = jwt_decode(token);
    const role = decode.id.split("-")[1];
    let isValid = false;

    data.forEach(el => {
      if (el === role) {
        isValid = true;
      }
    });
    return isValid;
  // if (isValid) {
  //   return true;
  // } else {
  //   return false;
  // }
  }
}

export function SweetAlert(data) {

  var Toast = Swal.mixin({
    
    background: "rgb(19 146 189)",
    showConfirmButton: false,
    timerProgressBar: true,
    showCloseButton: true,
    color:"white",
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
      // Swal.showLoading()
    }
  });

  Toast.fire(data)
}


