import React, { useState } from "react";
import { Link } from "react-router-dom";
import style from './style.module.css'
import { formValidation } from '../util/validation';
import axios from 'axios';
import { api } from '../util/Data';

const CatalogFooter = () => {
    const [formData, setFormData] = useState({message : "", name:"", contact:"", email:""})

    const changeHandler = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const submitHandler = async (e) => {
        if (formValidation([formData.message, formData.name, formData.contact, formData.email])) {
          axios
            .post(api.url + "/add-requirement", formData)
            .then(function (response) {
              if (response.data.success) {
                alert("We will contact you quickly");
                setFormData({message : "", name:"", contact:"", email:""})
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          alert("All fields are required");
        }
      };
    return (
        <footer className="main">
            <div className="page-content pt-30">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-11 col-lg-12 px-5 mb-15 m-auto" style={{ background: "#E3F5FA", borderRadius: "20px" }}>
                            <section className="mb-30">
                                <div className="row align-items-center">
                                    <div className="col-xl-8">
                                        <div className="contact-from-area padding-20-row-col">
                                            <h4 className="mb-10">Send Inquiry</h4>
                                            <div className={"contact-form-style mt-30 " + style.catalogFooter} id="contact-form" action="#" method="post">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="input-style mb-20">
                                                            <input className="py-4" name="name" onChange={(e) => changeHandler(e)} placeholder="Full Name" type="text" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="input-style mb-20">
                                                            <input className="py-4" name="email" onChange={(e) => changeHandler(e)} placeholder="Your Email" type="email" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="input-style mb-20">
                                                            <input className="py-4" name="contact" onChange={(e) => changeHandler(e)} placeholder="Your Phone" type="tel" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="input-style mb-20">
                                                            <input className="py-4" name="subject" onChange={(e) => changeHandler(e)} placeholder="Subject" type="text" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="textarea-style mb-30">
                                                            <textarea name="message" onChange={(e) => changeHandler(e)} placeholder="Message"></textarea>
                                                        </div>
                                                        <button className="submit submit-auto-width px-4 py-2" onClick={(e) => submitHandler(e)}>Send message</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="form-messege"></p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 pl-50 d-lg-block d-none">
                                        <img width={300} className="border-radius-15 mt-50" src="/assets/imgs/page/about-10.png" alt="Top B2B portal" />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            <section className="section-padding footer-mid">
                <div className="container pt-15 pb-20 px-5">
                    <div className="row">
                        <div className="col">
                            <div className="widget-about font-md mb-md-3 mb-lg-3 mb-xl-0 wow animate__animated animate__fadeInUp" data-wow-delay="0">
                                <div className="logo mb-20">
                                    <Link to="/">
                                        <img style={{ width: "12rem" }}
                                            src="/logo.png"
                                            alt="logo"
                                        />
                                    </Link>
                                    <p className="font-lg text-heading">
                                        Best Business to Business platform
                                    </p>
                                </div>
                                <ul className="contact-infor">
                                    <li style={{ display: "flex", flexWrap: "nowrap" }}>
                                        <img
                                            src="/assets/imgs/theme/icon-location.svg"
                                            alt=""
                                        />
                                        <span>
                                            RZH-20, Raghu Nager, Janakpuri, Dabri, New Delhi 110045
                                        </span>
                                    </li>
                                    <li>
                                        <img
                                            src="/assets/imgs/theme/icon-email-2.svg"
                                            alt=""
                                        />
                                        <span>sales@webtradesolution.com</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className="footer-link-widget col  wow animate__animated animate__fadeInUp"
                            data-wow-delay=".1s"
                        >
                            <h4 className="widget-title">Company</h4>
                            <ul className="footer-list  mb-sm-5 mb-md-0">
                                <li>
                                    <Link to="/about">About Us</Link>
                                </li>
                                <li>
                                    <Link to="/policy">Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to="/terms">Terms &amp; Conditions</Link>
                                </li>
                                <li>
                                    <Link to="/contact">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                        <div
                            className="footer-link-widget col  wow animate__animated animate__fadeInUp"
                            data-wow-delay=".2s"
                        >
                            <h4 className="widget-title ">Our Service</h4>
                            <ul className="footer-list  mb-sm-5 mb-md-0">
                                <li>
                                    <Link to="/plans">Membership Plan</Link>
                                </li>
                                <li>
                                    <Link to="/plans">Advertise with Us</Link>
                                </li>
                                <li>
                                    <Link to="/join">Banner Advertisement</Link>
                                </li>
                                <li>
                                    <Link to="/careers">Careers</Link>
                                </li>
                            </ul>
                        </div>
                        <div
                            className="footer-link-widget col  wow animate__animated animate__fadeInUp"
                            data-wow-delay=".3s"
                        >
                            <h4 className="widget-title ">Buyers Link</h4>
                            <ul className="footer-list  mb-sm-5 mb-md-0">
                                <li>
                                    <Link to="/requirement">Post Requirement</Link>
                                </li>
                                <li>
                                    <Link to="/companies">Browse Suppliers</Link>
                                </li>
                                <li>
                                    <Link to="/join">Join Free</Link>
                                </li>
                                <li>
                                    <Link to="/faq">FAQ</Link>
                                </li>
                            </ul>
                        </div>

                        <div
                            className="footer-link-widget widget-install-app col  wow animate__animated animate__fadeInUp"
                            data-wow-delay=".5s"
                        >
                            <h4 className="widget-title ">Secured Payment</h4>

                            <img
                                className=""
                                src="/assets/imgs/theme/payment-method.png"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </section>

            <div
                className="container pb-30 footer-copyright wow animate__animated animate__fadeInUp"
                data-wow-delay="0"
            >
                <div className="row align-items-center">
                    <div className="col-12 mb-30">
                        <div className="footer-bottom"></div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6">
                        <p className="font-sm mb-0 ">
                            &copy; 2021,{" "}
                            <strong className="text-brand"> <a href="https://webtradesolution.com/" target="_">Web Trade Solution</a></strong> - All rights reserved
                        </p>
                    </div>
                    <div className="col-xl-4 col-lg-6 text-center d-none d-xl-block">

                        <div className="hotline d-lg-inline-flex">
                            <img src="/assets/imgs/theme/phone-call.svg" alt="hotline" style={{ filter: "invert(1)" }} />
                            <p>
                                +91-8810200550<span className="">24/7 Support Center</span>
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 text-end d-none d-md-block">
                        <div className="mobile-social-icon">
                            <h6 className="">Follow Us</h6>
                            <Link to="#">
                                <img
                                    src="/assets/imgs/theme/icon-facebook-white.svg"
                                    alt=""
                                />
                            </Link>
                            <Link to="#">
                                <img
                                    src="/assets/imgs/theme/icon-twitter-white.svg"
                                    alt=""
                                />
                            </Link>
                            <Link to="#">
                                <img
                                    src="/assets/imgs/theme/icon-instagram-white.svg"
                                    alt=""
                                />
                            </Link>
                            <Link to="#">
                                <img
                                    src="/assets/imgs/theme/icon-pinterest-white.svg"
                                    alt=""
                                />
                            </Link>
                            <Link to="#">
                                <img
                                    src="/assets/imgs/theme/icon-youtube-white.svg"
                                    alt=""
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default CatalogFooter;
