import React from 'react'
import { Image } from 'antd';
import { useOutletContext } from 'react-router-dom';
import { api } from '../../components/util/Data';

export default function CatalogGallery() {
  const [companyDetails] = useOutletContext();

  return (
    <div className="col-xl-10 m-auto">
      <div className="my-4">
        <h4 className="section-title style-1 mb-15"><span className='text-muted'>Products Gallery : </span></h4>
        <Image.PreviewGroup
          preview={{
            onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
          }}
        >
          {companyDetails.gallery.map((el, i) => {
            return <Image height={200} className='p-2' src={api.galleryUrl + el} />
          })}
        </Image.PreviewGroup>
      </div>
    </div>
  )
}
