import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import style from './style.module.css'
import { Table } from "reactstrap";
import { Modal } from 'antd';

export default function MembershipPlan() {
  const [open, setOpen] = useState(false)

  const mamberShip = [
    { item: "Domain & Hosting", economy: "Sub domain Hosting", gold: 1, platinum: 1, platinumPro: 1, global: 1 },
    { item: "Page Website", economy: "4 Page Catalogue", gold: "10", platinum: "20", platinumPro: "50", global: "Unlimited" },
    { item: "Fully Responsive", economy: 1, gold: 1, platinum: 1, platinumPro: 1, global: 1 },
    { item: "Unlimited Buy Leads", economy: 1, gold: 1, platinum: 1, platinumPro: 1, global: 1 },
    { item: "Social Media Integration", economy: 1, gold: 1, platinum: 1, platinumPro: 1, global: 1 },
    { item: "Products Displayed", economy: "15", gold: "30", platinum: "50", platinumPro: "55", global: "Unlimited" },
    { item: "SSL Secure", economy: 1, gold: 1, platinum: 1, platinumPro: 1, global: 1 },
    { item: "Search Bar", economy: 1, gold: 1, platinum: 1, platinumPro: 1, global: 1 },
    { item: "Logo Designing", economy: 0, gold: 1, platinum: 1, platinumPro: 1, global: 1 },
    { item: "SEO Friendly", economy: 0, gold: 1, platinum: 1, platinumPro: 1, global: 1 },
    { item: "Chat Box", economy: 0, gold: 1, platinum: 1, platinumPro: 1, global: 1 },
    { item: "POP Mail ID", economy: 0, gold: "1", platinum: "3", platinumPro: "5", global: "5" },
    { item: "QR Code", economy: 0, gold: 0, platinum: 1, platinumPro: 1, global: 1 },
    { item: "Language Converter", economy: 0, gold: 0, platinum: 1, platinumPro: 1, global: 1 },
    { item: "Flip Book", economy: 0, gold: 0, platinum: 1, platinumPro: 1, global: 1 },
    { item: "Video Slideshow", economy: 0, gold: 0, platinum: 1, platinumPro: 1, global: 1 },
    { item: "GMB Listing", economy: 0, gold: 0, platinum: 1, platinumPro: 1, global: 1 },
    { item: "Youtube Video", economy: 0, gold: 0, platinum: 1, platinumPro: 1, global: 1 },
    { item: "Whatsapp Icon", economy: 0, gold: 0, platinum: 0, platinumPro: 1, global: 1 },
    { item: "Social Media Page Creation", economy: 0, gold: 0, platinum: 0, platinumPro: 1, global: 1 },
    { item: "Google Ads", economy: 0, gold: 0, platinum: 0, platinumPro: "5,000", global: "10,000" },
    { item: "Payment Gateway Integration", economy: 0, gold: 0, platinum: 0, platinumPro: 1, global: 1 },
    { item: "PDF Broachure", economy: 0, gold: 0, platinum: 0, platinumPro: 1, global: 1 },
    { item: "2 Month SEO", economy: 0, gold: 0, platinum: 0, platinumPro: 0, global: 1 },
    { item: "Water Mark", economy: 0, gold: 0, platinum: 0, platinumPro: 0, global: 1 },
    { item: "Customize Header Page", economy: 0, gold: 0, platinum: 0, platinumPro: 0, global: 1 },
    { item: "E-Commerce Website", economy: 0, gold: 0, platinum: 0, platinumPro: 0, global: 1 },
    { item: "Gallery Page", economy: 0, gold: 0, platinum: 0, platinumPro: 0, global: 1 }]
  return (
    <div className="container px-0">

      <div className="page-header breadcrumb-wrap undefined mb-10"><div className="container"><div className="breadcrumb"><Link to="/">Home</Link><span></span>Membership Plans</div></div></div>

      <div className={"row pb-10 " + style.planContainer}>
        <div className="row col-lg-9 m-auto">
          <Table hover responsive className="m-0" >
            <thead>
              <tr>
                <th></th>
                <th style={{ width: "150px", textAlign: "center" }}>
                  <h6 className="font-weight-bold">ECONOMY</h6></th>
                <th style={{ width: "150px", textAlign: "center" }}><h6 className="font-weight-bold">GOLD</h6></th>
                <th style={{ width: "150px", textAlign: "center" }}><h6 className="font-weight-bold">PLATINUM</h6></th>
                <th style={{ width: "150px", textAlign: "center" }}><h6 className="font-weight-bold">PLATINUM PRO</h6></th>
                <th style={{ width: "180px", textAlign: "center" }}><h6 className="font-weight-bold">GLOBAL</h6></th>
              </tr>
            </thead>
            <tbody>
              <tr className={style.rowItems}>
                <th scope="row"></th>
                <td className={"text-center"}>
                  <h4><span style={{ fontSize: "small" }}>Rs.</span> 15,999</h4>
                </td>
                <td className={"text-center"}>
                  <h4><span style={{ fontSize: "small" }}>Rs.</span> 25,999</h4>
                </td>
                <td className={"text-center"}>
                  <h4><span style={{ fontSize: "small" }}>Rs.</span> 36,999</h4>
                </td>
                <td className={"text-center"}>
                  <h4><span style={{ fontSize: "small" }}>Rs.</span> 57,999</h4>
                </td>
                <td className={"text-center"}>
                  <h4><span style={{ fontSize: "small" }}>Rs.</span> 1,08,999</h4>
                </td>
              </tr>
              {mamberShip.map((el, i) => {
                return <PlanCard key={i} data={el} />
              })}
              <tr className={style.rowItems}>
                <th scope="row"></th>
                <td className={"text-center"}>
                  <button onClick={(e)=>setOpen(true)} className='btn'>Buy Now</button>
                </td>
                <td className={"text-center"}>
                  <button onClick={(e)=>setOpen(true)} className='btn'>Buy Now</button>
                </td>
                <td className={"text-center"}>
                  <button onClick={(e)=>setOpen(true)} className='btn'>Buy Now</button>
                </td>
                <td className={"text-center"}>
                  <button onClick={(e)=>setOpen(true)} className='btn'>Buy Now</button>
                </td>
                <td className={"text-center"}>
                  <button onClick={(e)=>setOpen(true)} className='btn'>Buy Now</button>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <Modal open={open} title={"Secure Payment"} width={800} footer={null} onCancel={(e)=>setOpen(false)}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={"/web0550@sbi.webp"}
        />
      </Modal>
    </div>
  )
}


const PlanCard = (props) => {
  return (
    <tr className={style.rowItems}>
      <th scope="row" style={{ whiteSpace: "nowrap" }}>{props.data.item}</th>
      <td className={"text-center"}>
        <strong>
          {props.data.economy === 0 ? (
            <i className="far fa-times-circle text-danger fa-lg"></i>
          ) : props.data.economy === 1 ? (
            <i className="far fa-check-circle text-success fa-lg"></i>
          ) : (
            props.data.economy
          )}
        </strong>
      </td>
      <td className={"text-center"}>
        <strong>
          {props.data.gold === 0 ? (
            <i className="far fa-times-circle text-danger fa-lg"></i>
          ) : props.data.gold === 1 ? (
            <i className="far fa-check-circle text-success fa-lg"></i>
          ) : (
            props.data.gold
          )}
        </strong>
      </td>
      <td className={"text-center"}>
        <strong>
          {props.data.platinum === 0 ? (
            <i className="far fa-times-circle text-danger fa-lg"></i>
          ) : props.data.platinum === 1 ? (
            <i className="far fa-check-circle text-success fa-lg"></i>
          ) : (
            props.data.platinum
          )}
        </strong>
      </td>
      <td className={"text-center"}>
        <strong>
          {props.data.platinumPro === 0 ? (
            <i className="far fa-times-circle text-danger fa-lg"></i>
          ) : props.data.platinumPro === 1 ? (
            <i className="far fa-check-circle text-success fa-lg"></i>
          ) : (
            props.data.platinumPro
          )}
        </strong>
      </td>
      <td className={"text-center"}>
        <strong>
          {props.data.global === 0 ? (
            <i className="far fa-times-circle text-danger fa-lg"></i>
          ) : props.data.global === 1 ? (
            <i className="far fa-check-circle text-success fa-lg"></i>
          ) : (
            props.data.global
          )}
        </strong>
      </td>
    </tr>
  )

}
