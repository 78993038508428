import React, { useEffect } from 'react'
import InquiryForm from '../components/elements/InquiryForm'
import { Link } from 'react-router-dom'

export default function RequirmentForm() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [])
  return (
    <div className="container col-xl-11 m-auto pt-30 px-0">
      <div class="page-header breadcrumb-wrap undefined mb-20"><div class="container"><div class="breadcrumb"><Link to="/">Home</Link><span></span> Requirement Form</div></div></div>
      <div className="row flex-row-reverse">
        <div className="col-lg-7">
          <InquiryForm formTitle="Tell us your Requirement" />
        </div>

        <div className="col-lg-5 p-3">
        <img src="/assets/imgs/banner/req.jpeg" alt="" />
        </div>
      </div>
    </div>
  )
}
