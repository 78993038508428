import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { api } from '../components/util/Data';
import { Link, useParams } from 'react-router-dom';

export default function Careers() {
    const params = useParams()
    const [dataList, setDataList] = useState({ totalCareers: 0, careers: [] })
    // const [loading, setLoading] = useState(true);
    const [listPage, setListPage] = useState(1);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth", });
        axios
            .post(api.url + "/get-careers", { key: "" })
            .then(function (response) {
                console.log(response);
                setDataList({ totalCareers: response.data.total, careers: response.data.careers })
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [listPage, params])

    const handlePageChange = (event, value) => {
        // setLoading(true)
        setListPage(value);
    };

    return (
        <div className="container px-0">
            <div className="page-header breadcrumb-wrap undefined mb-10"><div className="container"><div className="breadcrumb"><Link to="/">Home</Link><span></span>Current Careers</div></div></div>

            <div className="row flex-row-reverse pb-10">
                <div className="col-lg-9  m-auto ">
                    {dataList.careers.length ? dataList.careers.map((el, i) => {
                        return <div key={i} className="vendor-wrap mb-20  style-2">
                            <div className="vendor-content-wrap w-100">
                                <div>
                                    <h4 className="mb-5">{el.designation}</h4>
                                    <div className="vendor-info d-flex justify-content-between flex-wrap align-items-end mt-15">
                                        <ul className="contact-infor">
                                            <li><i style={{ width: "30px", textAlign: "center" }} className="fa-solid fa-circle-info"></i><strong>Experience : </strong> <span>{el.experience}</span></li>
                                            <li><i style={{ width: "30px", textAlign: "center" }} className="fa-solid fa-circle-info"></i><strong>Details : </strong> <span>{el.description}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }) : ""}

                    <div style={{ display: "flex" }}>
                        <h5>Total : {dataList.totalCareers}</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}
