import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="main">
            <section className="featured section-padding">
                <div className="row g-2">
                    <div className="col-lg-3 col-sm-6">
                        <div
                            className="banner-left-icon d-flex align-items-center  wow animate__animated animate__fadeInUp"
                            data-wow-delay="0"
                        >
                            <div className="banner-icon">
                                <i className="fi-rs-shield-check"></i>
                            </div>
                            <div className="banner-text">
                                <h3 className="icon-box-title">
                                    Safe & Secure
                                </h3>
                                {/* <p>Get verified Buy/sellers</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div
                            className="banner-left-icon d-flex align-items-center  wow animate__animated animate__fadeInUp"
                            data-wow-delay=".1s"
                        >
                            <div className="banner-icon">
                                <img
                                    src="/assets/imgs/theme/icon-contact.svg"
                                    alt=""
                                />
                            </div>
                            <div className="banner-text">
                                <h3 className="icon-box-title">
                                    24/7 Support
                                </h3>
                                {/* <p>24/7 amazing services</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div
                            className="banner-left-icon d-flex align-items-center  wow animate__animated animate__fadeInUp"
                            data-wow-delay=".2s"
                        >
                            <div className="banner-icon">
                                <img
                                    src="/assets/imgs/theme/icon-3.svg"
                                    alt=""
                                />
                            </div>
                            <div className="banner-text">
                                <h3 className="icon-box-title">
                                    Quick Assistance
                                </h3>
                                {/* <p>When you sign up</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div
                            className="banner-left-icon d-flex align-items-center  wow animate__animated animate__fadeInUp"
                            data-wow-delay=".3s"
                        >
                            <div className="banner-icon">
                                <img
                                    src="/assets/imgs/theme/icon-4.svg"
                                    alt=""
                                />
                            </div>
                            <div className="banner-text">
                                <h3 className="icon-box-title">
                                    Wide Network
                                </h3>
                                {/* <p>Mega Discounts</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="newsletter mb-15  wow animate__animated animate__fadeIn">
                <div className="px-4">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="position-relative newsletter-inner px-6 py-4">
                                <div className="newsletter-content">
                                    <h3 className="mb-10">
                                        Can't find requirements !
                                    </h3>
                                    <h5 className="mb-10">
                                        Tell us what you {" "}
                                        <span className="text-brand">
                                            need
                                        </span>
                                    </h5>
                                    <div className="form-subcriber mt-10" style={{ maxWidth: "40rem" }}>
                                        <div className="input-style mb-20">
                                            <input className="form-control form-control-sm" placeholder="Name..." type="text" />
                                        </div>
                                        <div className="input-style mb-20">
                                            <input className="form-control form-control-sm" placeholder="Contact No..." type="tel" />
                                        </div>
                                        <div className="input-style mb-20">
                                            <textarea className="form-control form-control-sm" style={{ minHeight: "100px" }} placeholder="Subject..." ></textarea>
                                        </div>
                                        <button className="btn btm-sm border-0 py-2" type="submit">Send</button>
                                    </div>
                                </div>
                                <img
                                    src="/assets/imgs/banner/contact-img.png"
                                    alt="newsletter"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="section-padding footer-mid">
                <div className="container pt-15 pb-20 px-5">
                    <div className="row">
                        <div className="col">
                            <div className="widget-about font-md mb-md-3 mb-lg-3 mb-xl-0 wow animate__animated animate__fadeInUp" data-wow-delay="0">
                                <div className="logo mb-20">
                                    <Link to="/">
                                        <img style={{ width: "12rem" }}
                                            src="/logo.png"
                                            alt="logo"
                                        />
                                    </Link>
                                    <p className="font-lg text-heading">
                                        Best Business to Business platform
                                    </p>
                                </div>
                                <ul className="contact-infor">
                                    <li style={{ display: "flex", flexWrap: "nowrap" }}>
                                        <img
                                            src="/assets/imgs/theme/icon-location.svg"
                                            alt=""
                                        />
                                        <span>
                                            RZH-20, Raghu Nager, Janakpuri, Dabri, New Delhi 110045
                                        </span>
                                    </li>
                                    <li>
                                        <img
                                            src="/assets/imgs/theme/icon-email-2.svg"
                                            alt=""
                                        />
                                        <span>sales@webtradesolution.com</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className=" col  wow animate__animated animate__fadeInUp"
                            data-wow-delay=".1s"
                        >
                            <h4 className="widget-title">Company</h4>
                            <ul className="footer-list  mb-sm-5 mb-md-0">
                                <li>
                                    <Link to="/about">About Us</Link>
                                </li>
                                <li>
                                    <Link to="/policy">Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to="/terms">Terms &amp; Conditions</Link>
                                </li>
                                <li>
                                    <Link to="/contact">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                        <div
                            className=" col  wow animate__animated animate__fadeInUp"
                            data-wow-delay=".2s"
                        >
                            <h4 className="widget-title ">Our Service</h4>
                            <ul className="footer-list  mb-sm-5 mb-md-0">
                                <li>
                                    <Link to="/plans">Membership Plan</Link>
                                </li>
                                <li>
                                    <Link to="/plans">Advertise with Us</Link>
                                </li>
                                <li>
                                    <Link to="/advertize-with-us">Banner Advertisement</Link>
                                </li>
                                <li>
                                    <Link to="/careers">Careers</Link>
                                </li>
                            </ul>
                        </div>
                        <div
                            className=" col  wow animate__animated animate__fadeInUp"
                            data-wow-delay=".3s"
                        >
                            <h4 className="widget-title ">Buyers Link</h4>
                            <ul className="footer-list  mb-sm-5 mb-md-0">
                                <li>
                                    <Link to="/requirement">Post Requirement</Link>
                                </li>
                                <li>
                                    <Link to="/search_company/all">Browse Suppliers</Link>
                                </li>
                                <li>
                                    <Link to="/join">Join Free</Link>
                                </li>
                                <li>
                                    <Link to="/faqs">FAQ</Link>
                                </li>
                            </ul>
                        </div>

                        <div
                            className=" widget-install-app col  wow animate__animated animate__fadeInUp"
                            data-wow-delay=".5s"
                        >
                            <h4 className="widget-title ">Seller's link</h4>
                            <ul className="footer-list  mb-sm-5 mb-md-0">
                                <li>
                                    <Link to="/join">Sell Your Product</Link>
                                </li>
                                <li>
                                    <Link to="/buy_leads">Latest Buyleads</Link>
                                </li>
                                <div>
                                    <p>Secured Payment :</p>
                                    <img src="/assets/imgs/theme/payment-method.png" alt="secure payment" />
                                </div>
                            </ul>

                        </div>
                    </div>
                </div>
            </section>

            <div
                className="container pb-30 footer-copyright wow animate__animated animate__fadeInUp"
                data-wow-delay="0"
            >
                <div className="row align-items-center">
                    <div className="col-12 mb-30">
                        <div className="footer-bottom"></div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6">
                        <p className="font-sm mb-0 ">
                            &copy; 2021,{" "}
                            <strong className="text-brand"> <a href="https://webtradesolution.com/" target="_">Web Trade Solution</a></strong> - All rights reserved
                        </p>
                    </div>
                    <div className="col-xl-4 col-lg-6 text-center d-none d-xl-block">

                        <div className="hotline d-lg-inline-flex">
                            <img src="/assets/imgs/theme/phone-call.svg" alt="hotline" style={{ filter: "invert(1)" }} />
                            <p>
                                +91-8810200550<span className="">24/7 Support Center</span>
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 text-end d-none d-md-block">
                        <div className="mobile-social-icon">
                            <h6 className="">Follow Us</h6>
                            <Link to="#">
                                <img
                                    src="/assets/imgs/theme/icon-facebook-white.svg"
                                    alt=""
                                />
                            </Link>
                            <Link to="#">
                                <img
                                    src="/assets/imgs/theme/icon-twitter-white.svg"
                                    alt=""
                                />
                            </Link>
                            <Link to="#">
                                <img
                                    src="/assets/imgs/theme/icon-instagram-white.svg"
                                    alt=""
                                />
                            </Link>
                            <Link to="#">
                                <img
                                    src="/assets/imgs/theme/icon-pinterest-white.svg"
                                    alt=""
                                />
                            </Link>
                            <Link to="#">
                                <img
                                    src="/assets/imgs/theme/icon-youtube-white.svg"
                                    alt=""
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
