
import React from "react";
import { Link } from "react-router-dom";
import { api } from "../util/Data";

const SingleProduct = ({ product }) => {
    const planArray = ["Guest", "Economy", "Gold", "Platinum", "Platinum Pro", "Global"]
    const trimmedString = (text) => {
        return text.length > 40 ? text.substring(0, 40 - 3) + "..." : text;
    }

    return (
        <>
            <div className="product-cart-wrap mb-30 cd-rd cd-bg">
                <div className="product-img-action-wrap">
                    <div className="product-img product-img-zoom">
                        <Link to={"/product-detail/" + product.productLink}>
                            <img
                                className="default-img"
                                src={api.productImg + product.image}
                                alt=""
                            />
                        </Link>
                    </div>
                </div>
                <div className="product-content-wrap pt-3">
                    <div style={{ fontSize: "small", margin: "0px", padding: product.additionalLabel ? "0px":"12px" }}>{trimmedString(product.additionalLabel)}</div>
                    <h2 style={{ fontSize: "20px" }}>
                        <Link to={"/product-detail/" + product.productLink}>{product.productName}</Link>
                    </h2>
                    <div className="product-category">{product.category.category}</div>
                    <hr className="my-1" />
                    <p className="font-small text-muted">
                        <a href={"/company/" + product.company.catalogLink} target="_" >{product.company.company}</a>
                        <div style={{ fontSize: "small" }}>{planArray[product.company.membershipPlan]} Member</div>
                        {product.company.joinYear ? <div className='text-dark' style={{ fontSize: "13px" }}> <i className="fa-regular fa-handshake"></i> {product.company.joinYear} Years</div> : <div className='text-dark' style={{ padding: "11px" }}> </div>}
                        <div><i className="fa-solid fa-map-location-dot"></i> {product.company.location}</div>
                    </p>
                    <Link to={"/requirement"} className="btn py-1 w-100 bg-main-secondary" > " Get Query "</Link>
                </div>
            </div>
        </>
    );
};


export default SingleProduct;
