import React, { useState } from 'react'
import DataTable from "react-data-table-component";
import { Tooltip } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Button } from 'react-bootstrap';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

function DataTableComponent(props) {
    const [currentRow, setCurrentRow] = useState(null);
    const [gotoPage, setGotoPage] = useState(1);
    const [sendPageNo, setSendPageNo] = useState(1);

    const ExpandedComponent = ({ data }) => {
        return props.expandedComponent(data)
    };

    const expandItem = () => {
        return (
            <Tooltip title="Hide">
                    <ExpandLessIcon />
            </Tooltip>
        );
    };

    const collapseItem = () => {
        return (
            <Tooltip title="View">
                    <ExpandMoreIcon />
            </Tooltip>
        );
    };

    const paginationOptions = {
        rowsPerPageText:
            <>
                <span>Page no : </span>
                <input type="number" style={{ maxWidth: "50px", border: "none", borderBottom: "solid 1px" }} value={gotoPage} onChange={(e) => e.target.value < 0 ? setGotoPage(1) : setGotoPage(e.target.value)} />
                <button style={{ border: "none", marginRight: "20px" }} onClick={(e) => { jumpToPage() }}>Go</button>
                <span>Rows per page:</span>
            </>,
    };

    const jumpToPage = () => {
        if (gotoPage < 1) {
            setSendPageNo(1)
            setGotoPage(1)
        } else {
            setSendPageNo(gotoPage)
        }
    }

    const handlePageChange = page => {
        props.pageChange(page)
        setGotoPage(page)
    };

    const handlePerRowsChange = (newPerPage, page) => {
        setGotoPage(page)
        props.perRowsChange({ perPage: newPerPage, pageNo: page })
    };

    const handleSort = (column, sortDirection) => {
        props.setHandleSort({ sortDirection: sortDirection, sortField: column.sortField })
    };
    return (
        <>
            {
                props.notFilter ? "" : <form onSubmit={(e) => { e.preventDefault(); props.setSearchTrue(true) }} >
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                        <Form.Control
                            placeholder="Text here for Search..."
                            aria-label="search"
                            aria-describedby="basic-addon1"
                            onChange={(e) => props.inputFilter(e.target.value)}
                        />
                        <Button className="border-0">{props.inputFilterValue ? "Search" : "Reset"}</Button>
                    </InputGroup>
                </form>
            }


            <DataTable
                persistTableHead
                highlightOnHover
                columns={props.columnsData}
                data={props.data}
                progressPending={props.dataLoad}
                pagination
                expandableRowExpanded={(row) => (row === currentRow)}
                onRowClicked={(row) => setCurrentRow(row)}
                onRowExpandToggled={(bool, row) => setCurrentRow(row)}
                expandableRows={props.expandable}
                expandableRowsComponent={ExpandedComponent}
                expandableIcon={{ collapsed: collapseItem(), expanded: expandItem() }}
                paginationServer
                sortServer
                onSort={handleSort}
                paginationTotalRows={props.totalRowsData}
                paginationComponentOptions={paginationOptions}
                onChangeRowsPerPage={handlePerRowsChange}
                paginationDefaultPage={sendPageNo}
                onChangePage={handlePageChange} />
        </>
    )
}

export default DataTableComponent