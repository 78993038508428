import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { api } from '../components/util/Data'
import axios from 'axios';

export default function CategoryDirectory() {
    const params = useParams();
    const [subCategory, setSubCategory] = useState([])
    const [categoryData, setCategoryData] = useState([])
  
    useEffect(() => {
      axios
        .post(api.url + "/get-category-directory", { category: params.category })
        .then(function (response) {
          console.log(response.data);
          setCategoryData(response.data.category)
          setSubCategory(response.data.subCategory)
        })
        .catch(function (error) {
          console.log(error);
        });
    }, [params])
    
    const makeLink = (str) => {
        return str.replace(/^\s+|\s+$/g, '').replaceAll(" ", "_").toLowerCase();
    }

    return (
        <div className="my-4">
            <div class="archive-header-2 text-center">
                <h3 class="display-7 mb-50"><strong><span className='text-brand'>{categoryData.category}</span> Directory</strong></h3>
            </div>
            <div class="row">
                <div class="col-lg-10 mx-auto row">
                    {subCategory.map((ele, i) => {
                        return <div key={i} className="col-md-3">
                            <div className="card-1">
                                <figure className=" img-hover-scale overflow-hidden">
                                    <Link to={"/products/" + makeLink(ele.subCategory)}>
                                        <img
                                            src={api.categoryImg + ele.image}
                                            alt={ele.subCategory}
                                        />
                                    </Link>
                                </figure>
                                <h6>
                                    <Link to={"/products/" + makeLink(ele.subCategory)}>
                                        {ele.subCategory}
                                    </Link>
                                </h6>
                                {/* <span>26 items</span> */}
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}
