import React, { useEffect, useState } from "react";
import GoToTop from "./GoToTop";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import CatalogHeader from "./CatalogHeader";
import CatalogMobileMenu from "./CatalogMobileMenu";
import CatalogFooter from "./CatalogFooter";
import axios from "axios";
import { api } from "../util/Data";
import style from './style.module.css'
import { Spin } from "antd";

const CatalogLayout = () => {
    const [isToggled, setToggled] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()
    const [companyDetails, setCompanyDetails] = useState({});
    const { link } = useParams()

    useEffect(() => {
        setLoading(true)
        axios
            .post(api.url + "/get-company-for-catalog", { link: link })
            .then(function (response) {
                // console.log(response.data);
                if (response.data.success) {
                    setCompanyDetails(response.data.company)
                    setLoading(false)
                } else {
                    navigate("/404")
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [link, navigate])

    const toggleClick = () => {
        setToggled(!isToggled);
        isToggled
            ? document
                .querySelector("body")
                .classList.remove("mobile-menu-active")
            : document
                .querySelector("body")
                .classList.add("mobile-menu-active");
    };
    return (
        loading ? <div className={style.lazyLoad}> <Spin tip="Loading" size="large"></Spin> </div> :
            <>
                {isToggled && <div className="body-overlay-1" onClick={toggleClick}></div>}
                <CatalogHeader company={companyDetails} isToggled={isToggled} toggleClick={toggleClick} />
                <CatalogMobileMenu isToggled={isToggled} toggleClick={toggleClick} company={companyDetails} />
                <div className="main">
                    <Outlet context={[companyDetails]} />
                </div>
                <GoToTop />
                <CatalogFooter />
            </>
    );
};

export default CatalogLayout;
