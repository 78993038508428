import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { api } from '../components/util/Data';

export default function ProductDetail() {
  const { company, product } = useParams()
  const [productItem, setProductItem] = useState({})
  const planArray = [{ plan: "Guest", leads: 0 }, { plan: "Economy", leads: 10 }, { plan: "Gold", leads: 30 }, { plan: "Platinum", leads: 60 }, { plan: "Platinum Pro", leads: 80 }, { plan: "Global", leads: 100 }]
  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    axios
      .post(api.url + "/get-product-detail", { company: company, product: product })
      .then(function (response) {
        // console.log(response);
        setProductItem(response.data.product)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [company, product])

  return (
    <div className="container px-0">
      <div className="page-header breadcrumb-wrap undefined mb-10"><div className="container"><div className="breadcrumb"><Link to="/">Home</Link><span></span> Product Details </div></div></div>

          <div className="container">
            <div className="row flex-row-reverse cd-bg cd-rd">
              <div className="col-xl-10 col-lg-12 m-auto">
                <div className="product-detail accordion-detail">
                  <div className="row mb-50  mt-30">
                    <div className="col-md-5 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                      <img style={{ width: "100%", borderRadius: "10px" }} src={api.productImg + productItem.image} alt={productItem.productName} />
                    </div>
                    <div className="col-md-7 col-sm-12 col-xs-12">
                      <div className="detail-info  pr-30 pl-30">
                        <h2 className="title-detail">{productItem.productName}</h2>
                        <div className="product-rate-cover">
                          <span className="font-small ml-5 text-muted"> {productItem.additionalLabel}</span>
                        </div>

                        <div className="short-desc mb-30">
                          {/* <p className="font-lg">{productItem.productDetails}</p> */}
                          <p className="font-lg">{productItem.productDetails?productItem.productDetails.split("\n").map((el, i) => {
                            return <p key={i} className='text-dark m-0 my-2'>{el} </p>
                          }):""}</p>
                        </div>
                        <div className="bt-1 border-color-1 mt-30 mb-30"></div>
                        <div className="detail-extralink">
                          <Link to={"/requirement"} className="btn">
                            Product Query
                          </Link>
                        </div>
                        <ul className="product-meta font-xs color-grey mt-20">
                          <li className="mb-5">
                            <h4>
                              {productItem.company? <a href={`/company/${productItem.company.catalogLink}`} target='_blank' rel="noreferrer">{productItem.company.company}</a> : ""}
                            </h4>
                          </li>
                          <li className="mb-5">
                            <h5>{productItem.company? planArray[productItem.company.membershipPlan].plan + " Member" : ""}</h5>
                          </li>
                          <li>
                          <i className="fa-regular fa-handshake"></i>  {productItem.company? productItem.company.joinYear : ""} Yrs
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}
