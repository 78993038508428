import React, { useEffect, useState } from "react";
import GoToTop from "./GoToTop";
import { getCookie } from "../util/Cokkies";
import { PoweroffOutlined } from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import { Button } from 'antd';
import style from './style.module.css'
import { clearLocalStorage, removeLocalStorage } from '../util/localStore';
import { removeCookie } from '../util/Cokkies';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { userValidate } from "../util/validation";
import { useSelector } from "react-redux";
const { Header, Content, Sider } = Layout;

const userMenu = [
    { label: "Dashboard", link: "dashboard", icon: "fas fa-chart-bar", children: [] },
    // { label: "Profile", link: "profile", icon: "fas fa-chart-bar", children: [] },
    {
        label: "Manage Company", link: "#", icon: "fas fa-chart-bar", children: [
            // { label: "Users List", link: "users-list" },
            { label: "Company Details", link: "company-details" }
        ]
    },
    { label: "Banners", link: "banners", icon: "fas fa-chart-bar", children: [] },
    {
        label: "Products", link: "#", icon: "fas fa-chart-bar", children: [
            { label: "Products List", link: "products-list" },
            { label: "Add Product", link: "add-product" }
        ]
    },
    { label: "Gallery", link: "gallery", icon: "fas fa-chart-bar", children: [] },
    {
        label: "Buy Leads", link: "#", icon: "fas fa-chart-bar", children: [
            { label: "My Leads", link: "my-leads" },
            { label: "Buy Leads List", link: "leads-list" }
        ]
    }
];

const adminMenu = [
    { label: "Dashboard", link: "dashboard", icon: "fas fa-chart-bar", children: [] },
    // { label: "Profile", link: "profile", icon: "fas fa-chart-bar", children: [] },
    {
        label: "Manage Company", link: "#", icon: "fas fa-chart-bar", children: [
            { label: "Company List", link: "company-list" },
            // { label: "Add User", link: "add-user" }
        ]
    },
    // {
    //     label: "Products", link: "#", icon: "fas fa-chart-bar", children: [
    //         { label: "Products List", link: "products-list" },
    //         { label: "Add Product", link: "add-product" }
    //     ]
    // },
    {
        label: "Manage Categories", link: "#", icon: "fas fa-chart-bar", children: [
            { label: "Category Group", link: "category-group" },
            { label: "Categories", link: "categories" },
            { label: "Sub Categories", link: "sub-categories" }
        ]
    },
    {
        label: "Buy Leads", link: "#", icon: "fas fa-chart-bar", children: [
            { label: "Add Leads", link: "add-leads" },
            { label: "Buy Leads List", link: "leads-list" }
        ]
    },
    {
        label: "Testimonials", link: "#", icon: "fas fa-chart-bar", children: [
            { label: "Add Testimonial", link: "add-testimonial" },
            { label: "Testimonials List", link: "testimonials-list" }
        ]
    },
    { label: "Requirements", link: "requirements", icon: "fas fa-chart-bar", children: [] },
    { label: "Main Banners", link: "main-banners", icon: "fas fa-chart-bar", children: [] },
    { label: "Careers", link: "add-careers", icon: "fas fa-chart-bar", children: [] }
];

const hrMenu = [
    { label: "Dashboard", link: "dashboard", icon: "fas fa-chart-bar", children: [] },
    // { label: "Profile", link: "profile", icon: "fas fa-chart-bar", children: [] },
];

const AdminLayout = () => {
    const catstore = useSelector((state) => state.CategoryState);
    const currentCompanyUser = useSelector((state) => state.CompanyUser.data);
    const [selectedMenu, setSelectedMenu] = useState({ selectedKey: "", openKey: "" });
    const verify_admin = userValidate(['admin'], "token");
    const verify_hr = userValidate(['hr'], "token");
    const menuList = verify_admin ? adminMenu : verify_hr ? hrMenu : userMenu
    let token = getCookie("token")
    useEffect(() => {
        if (!token) {
            clearLocalStorage()
            window.location.replace("/login")
        }
    }, [currentCompanyUser, token])

    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate()
    const {
        token: { colorBgContainer },
    } = theme.useToken();


    const submenu = (arr, parent) => {
        const child = arr.map((item, i) => {
            return { label: <span onClick={() => { jumpTo("/admin/" + item.link, parent, item.label) }}>{item.label}</span>, icon: undefined, children: undefined, key: item.label }
        })
        return child
    }
    const menu = menuList.map((item, i) => {
        return { label: item.children.length ? item.label : <span onClick={() => { jumpTo("/admin/" + item.link, item.label, "") }}><i className={item.icon}></i> {item.label}</span>, icon: undefined, children: item.children.length ? submenu(item.children, item.label) : undefined, key: item.label }
    })

    const jumpTo = (link, select, open) => {
        setSelectedMenu({ selectedKey: select, openKey: open })
        navigate(link, { state: catstore })
    }

    const logOut = () => {
        removeLocalStorage("user");
        removeCookie("token");
        window.location.replace("/login")
    }


    return (
        <>
            <div className="main">
                <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
                    {/* <SideBar childrencontent={children} /> */}
                    <Layout style={{ minHeight: '100vh' }}>
                        <Header className={style.header}>
                            <div className={style.adminlogo} />
                            <div className="head-menu">
                                {verify_admin ? <Link className="mr-20" state={currentCompanyUser} to={"/admin/change-admin-password"} >Change Password</Link> : <> <a className="mr-20" href={"/company/" + currentCompanyUser.catalogLink} target="_blank" rel="noreferrer">Catalog</a>
                                    {currentCompanyUser.website ? <a href={currentCompanyUser.website} target="_blank" rel="noreferrer">Website</a> : ""} </>}
                                <Button className="ml-20" type="primary" icon={<PoweroffOutlined />} onClick={() => logOut()} danger />
                            </div>

                        </Header>
                        <Layout>
                            <Sider breakpoint="md"
                                collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}
                                collapsedWidth="50"
                                onBreakpoint={(broken) => {
                                    // console.log(broken);
                                }}
                                width={200} style={{ background: colorBgContainer }}>
                                <Menu
                                    mode="inline"
                                    defaultSelectedKeys={[selectedMenu.selectedKey]}
                                    defaultOpenKeys={[selectedMenu.openKey]}
                                    // style={{ height: '100%', borderRight: 0 }}
                                    className={style.siteMenu}
                                    items={menu}
                                />
                            </Sider>
                            <Layout style={{ padding: '15px' }}>
                                {/* <Breadcrumb style={{ margin: '16px 0' }}
                                    items={[
                                        {
                                            title: 'Home',
                                        },
                                        {
                                            title: selectedMenu.selectedKey,
                                        },
                                        {
                                            title: selectedMenu.openKey,
                                        },
                                    ]}
                                /> */}
                                <Content
                                    style={{
                                        padding: 24,
                                        margin: 0,
                                        height: "100%",
                                        background: colorBgContainer,
                                    }}
                                >
                                    {<Outlet />}
                                </Content>
                            </Layout>
                        </Layout>
                    </Layout>
                </div>
            </div >
            <GoToTop />
        </>
    );
};

export default AdminLayout;
