import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { getCookie } from '../../components/util/Cokkies';
import { api } from '../../components/util/Data';
import style from './style.module.css'
import { Grid, TextField } from '@mui/material';
function ChangePassword() {
    const location = useLocation();
    const adminPortal = location.pathname.includes("admin");
    const navigate = useNavigate()
    const token = getCookie(adminPortal ? "token" : "alcToken");
    const [formData, setFormData] = useState(location.state);
    // const [passwords, setPasswords] = useState({ id: formData.user._id, newPassword: "", confirmPassword: "" });

    const changeHandle = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const submitHandler = (e) => {
        e.preventDefault();
        if (!formData.confirmPassword || !formData.newPassword) {
            alert("Please enter mandetory fields");
        } else {
            if (formData.newPassword === formData.confirmPassword) {
                axios
                    .post(
                        api.url + "/update-user-password",
                        { id: formData._id, newPassword: formData.newPassword, email: formData.email, userId: formData.user._id, adminChange: adminPortal},
                        { headers: { authorization: "Bearer " + token } }
                    )
                    .then(function (response) {
                        if (response.data.success) {
                            alert("Password change successfull");
                            navigate(adminPortal ? "/admin" : "/allocation")
                        }
                    })
                    .catch(function (error) {
                        alert(error.response.data.error);
                    });
            } else {
                alert("Confirm Password is not match");
            }
        }
    };

    return (
        <div>
            <div className="content-header p-0">
                <div className="container-fluid">
                    <h4 className="m-0 text-dark">Change User's Login Credentials</h4>
                    <hr className="my-2" />
                </div>
            </div>
            <div className="container px-4">
                <Grid container spacing={1} style={{ maxWidth: "450px" }} className="form pt-20">
                    {adminPortal ? <h5>Company: <strong>{formData.company}</strong></h5> : <h5>User Name: <strong>{formData.user.fullname}</strong></h5>}
                    <Grid item md={12} className={"w-100"}>
                        <TextField size="small" className={style.formInput} name="email" type="text" required label="Email" margin="normal" onChange={(e) => changeHandle(e)} defaultValue={formData.user.email} variant="outlined" />
                    </Grid>
                    <Grid item md={12} className={"w-100"}>
                        <TextField size="small" className={style.formInput} onChange={(e) => changeHandle(e)} name="newPassword" type="text" required label="New Password*" margin="normal" value={formData.newPassword} variant="outlined" />
                    </Grid>

                    <Grid item md={12} className={"w-100"}>
                        <TextField size="small" className={style.formInput} name="confirmPassword" type="text" required label="Confirm Password*" margin="normal" value={formData.confirmPassword} onChange={(e) => changeHandle(e)} variant="outlined" />
                    </Grid>
                    <Grid item>
                        <Button onClick={(e) => submitHandler(e)} className="btn btn-sm px-3 ml-2">
                            Update
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default ChangePassword