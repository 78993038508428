import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { ButtonGroup } from "reactstrap";
import { Link } from "react-router-dom";
import { Button, IconButton } from "@mui/material";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { getCookie } from "../../components/util/Cokkies";
import { api } from "../../components/util/Data";
import DataTableComponent from "../../components/elements/DataTableComponent";
import { userValidate } from "../../components/util/validation";
import axios from "axios";


function RequirementsList() {
  const [listData, setListData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  let token = getCookie("token");
  const [totalRows, setTotalRows] = useState(0);
  const [pending, setPending] = useState(true);
  const [tableOptions, setTableOptions] = useState({ perPage: 10, pageNo: 1, sortDirection: "desc", sortField: "createdAt" });

  useEffect(() => {
    setPending(true);
    axios
      .post(api.url + "/get-requiment-list", { page: tableOptions.pageNo, sortDirection: tableOptions.sortDirection, sortField: tableOptions.sortField, perPage: tableOptions.perPage }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        // console.log(response.data);
        if(response.data.success){
          response.data.requirements.forEach((item, index) => {
            item.serial = index + 1 + (tableOptions.perPage * tableOptions.pageNo - tableOptions.perPage);
          });
        }
        setTotalRows(response.data.total);
        setListData(response.data.requirements);
        setFilterItems(response.data.requirements);
        setPending(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);


  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      sortable: true,
      width: "65px",
    },
    {
      name: <strong>Name</strong>,
      selector: (row) => row.name,
      sortable: true,
      width: "200px",
    },
    {
      name: <strong>Contact</strong>,
      selector: (row) => row.callCode + "-" + row.contact,
      sortable: true,
      width: "200px",
    },
    {
      name: <strong>Email</strong>,
      selector: (row) => row.email,
      sortable: true,
      width: "180px",
    },
    {
      name: <strong>Add On</strong>,
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      sortable: true,
      width: "120px",
    },
    {
      name: <strong>Action</strong>,
      cell: (row) =>
        <IconButton onClick={() => deleteItem(row._id)}>
          <DeleteIcon fontSize="small" color="error" />
        </IconButton>,
      button: true,
    },
  ];

  const deleteItem = (id) => {
    axios
      .post(api.url + "/delete-requirement", { id }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        if (response.data.success) {
          alert("Delete Successfull");
          const newListData = listData.filter((el) => {
            return el._id !== id;
          });
          setListData(newListData);
          setFilterItems(newListData);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const expComponent = (data) => {
    return (
      <div style={{ width: "100%", fontWeight: "bolder" }}>
        <ul>
          <li>
            <u>Name</u> : {data.name}
          </li>
          <li>
            <u>Contact</u> : {data.contact}
          </li>
          <li>
            <u>Email</u> : {data.email}
          </li>
          <li>
            <u>Country</u> : {data.country}
          </li>
          <li>
            <u>Message</u> : {data.message}
          </li>
        </ul>
      </div>
    );
  };

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Requirement List</h4>
          <hr className="my-2" />
        </div>
      </div>

      <DataTableComponent
        pageChange={(e) => { setTableOptions({ ...tableOptions, pageNo: e }) }}
        perRowsChange={(e) => setTableOptions({ ...tableOptions, perPage: e.perPage, pageNo: 1 })}
        setHandleSort={(e) => setTableOptions({ ...tableOptions, sortDirection: e.sortDirection, sortField: e.sortField })}
        expandedComponent={e => expComponent(e)}
        expandable={true}
        dataLoad={pending}
        notFilter={true}
        columnsData={columns}
        totalRowsData={totalRows}
        data={filterItems} />
    </div>
  );
}

export default RequirementsList;
