import React, { useEffect, useState } from 'react'
import DirCategoryCard from '../components/elements/DirCategoryCard';
import { api } from '../components/util/Data'
import axios from 'axios';

export default function ProductDirectory() {
  const [categoryData, setCategoryData] = useState({})
  const [groupData, setGroupData] = useState({})
  const [dataload, setdataload] = useState(true)

  useEffect(() => {
    axios
      .post(api.url + "/get-product-directory", { category: "" })
      .then(function (response) {
        //   console.log(response.data);
        const newList = response.data.category.map((el) => {
          const listing = response.data.subCategory.filter(ele => ele.category === el._id)
          return { ...el, items: listing }
        })
        setCategoryData(newList)
        setGroupData(response.data.group)
        setdataload(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])

  return (
    <div className="my-4">
      <div class="archive-header-2 text-center">
        <h3 class="display-6 mb-20"><strong><span className='text-brand'>Products</span> Directory</strong></h3>
      </div>
      {dataload ? "Loading..." : groupData.map((ele, i) => {
        return <DirCategoryCard key={i} groupData={ele} catData={categoryData} />
      })}
    </div>
  )
}
