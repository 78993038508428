import Slider from "react-slick";
import { Link } from "react-router-dom";
import { api } from "../util/Data";
import { useRef } from "react";

const CategorySlider2 = (props) => {
  const slider = useRef(null);
  const makeLink = (str) => {
    return str.replace(/^\s+|\s+$/g, '').replaceAll(" ", "_").toLowerCase();
  }

    var settings = {
        infinite: true,
        dots: true,
        slidesToShow: 6,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 500,
        speed: 5000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 2
            }
          },
          {
            breakpoint: 375,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

    return (
        <>
            <Slider ref={slider} {...settings}>
                {props.data.map((item, i) => (
                  i < 25 ? <div key={i}>
                        <div className="card-1">
                            <figure className=" img-hover-scale overflow-hidden">
                                <Link to={"/products/"+ makeLink(item.subCategory)}>
                                        <img
                                            src={api.categoryImg + item.image}
                                            alt=""
                                        />
                                </Link>
                            </figure>
                            <h6>
                                <Link to={"/products/"+ makeLink(item.subCategory)}>
                                    {item.subCategory}
                                </Link>
                            </h6>
                            {/* <span>26 items</span> */}
                        </div>
                    </div> :""
                ))}
            </Slider>
            <div
                className="slider-arrow slider-arrow-2 flex-right carausel-10-columns-arrow"
                id="carausel-10-columns-arrows"
            >
                <span onClick={() => slider?.current?.slickPrev()} className="slider-btn slider-prev slick-arrow custom_prev_ct1">
                    <i className="fi-rs-arrow-small-left"></i>
                </span>
                <span onClick={() => slider?.current?.slickNext()} className="slider-btn slider-next slick-arrow custom_next_ct1">
                    <i className="fi-rs-arrow-small-right"></i>
                </span>
            </div>
        </>
    );
};

export default CategorySlider2;
