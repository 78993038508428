import React, { useEffect, useState } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import style from "./style.module.css";
import { Grid, IconButton, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { getCookie } from "../../components/util/Cokkies";
import { api } from "../../components/util/Data";
import DataTableComponent from "../../components/elements/DataTableComponent";
import { Button } from "react-bootstrap";
import axios from "axios";

function AddCategoryGroup() {
  let token = getCookie("token");
  const [categoriesList, setCategoriesList] = useState([]);
  const [formData, setFormData] = useState({ categoryGroup: "" });
  const [updateQuery, setUpdateQuery] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [pending, setPending] = useState(true);
  const [filterItems, setFilterItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [tableOptions, setTableOptions] = useState({ perPage: 10, pageNo: 1, sortDirection: "desc", sortField: "createdAt" });

  useEffect(() => {
    setPending(true)
    axios
      .post(api.url + "/get-categories-group-for-admin", { page: tableOptions.pageNo, sortDirection: tableOptions.sortDirection, sortField: tableOptions.sortField, perPage: tableOptions.perPage, key: filterValue })
      .then(function (response) {
        // console.log(response);
        response.data.categorygroup.forEach((item, index) => {
          item.serial = index + 1 + (tableOptions.perPage * tableOptions.pageNo - tableOptions.perPage);
        });
        setCategoriesList(response.data.categorygroup);
        setFilterItems(response.data.categorygroup);
        setTotalRows(response.data.total);
        setPending(false)
        setIsSearch(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [isSearch, tableOptions, updateQuery]);

  const updateFuction =()=>{
    axios
        .post(api.url + "/update-category-group", formData, { headers: { authorization: "Bearer " + token } })
        .then(function (response) {
          // console.log(response);
          if (response.data.success) {
            alert("Update Successfull");
            setUpdateQuery(false)
          }
        })
        .catch(function (error) {
          console.log(error);
          setUpdateQuery(false)
          alert(error.response);
        });
  }

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      width: "65px",
    },
    {
      name: <strong>CATEGORY GROUP</strong>,
      selector: (row) => row.categoryGroup
    },
    {
      name: <strong>ACTION</strong>,
      selector: (row) => (
        <>
          <IconButton onClick={(e) => { setUpdateQuery(true); setFormData(row) }} className={style.actionBtn} size="small">
            <EditIcon fontSize="small" />
          </IconButton>
          {" "}
          <IconButton onClick={(e) => deletehandle(row._id)} className={style.actionBtn} color="error" size="small">
            <DeleteIcon fontSize="small" />
          </IconButton>
        </>
      ),
      width: "100px",
    },
  ];

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const deletehandle = (id) => {
    try {
      axios
        .post(api.url + "/delete-category", { id: id, type: "categoryGroup", image: "image" }, { headers: { authorization: "Bearer " + token } })
        .then(function (response) {
          // console.log(response);
          if (response.data.success) {
            alert("Delete Successfull");
            setFilterItems(categoriesList.filter((el) => {
              return id !== el._id
            }));
            setCategoriesList(categoriesList.filter((el) => {
              return id !== el._id
            }));
          }
        })
        .catch(function (error) {
          // console.log(error);
          alert(error.response.data.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (formData.categoryGroup) {
      axios
        .post(api.url + "/add-category-group", formData, { headers: { authorization: "Bearer " + token } })
        .then(function (response) {
          // console.log(response);
          if (response.data.success) {
            alert("Add Successfull");
            formData["_id"] = response.data.catId;

            setCategoriesList([...categoriesList, { serial: categoriesList.length + 1, _id: response.data.catId, categoryGroup: formData.categoryGroup }]);

            setFilterItems([...filterItems, { serial: filterItems.length + 1, _id: response.data.catId, categoryGroup: formData.categoryGroup }]);

            setFormData({ categoryGroup: "" })
          }
        })
        .catch(function (error) {
          console.log(error);
          alert(error.response);
        });
    } else {
      alert("Please fill the required fields");
    }
  };

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Add Category Group</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        {/* Add Categories */}
        <Grid container spacing={1} className="form">
          <Grid item md={12} className={"w-100"}>
            <TextField size="small" className={style.formInput} name="categoryGroup" type="text" required label="Category Group" margin="normal" value={formData.categoryGroup} onChange={(e) => changeHandler(e)} variant="outlined" />
          </Grid>
          <Grid item>
            {updateQuery ? <Button onClick={(e) => updateFuction(e)} className="btn btn-sm px-3 ml-2">
              Update Category Group
            </Button> : <Button onClick={(e) => submitHandler(e)} className="btn btn-sm px-3 ml-2">
              Add Category Group
            </Button>}

          </Grid>
        </Grid>

        {/* Table */}
        <p className="m-0 text-dark py-2">
          {" "}
          <strong>Category Group List :</strong>
        </p>

        <DataTableComponent
          pageChange={(e) => { setTableOptions({ ...tableOptions, pageNo: e }) }}
          perRowsChange={(e) => setTableOptions({ ...tableOptions, perPage: e.perPage, pageNo: 1 })}
          setHandleSort={(e) => setTableOptions({ ...tableOptions, sortDirection: e.sortDirection, sortField: e.sortField })}
          expandable={false}
          inputFilterValue={filterValue}
          setSearchTrue={(e) => setIsSearch(true)}
          inputFilter={(e) => setFilterValue(e)}
          columnsData={columns}
          dataLoad={pending}
          totalRowsData={totalRows}
          data={filterItems} />
      </div>
    </div>
  );
}

export default AddCategoryGroup;
