import React, { useEffect, useState } from "react";
import { FormGroup, FormText, Input, Label, Table } from "reactstrap";
import style from "./style.module.css";
import { api } from "../../components/util/Data";
import { getCookie } from "../../components/util/Cokkies";
import { Grid } from "@mui/material";
import { Button } from "react-bootstrap";
// import Stack from "@mui/material/Stack";
// import Typography from "@mui/material/Typography";
import { Flex, Radio } from 'antd';
import Switch from "@mui/material/Switch";
import axios from "axios";
import moment from "moment";
import { styled } from "@mui/material/styles";

function MainBannerPage() {
  const [bannersData, setBannersData] = useState([]);
  const [formData, setFormData] = useState({
    type: "main",
    image: "",
    link: "",
  });
  const [imageUrl, setImageUrl] = useState("");
  const [bannerType, setBannerType] = useState("main");
  const [uploadFiles, setUploadFiles] = useState([]);
  const [fileSelected, setFileSelected] = useState(false);
  let token = getCookie("token");

  useEffect(() => {
    axios
      .post(
        api.url + "/get-main-banners",
        { id: "id" },
        { headers: { authorization: "Bearer " + token } }
      )
      .then(function (response) {
        // console.log(response.data);
        response.data.banner.forEach((item, index) => {
          item.serial = index + 1;
        });
        setBannersData(response.data.banner);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [token]);

  const uploadLogoImg = (e) => {
    const file = e.target.files[0];
    var reader = new FileReader();
    const form = new FormData();
    form.append("link", formData.link);
    form.append("type", formData.type);
    if (e.target.files && file) {
      form.append("bannerFile", file, file.name);
      setUploadFiles(form);
      // processFile(file);
      setImageUrl(URL.createObjectURL(file));
      reader.readAsDataURL(file);
      if (file.size > 200000) {
        alert("Size sould less then 200 kb ");
        setFileSelected(false);
      } else {
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            var height = this.height;
            var width = this.width;
            if (width <= height) {
              alert("Image's height & width sould be same");
              setFileSelected(false);
            } else {
              setFileSelected(true);
            }
          };
        };
      }
    } else {
      setFileSelected(false);
    }
  };

  const submitHandler = async () => {
    if (fileSelected) {
      axios
        .post(api.url + "/upload-main-banner", uploadFiles)
        .then(function (response) {
          // console.log(response)
          if (response.data.success) {
            alert("Banner Add Successfull");
            setBannersData((bannersData) => [
              ...bannersData,
              response.data.file,
            ]);
            setFileSelected(false);
          } else {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      alert("Please fill the required fields");
    }
  };

  const deleteBanner = (data) => {
    axios
      .post(api.url + "/delete-main-banner", { data })
      .then(function (response) {
        setBannersData("");
        const filter = bannersData.filter((el) => el !== data.image);
        setBannersData(filter);
        alert("Banner Deleted");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const switchToggle = (value) => {
    setFormData({ ...formData, type: value })
    setBannerType(value);
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, link: e.target.value });
  };

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Add Banner</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        {/* Add Categories */}
        <div>
          <Grid container spacing={1} className="form align-items-center">
            <Grid item md={7} className={"w-100"}>
              {/* <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>Main Banner</Typography>
                                <AntSwitch checked={bannerType} onChange={(e) => { switchToggle(e) }} inputProps={{ 'aria-label': 'ant design' }} />
                                <Typography>Ads Banners</Typography>
                            </Stack> */}
              <Flex vertical gap="middle">
                <Radio.Group defaultValue={bannerType} onChange={(e)=>switchToggle(e.target.value)}>
                  <Radio.Button value="main">Main</Radio.Button>
                  <Radio.Button value="ads">Ads 1</Radio.Button>
                  <Radio.Button value="ads-2">Ads 2</Radio.Button>
                </Radio.Group>
              </Flex>
            </Grid>

            <Grid item md={6} className={"w-100"}>
              <FormGroup>
                <Label className={style.formLabel} for="link">
                  Link
                </Label>
                <Input
                  bsSize="sm"
                  className={style.fileInput + " py-2"}
                  name="link"
                  id="link"
                  onChange={(e) => changeHandler(e)}
                  type="text"
                />
              </FormGroup>
            </Grid>
            <Grid item md={6} className={"w-100"}>
              <FormGroup>
                <Label className={style.formLabel} for="image">
                  Banner Image
                </Label>
                <Input
                  bsSize="sm"
                  className={style.fileInput + " py-2"}
                  name="image"
                  accept="image/*"
                  onChange={(e) => uploadLogoImg(e)}
                  type="file"
                />
                {formData.image ? (
                  <FormText>
                    <strong>Selected Image : {formData.image} </strong>
                  </FormText>
                ) : (
                  ""
                )}
              </FormGroup>
            </Grid>
            <Grid item md={6} className={"w-100"}>
              <Button
                onClick={(e) => submitHandler(e)}
                className="btn px-3 ml-2 pb-10"
              >
                Add Banner
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className="banner-list">
          <h4>Banners List : </h4>
          <div className="g-2">
            <Table hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Banner</th>
                  <th>Type</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {bannersData.map((el, i) => {
                  return (
                    <tr key={i}>
                      <th scope="row">{el.serial}</th>
                      <td>
                        <img
                          height={50}
                          src={api.bannerUrl + el.image}
                          alt={""}
                        />
                      </td>
                      <td>{el.type}</td>
                      <td>{moment(el.createdAt).format("DD/MM/YYYY")}</td>
                      <td>
                        <button
                          className="btn"
                          onClick={(e) => deleteBanner(el)}
                        >
                          <i className="fa-solid fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainBannerPage;
