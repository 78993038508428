import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DeleteIcon from "@mui/icons-material/Delete";
import style from './style.module.css'
import { IconButton } from "@mui/material";
import moment from "moment";
import { getCookie } from "../../components/util/Cokkies";
import { api } from "../../components/util/Data";
import axios from "axios";


function TestimonialsList() {
  let token = getCookie("token");
  const [pending, setPending] = useState(true);
  const [listData, setListData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);

  useEffect(() => {
    axios
      .post(api.url + "/get-testimonials", { id: "id" })
      .then(function (response) {
        // console.log(response.data);
        response.data.testimonials.forEach((item, index) => { item.serial = index + 1; });
        setListData(response.data.testimonials);
        setFilterItems(response.data.testimonials);
        setPending(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [filterItems]);

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      sortable: true,
      width: "65px",
    },
    {
      name: <strong>Name</strong>,
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: <strong>Designation</strong>,
      selector: (row) => row.designation,
      sortable: true,
    },
    {
      name: <strong>Comapany</strong>,
      selector: (row) => row.company,
      sortable: true,
    },
    // {
    //   name: <strong>Published</strong>,
    //   selector: (row) =><FormControlLabel className="mx-auto" control={<Android12Switch onChange={(e) => publishfunc(e, row._id)} checked={row.published} />} />,
    //   sortable: true,
    // },
    {
      name: <strong>ADD ON</strong>,
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      sortable: true,
      width: "120px"
    },
    {
      selector: (row) => <IconButton className={style.actionBtn} onClick={(e)=>deleteTest(row._id)} color="primary" size="small">
         <DeleteIcon fontSize="small" color="error" />
      </IconButton>,
      button: true,
      width: "65px",
    },
  ];

  const deleteTest =(id)=>{
    axios
    .post(api.url + "/delete-testimonials", { id }, { headers: { authorization: "Bearer " + token } })
    .then(function (response) {
      // console.log(response.data);
      if (response.data.success) {
        alert("Delete Successfull");
        const newListData = listData.filter((el) => {
          return el._id !== id;
        });
        setListData(newListData);
        setFilterItems(newListData);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const ExpandedComponent = ({ data }) => {
    return <div style={{ width: "100%", fontWeight: "bolder", padding:"15px" }}>
      <ul>
        <li><u>Company Name</u> : {data.companyName}</li>
        <li> <u>Message</u> : {data.message}</li>
      </ul>
    </div>;
  }

  const expandItem = () => {
    return <h3 className="text-danger m-1 ml-2">
      <i className="fas fa-caret-square-up"></i>
    </h3>
  }
  const collapseItem = () => {
    return <h3 className="text-primary m-1 ml-2">
      <i className="fas fa-caret-square-down"></i>
    </h3>
  }

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Testimonial List</h4>
          <hr className="my-2" />
        </div>
      </div>

      <DataTable pagination persistTableHead highlightOnHover columns={columns} data={filterItems} progressPending={pending} expandableRows expandableRowsComponent={ExpandedComponent} expandableIcon={{ collapsed: collapseItem(), expanded: expandItem() }} />
    </div>
  );
}

export default TestimonialsList;
