import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { Link, useNavigate } from "react-router-dom";
import { Button, IconButton } from "@mui/material";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import { getCookie } from "../../components/util/Cokkies";
import { api } from "../../components/util/Data";
import DataTableComponent from "../../components/elements/DataTableComponent";
import { userValidate } from "../../components/util/validation";
import axios from "axios";
import { useSelector } from "react-redux";


function LeadsList() {
  const navigate = useNavigate()
  const [listData, setListData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [rSelected, setRSelected] = useState(true);
  let token = getCookie("token");
  const currentCompanyUser = useSelector((state) => state.CompanyUser.data);
  const [totalRows, setTotalRows] = useState(0);
  const [pending, setPending] = useState(true);
  const [filterValue, setFilterValue] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [tableOptions, setTableOptions] = useState({ perPage: 10, pageNo: 1, sortDirection: "desc", sortField: "createdAt" });
  const verify_admin_hr = userValidate(['admin', 'hr'], 'token');

  useEffect(() => {
    if(currentCompanyUser.user){
      setPending(true);
      axios
        .post(api.url + "/get-leads-for-admin", { user: currentCompanyUser, forUser: rSelected, page: tableOptions.pageNo, sortDirection: tableOptions.sortDirection, sortField: tableOptions.sortField, perPage: tableOptions.perPage, key: filterValue })
        .then(function (response) {
          // console.log(response.data);
          response.data.leads.forEach((item, index) => {
            item.serial = index + 1 + (tableOptions.perPage * tableOptions.pageNo - tableOptions.perPage);
          });
          setTotalRows(response.data.total);
          setIsSearch(false)
          setListData(response.data.leads);
          setFilterItems(response.data.leads);
          setPending(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

  }, [currentCompanyUser, tableOptions, rSelected, isSearch]);

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      sortable: true,
      width: "65px",
    },
    {
      name: <strong>Unique ID</strong>,
      selector: (row) => row.leadId,
      sortable: true,
      width: "150px",
    },
    {
      name: <strong>Title</strong>,
      selector: (row) => row.leadTitle,
      sortable: true,
      width: "330px",
    },
    {
      name: <strong>Contact Person</strong>,
      selector: (row) => row.leadingName,
      sortable: true,
      width: "180px",
    },
    {
      name: <strong>Add On</strong>,
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      sortable: true,
      width: "120px",
    },
    {
      name: <strong>Action</strong>,
      cell: (row) =>
        verify_admin_hr ? (
          <>
            <Link to={"/admin/update-lead/" + row.leadId} state={row}>
              <IconButton>
                <EditIcon fontSize="small" color="info" />
              </IconButton>
            </Link>
            <IconButton onClick={() => deleteItem(row._id)}>
              <DeleteIcon fontSize="small" color="error" />
            </IconButton>
          </>
        ) : (
          <button
            className={"btn btn-sm btn-info py-0 " + style.actionBtn}
            onClick={(e) => {
              buyConfirmation(row, currentCompanyUser._id)
            }}
            color="primary"
            size="small"
          >
            Buy
          </button>
        ),
      button: true,
    },
  ];

  const deleteItem = (id) => {
    axios
      .post(api.url + "/delete-lead", { id }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        if (response.data.success) {
          alert("Delete Successfull");
          const newListData = listData.filter((el) => {
            return el._id !== id;
          });
          setListData(newListData);
          setFilterItems(newListData);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const buyConfirmation = (lead, compId) => {
    if (currentCompanyUser.leadsCredits > 0 && currentCompanyUser.membershipPlan > 0) {
      Swal.fire({
        html: `
        <h5 class="mb-3">Balance Lead Details :</h5>
        <table class="table table-sm table-borderless">
          <tbody>
            <tr> <td class="text-left">Available limit: ${currentCompanyUser.leadsCredits}</td> <td>${currentCompanyUser.leadsCredits}</td> </tr>
            <tr> <td class="text-left">Limit used for this lead</td> <td>1</td></tr>
            <tr> <th class="text-left">Remaining lead Balance</th> <th >${currentCompanyUser.leadsCredits - 1}</th> </tr>
          </tbody>
        </table>
        `,
        showCancelButton: true, confirmButtonColor: '#3085d6', cancelButtonColor: '#d33', confirmButtonText: 'Buy Now'
      }).then((result) => {
        console.log(result);
        if (result.isConfirmed) {
          buyLead(lead, compId)
        }
      })
    } else if (currentCompanyUser.leadsCredits > 0 && currentCompanyUser.membershipPlan < 0) {
      alert("Credits limit exceeded")
    } else{
      alert("You are not a paid user")
    }
  }

  const buyLead = (lead, compId) => {
    axios
      .post(api.url + "/buy-lead", { lead: lead._id, company: compId }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        // console.log(response);
        if (response.data.leads) {
          Swal.fire(
            'Purchased!',
            'You have Purchased this Leads...',
            'success'
          )
          let newData = currentCompanyUser;
          newData = { ...newData, leadsCredits: currentCompanyUser.leadsCredits - 1 };
        } else {
          alert(response.data.message)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const expComponent = (data) => {
    return (
      <div style={{ width: "100%", fontWeight: "bolder" }}>
        <ul>
          <li>
            <u>Title</u> : {data.leadTitle}
          </li>
          <li>
            <u>Message</u> : {data.leadMessage}
          </li>
          <li>
            <u>Company Name</u> : {data.company}
          </li>
          <li>
            <u>Contact No.</u> :{" "}
            <Button
              onClick={(e) => {
                alert("Please purchase lead for View Contact No.");
              }}
              className="text-danger text-decoration-none py-0"
            >
              XXXXXXXXXX
            </Button>
          </li>
          <li>
            <u>Email</u> :{" "}
            <Button
              onClick={(e) => {
                alert("Please purchase lead for View Email.");
              }}
              className="text-danger text-decoration-none py-0"
            >
              xxxxxxxx@xxxxx.xxx
            </Button>
          </li>
          <li>
            <u>Location</u> : {data.location}
          </li>
          <li>
            <u>Country</u> : {data.country}
          </li>
        </ul>
      </div>
    );
  };

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Leads List</h4>
          <hr className="my-2" />
        </div>
      </div>

      {verify_admin_hr ? (
        ""
      ) : (
        <div className="mb-2">
          <Button
            variant={rSelected ? "contained" : "outlined"}
            className={"px-5"}
            size="small"
            onClick={() => {
              setRSelected(true)
            }}
            >
            Leads for you
          </Button>
          <Button
            variant={rSelected ? "outlined" : "contained"}
            className={"px-5"}
            size="small"
            onClick={() => {
              setRSelected(false)
            }}
          >
            All Buy Leads
          </Button>
        </div>
      )}

      <DataTableComponent
        pageChange={(e) => { setTableOptions({ ...tableOptions, pageNo: e }) }}
        perRowsChange={(e) => setTableOptions({ ...tableOptions, perPage: e.perPage, pageNo: 1 })}
        setHandleSort={(e) => setTableOptions({ ...tableOptions, sortDirection: e.sortDirection, sortField: e.sortField })}
        expandedComponent={e => expComponent(e)}
        expandable={true}
        inputFilterValue={filterValue}
        setSearchTrue={(e) => { setRSelected(e) }}
        inputFilter={(e) => setFilterValue(e)}
        dataLoad={pending}
        columnsData={columns}
        totalRowsData={totalRows}
        data={filterItems} />
    </div>
  );
}

export default LeadsList;
