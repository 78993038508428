import { useEffect } from "react";
import { Link } from "react-router-dom";

const CategoryProduct = (props) => {
    const makeLink = (str) => {
        return str.replace(/^\s+|\s+$/g, '').replaceAll(" ", "_").toLowerCase();
    }

    useEffect(() => {
        // console.log(props)
    }, [props])

    return (
        <li className="mb-1">
            <Link to={"/group-directory/" + makeLink(props.groupData.categoryGroup)} state={{ group: props.groupData, category: props.catData.filter((ele) => ele.categoryGroup === props.groupData._id), subData: props.subData }} >
                <strong>{props.title}</strong>
            </Link>
        </li>
    );
};

export default CategoryProduct