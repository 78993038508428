import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

export default function Faq() {

    const faqs = []
    return (
        <div className="container px-0">
            <div className="page-header breadcrumb-wrap undefined mb-10">
                <div className="container">
                    <div className="breadcrumb"><Link to="/">Home</Link><span></span>Advertize with us</div>
                </div>
            </div>
            <div className="row px-5">
                <div className="col-lg-6 p-4">
                <h3 className='text-brand mb-2'>Advertise with Us</h3>
                    <h4>Make Your Brand Visible ByWorld Business Mart's Banner Advertisement Service</h4>
                    At World Business Mart, we understand the importance of visibility and brand recognition in today's competitive business landscape. That's why we offer banner advertisement opportunities on our highly-trafficked portal, ensuring that your business gets the exposure it deserves. Our dedicated team of experts will work closely with you to create compelling banner ads that effectively convey your message and capture the attention of our users.
                    <h4 className='mt-10'> Why choose World Business Mart for your banner advertisement needs? </h4>
                    •	Global Reach: With a worldwide user base, World Business Mart offers you the opportunity to connect with customers from all corners of the globe. Expand your reach and tap into new markets by leveraging our international audience.
                    •	Targeted Advertising: We understand that reaching the right audience is crucial for maximizing your advertising efforts. With our advanced targeting options, you can choose specific demographics, geographic locations, and user interests to ensure that your banner ads are seen by the right people.
                    •	High Visibility: World Business Mart attracts a large volume of visitors every day, providing ample opportunities for your banner ads to be seen. Our strategically placed banners ensure maximum visibility, increasing the likelihood of user engagement and conversions.
                    •	Flexible Options: We offer various banner ad formats and sizes to accommodate your specific needs. Whether you prefer static images or animated banners, we can tailor the advertisement to match your branding and campaign goals.
                    •	Performance Tracking: At World Business Mart, we believe in data-driven decision making. Our comprehensive analytics dashboard provides you with valuable insights into the performance of your banner ads. Monitor click-through rates, impressions, and conversions to optimize your campaigns and achieve better results.
                    •	Competitive Pricing: We understand the importance of cost-effectiveness for businesses of all sizes. That's why we offer competitive pricing options for our banner advertisement services. Choose a package that suits your budget and objectives, and let us help you get the best return on your investment.
                </div>
                <div className="col-lg-6 p-5">
                    <img src="/assets/imgs/banner/advertise.webp" alt="" />
                </div>
            </div>
        </div>
    )
}
