import { createSlice } from "@reduxjs/toolkit";

export const counter = createSlice({
  name: "Counter",
  initialState: {
    data: {},
  },
  reducers: {
    getAllocationCount: (state, action) => {
      state.data = action.payload;
    },
    deleteAllocationCount: (state, action) => {
      state.data = {...state.data, [action.payload] : state.data[action.payload] - 1 }
    },
    addAllocationCount: (state, action) => {
      state.data = {...state.data, [action.payload] : state.data[action.payload] + 1 }
    },
  },
});

export const { addAllocationCount, deleteAllocationCount, getAllocationCount} = counter.actions;

export default counter.reducer;
