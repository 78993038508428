import { useEffect } from "react";

function PrivacyPolicy() {
     useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [])
    return (
        <>
            <div className="page-content pt-50">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 col-lg-12 m-auto">
                            <div className="row">
                                <div className="col-lg-9 m-auto">
                                    <div className="single-page pr-30 mb-lg-0 mb-sm-5">
                                        <div className="single-header style-2">
                                            <h2>Privacy Policy</h2>
                                        </div>
                                        <div className="single-content mb-50">
                                            <p>
                                                World Business Mart ("we," "us," "our") is committed to protecting the privacy of our users ("you," "your") who access our website at www.worldbusinessmart.com (the "Website") and use our services. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our Website.
                                            </p>
                                            <h4 className="mb-20 mt-30">
                                                Information We Collect
                                            </h4>
                                            <p className="mb-10">
                                                <strong>
                                                    We may collect various types of information from you, including but not limited to:
                                                </strong>
                                            </p>
                                            <ul>

                                                <li>
                                                    Personal Information: We may collect personal information, such as your name, email address, phone number, and company details, when you register for an account, submit inquiries, or engage in transactions on our platform.
                                                </li>
                                                <li>
                                                    Usage Information: We may collect information about your use of the Website, such as your IP address, browser type, device type, pages visited, and actions taken on the Website, to improve our services, analyze trends, and customize your experience.
                                                </li>
                                                <li>
                                                    Cookies and Similar Technologies: We may use cookies, web beacons, and other similar technologies to collect information about your browsing behavior on our Website, store your preferences, and provide personalized content and advertisements.
                                                </li>
                                            </ul>

                                            <h4 className="mb-20 mt-30">
                                                How We Use Your Information
                                            </h4>
                                            <p className="mb-10">
                                                <strong>
                                                    We may use the information we collect from you for various purposes, including but not limited to:
                                                </strong>
                                            </p>
                                            <ul>
                                                <li>
                                                    Providing and Improving Our Services: We use your personal information to provide and improve our services, including facilitating transactions, communicating with you, customizing your experience, and responding to your inquiries and feedback.
                                                </li>
                                                <li>
                                                    Marketing and Advertising: We may use your information to send you promotional materials, newsletters, and updates about our services, and to display personalized content and advertisements based on your interests and browsing behavior.
                                                </li>
                                                <li>
                                                    Analytics and Research: We may use your information to analyze trends, track usage patterns, and conduct research to improve our services, develop new features, and enhance the user experience.
                                                </li>
                                                <li>
                                                    Legal Compliance: We may use your information to comply with applicable laws, regulations, and legal processes, and to protect our rights, property, and safety, as well as the rights, property, and safety of our users and others.
                                                </li>
                                            </ul>

                                            <h4 className="mb-20 mt-30">
                                                How We Share Your Information
                                            </h4>
                                            <p className="mb-10">
                                                <strong>
                                                    We may share your information with third parties in the following circumstances:
                                                </strong>
                                            </p>
                                            <ul>
                                                <li>
                                                    Service Providers: We may share your information with third-party service providers who help us operate, maintain, and improve our Website and services, such as hosting, payment processing, and marketing.
                                                </li>
                                                <li>
                                                    Business Partners: We may share your information with our business partners, such as manufacturers, exporters, and suppliers, to facilitate transactions and provide you with relevant products and services.
                                                </li>
                                                <li>
                                                    Legal Compliance: We may disclose your information as required by law, regulation, or legal process, or to protect our rights, property, and safety, as well as the rights, property, and safety of our users and others.
                                                </li>
                                                <li>
                                                    Aggregated or De-identified Information: We may share aggregated or de-identified information that does not personally identify you with third parties for research, marketing, and other purposes.
                                                </li>
                                            </ul>

                                            <h4 className="mb-20 mt-30">
                                                Your Choices and Rights
                                            </h4>
                                            <p><strong className="mb-10">
                                                You have certain choices and rights regarding your personal information:
                                            </strong></p>
                                            <ul>
                                                <li>
                                                    Account Information: You can update or modify your account information at any time by logging into your account on the Website.
                                                </li>
                                                <li>
                                                    Cookies: You can disable or delete cookies through your browser settings, although doing so may affect your experience on the Website.
                                                </li>
                                                <li>
                                                    Marketing Communications: You can unsubscribe from our marketing communications at any time by following the instructions in the emails we send you or by contacting us at the contact information provided below.
                                                </li>
                                                <li>
                                                    Access and Correction: You have the right to request access to and correction of your personal information held by us. Please contact us at the contact information provided below to make such requests.
                                                </li>
                                            </ul>
                                            <h4 className="mb-20 mt-30">
                                                Data Security
                                            </h4>
                                            <p><strong className="mb-10">
                                                We take reasonable measures to protect the security of your personal information.
                                            </strong></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PrivacyPolicy;
