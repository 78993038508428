import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Notfound from "./pages/Notfound";
import Home from "./pages/Home";
import AdminLayoutRout from "./pages/admin/AdminLayoutRout";
import Login from "./pages/Login";
import Join from "./pages/Join";
import About from "./pages/About";
import TermsConditions from "./pages/TermsConditions";
import Contact from "./pages/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import LeadsPages from "./pages/LeadsPages";
import RequirmentForm from "./pages/RequirmentForm";
import ProductPage from "./pages/ProductPage";
import LeadDetail from "./pages/LeadDetail";
import ProductDetail from "./pages/ProductDetail";
import CompanyPage from "./pages/CompanyPage";
import CatalogRout from "./pages/Catalog/CatalogRout";
import ProductDirectory from "./pages/ProductDirectory";
import CategoryDirectory from "./pages/CategoryDirectory";
import GroupDirectory from "./pages/GroupDirectory";
import RegionalPage from "./pages/RegionalPage";
import MembershipPlan from "./pages/admin/MembershipPlan";
import Careers from "./pages/Careers";
import Faq from "./pages/Faq";
import Advertize from "./pages/Advertize";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/buy_leads" element={<LeadsPages />}></Route>
          <Route path="/search_buy_lead/:key" element={<LeadsPages />}></Route>
          <Route path="/regional-directory/:country" element={<LeadsPages />}></Route>
          <Route path="/products/:category" element={<ProductPage />}></Route>
          <Route path="/search_product/:key" element={<ProductPage />}></Route>
          <Route path="/product-detail/:company/:product" element={<ProductDetail />}></Route>
          <Route path="/regional-directory" element={<RegionalPage />}></Route>
          <Route path="/country-directory/:country" element={<CompanyPage />}></Route>
          <Route path="/search_company/:key" element={<CompanyPage />}></Route>
          <Route path="/lead-detail/:id" element={<LeadDetail />}></Route>
          <Route path="/requirement" element={<RequirmentForm />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/join" element={<Join />}></Route>
          <Route path="/faqs" element={<Faq />}></Route>
          <Route path="/careers" element={<Careers />}></Route>
          <Route path="/plans" element={<MembershipPlan />}></Route>
          <Route path="/advertize-with-us" element={<Advertize />}></Route>
          <Route path="/product-directory" element={<ProductDirectory />}></Route>
          <Route path="/category-directory/:category" element={<CategoryDirectory />}></Route>
          <Route path="/group-directory/:category" element={<GroupDirectory />}></Route>
          
          <Route path="/policy" element={<PrivacyPolicy />}></Route>
          <Route path="/terms" element={<TermsConditions />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="*" element={<Notfound direction={""} />} />
        </Route>
        <Route path="/admin/*" element={<AdminLayoutRout />}></Route>
        <Route path="/company/:link/*" element={<CatalogRout />}></Route>
      </Routes>
    </BrowserRouter >
  );
}

export default App;
