import React, { useEffect, useState } from 'react'
import CatalogBanner from './CatalogBanner'
import { Link, useOutletContext, useParams } from "react-router-dom";
import style from './style.module.css'
import axios from 'axios';
import { api } from '../../components/util/Data';
import { Image } from 'antd';

export default function CatalogHome() {
  const params = useParams()
  const [companyDetails] = useOutletContext();
  const [loading, setLoading] = useState(true)
  const [productsList, setProductsList] = useState([])

  useEffect(() => {
    setLoading(true)
    console.log(params);
    axios
      .post(api.url + "/get-featured-products", { company: companyDetails._id })
      .then(function (response) {
        setProductsList(response.data.products)
        setLoading(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [companyDetails._id])

  return (
    <>
      <CatalogBanner banners={companyDetails.banners} />
      <div className="col-xl-10 m-auto">
        <div className="my-4">
          <h4 className="section-title style-1 mb-15"><span className='text-dark'>About {companyDetails.company}</span></h4>
          {companyDetails.aboutCompany.split("\n").map((el, i)=>{
            return <p key={i} className='text-dark m-0 my-2'>{el} </p>
          })}

          <div className={"row py-4 text-center " + style.catalogTabs}>
            <div className="col-md-3">
              <div className="featured-card">
                <p className="mb-5"><strong>CEO</strong></p>
                <h4 className="mb-10 text-brand">{companyDetails.companyCEO}</h4>
              </div>
            </div>
            <div className="col-md-3">
              <div className="featured-card">
                <p className="mb-5"><strong>Business Type</strong></p>
                <h4 className="mb-10 text-brand">{companyDetails.businessType.join(", ")}</h4>
              </div>
            </div>
            <div className="col-md-3">
              <div className="featured-card">
                <p className="mb-5"><strong>Estb. Year</strong></p>
                <h4 className="mb-10 text-brand">{companyDetails.yearEstb}</h4>
              </div>
            </div>
            <div className="col-md-3">
              <div className="featured-card">
                <p className="mb-5"><strong>Country</strong></p>
                <h4 className="mb-10 text-brand"><img style={{ width: "25px", margin: "0px" }} src={companyDetails.countryFlag} alt={companyDetails.country} /> {companyDetails.country}</h4>
              </div>
            </div>
          </div>

          <div className="my-4">
            <h4 className="section-title style-1 mb-15 animated animated">Our Hot Products
              <Link to={"/company/" + companyDetails.catalogLink + "/products"} className="show-all">
                More <i className="fi-rs-angle-right"></i>
              </Link></h4>
            {loading ? <h5>Loading...</h5> : <div className="row g-4">
              {productsList.map((el, i) => {
                return <div key={i} className="col-md-3">
                  <div className={style.productCard} >
                    <div className="card">
                      <div className="img-head p-3" style={{ maxHeight: "250p", overflow: "hidden" }}>
                        <a href={"/product-detail/" + el.productLink} rel="noreferrer" target={"_blank"}>
                          <img alt={el.productName} src={api.productImg + el.image} className="card-img-top" />
                        </a>
                      </div>
                      <div className="card-body pt-0">
                        <a href={"/product-detail/" + el.productLink} rel="noreferrer" target={"_blank"}><h5 className="card-title">{el.productName}</h5></a>
                        <p className="card-text" style={{minHeight: "150px"}}>{el.productDetails.substring(0, 150)}...</p>
                        <a href={"/product-detail/" + el.productLink} rel="noreferrer" target={"_blank"} className="btn btn-primary py-1">View</a>
                      </div>
                    </div>
                  </div>
                </div>
              })}
            </div>}
          </div>

          <div className="my-4">
            <h4 className="section-title style-1 mb-15 animated animated">Gallery
              <Link to={"/company/" + companyDetails.catalogLink + "/gallery"} className="show-all">
                More <i className="fi-rs-angle-right"></i>
              </Link></h4>
            {loading ? <h5>Loading...</h5> :
              <Image.PreviewGroup
                preview={{
                  onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                }}
              >
                {companyDetails.gallery.map((el, i) => {
                  if (i < 6) {
                    return <Image key={i} height={200} className='p-2' src={api.galleryUrl + el} />
                  }
                })}
              </Image.PreviewGroup>
            }
          </div>
        </div>
      </div>
    </>
  )
}
