import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function TopHeader() {

    // useEffect(() => {

    //     var addScript = document.createElement("script");
    //     addScript.setAttribute(
    //       "src",
    //       "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    //     );
    //     document.body.appendChild(addScript);
    //     window.googleTranslateElementInit = googleTranslateElementInit;
    // }, [])

    // const googleTranslateElementInit = () => {
    //     new window.google.translate.TranslateElement(
    //       {
    //         includedLanguages: 'bn,de,nl,en,es,it,fr,hi', defaultLanguage:'en', pageLanguage: "en", layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE, autoDisplay: false, multilanguagePage:true 
    //       },
    //       "google_translate_element"
    //     );
    //   };
    return (
        <div className="header-top header-top-ptb-1 d-none d-lg-block">
            <div className="container">
                <div className="header-info header-info-right">
                    <ul>
                        <li>
                            Call on Toll Free Number:
                            <strong className="text-brand">
                                {" "}
                                +91-8810200550
                            </strong>
                        </li>
                        <li>
                            <Link to="/product-directory">
                                For buyer
                            </Link>
                        </li>
                        <li>
                            <Link to="/buy_leads"> For seller </Link>
                        </li>
                        <li>
                            <Link to="/join"> Register Now </Link>
                        </li>
                        <li>
                            <div className='mx-2' id="google_translate_element"></div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
