import { useEffect } from "react";

function About() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [])

    return (
        <>
            <div className="container  pt-50">
                <div className="row">
                    <div className="col-xl-10 col-lg-12 m-auto">
                        <section className="row align-items-center mb-50">
                            <div className="col-lg-6">
                                <img src="/assets/imgs/page/about-1.webp" alt="" className="border-radius-15 mb-md-3 mb-lg-0 mb-sm-4" />
                            </div>
                            <div className="col-lg-6">
                                <div className="pl-25 pb-30">
                                    <h4 className="mt-30 mb-10 text-muted">Welcome to </h4>
                                    <h2 className="mb-30">World Business Mart</h2>
                                    <p className="mb-25">Welcome to World Business Mart – Your Ultimate B2B Marketplace for Bulk Products!</p>
                                    <p className="mb-50">At World Business Mart, we are proud to be the leading B2B portal and marketplace that connects manufacturers, exporters, and suppliers from around the world. Whether you are a seasoned business professional or a budding entrepreneur, our platform is designed to cater to your bulk product selling needs with unparalleled efficiency and convenience.</p>

                                </div>
                            </div>
                            <div className="col-12 single-content">
                                <ul>
                                    <li>Why Choose World Business Mart?</li>
                                    <li>Global Reach: With a vast network of registered buyers and sellers spanning across continents, World Business Mart provides you with an extensive global reach. Connect with potential customers and suppliers from diverse industries and geographies, and expand your business horizons like never before.</li>
                                    <li>Wide Product Range: Our platform offers an extensive range of bulk products, encompassing various industries such as electronics, textiles, chemicals, machinery, and more. From raw materials to finished goods, you can find it all on World Business Mart, making it a one-stop-shop for all your B2B needs.</li>
                                    <li>Verified Suppliers: We understand the importance of trust in the B2B world. That's why we ensure that all our registered suppliers go through a thorough verification process to ensure their credibility and reliability. Rest assured, you can trade with confidence on our platform, knowing that you are dealing with legitimate and trustworthy suppliers.</li>
                                    <li>Easy-to-Use Platform: Our user-friendly interface and intuitive navigation make it seamless for you to browse, search, and connect with potential buyers and sellers. Our platform is designed to save you time and effort, enabling you to focus on growing your business.</li>
                                    <li>Powerful Tools: World Business Mart provides a range of powerful tools and features to enhance your B2B trading experience. From personalized profiles and messaging options to product showcases and trade leads, our platform empowers you to showcase your products, connect with relevant stakeholders, and close deals efficiently.</li>
                                    <li>Exceptional Customer Support: Our dedicated customer support team is always ready to assist you in resolving any queries or issues. We are committed to providing you with a seamless and hassle-free experience on our platform.</li>
                                </ul>
                            </div>
                        </section>
                        <section className="row align-items-center mb-50">
                            <div className="row mb-50 align-items-center">
                                <div className="col-lg-7 pr-30">
                                    <img src="/assets/imgs/page/about-5.png" alt="" className="mb-md-3 mb-lg-0 mb-sm-4" />
                                </div>
                                <div className="col-lg-5">
                                    <h4 className="mb-20">Join World Business Mart Today!</h4>
                                    <p className="mb-10">Whether you are a manufacturer, exporter, or supplier, World Business Mart is the ideal platform for you to expand your B2B trade. With our global reach, wide product range, verified suppliers, easy-to-use platform, powerful tools, and exceptional customer support, you can boost your business growth and achieve success in the competitive B2B market.</p>
                                    <p className="mb-10">Join World Business Mart today and unlock endless opportunities for bulk product selling! Sign up now and experience the power of B2B trading like never before.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 pr-30 mb-md-5 mb-lg-0 mb-sm-5">
                                    <h3 className="mb-30">Mission</h3>
                                    <p>Our mission at World Business Mart is to empower businesses worldwide by providing a cutting-edge B2B portal and marketplace that facilitates seamless, efficient, and trusted bulk product trading. We are committed to connecting manufacturers, exporters, and suppliers from diverse industries, geographies, and backgrounds, and empowering them with the tools and resources they need to thrive in the global marketplace.</p>
                                </div>
                                <div className="col-lg-6">
                                    <h3 className="mb-30">Vision</h3>
                                    <p>Our vision at World Business Mart is to be the leading B2B portal and marketplace, recognized for our innovation, reliability, and customer-centric approach. We aspire to be the go-to platform for businesses seeking to buy or sell bulk products, and to be the catalyst that accelerates B2B trade across the globe. We envision a world where businesses can effortlessly connect, trade, and grow, powered by our cutting-edge technology and unwavering commitment to excellence.</p>
                                </div>
                            </div>
                        </section>

                        <section className="row align-items-center mb-50">
                            <div className="col-12 single-content">
                                <h3>
                                    Values
                                </h3>
                                <ul>
                                    <li>Trustworthiness: We believe that trust is the foundation of successful B2B trade. We strive to build and maintain trust with our users by verifying suppliers, safeguarding data privacy, and promoting transparency and integrity in all our interactions.</li>
                                    <li>Innovation: We are dedicated to staying at the forefront of technology and innovation, constantly enhancing our platform with new features, tools, and capabilities. We strive to provide a seamless and user-friendly experience for our users, leveraging the latest advancements in the digital world.</li>
                                    <li>Customer-Centricity: Our users are at the heart of everything we do. We are committed to understanding their needs, providing exceptional customer support, and continuously improving our platform based on their feedback. We aim to exceed our users' expectations and be their trusted partner in their B2B trade journey.</li>
                                    <li>Diversity & Inclusivity: We celebrate diversity and inclusivity, embracing businesses of all sizes, types, and backgrounds. We believe that diversity fosters innovation and creativity, and we strive to create a platform that is inclusive and welcoming to all, regardless of their location, industry, or business size.</li>
                                    <li>Excellence: We are committed to excellence in all aspects of our operations, from the quality of our platform to the level of service we provide. We continuously strive to improve and deliver outstanding results, setting high standards for ourselves and pushing the boundaries of what is possible in the B2B trading space.</li>
                                </ul>
                                <p className="pt-20">
                                    <strong>Join World Business Mart today and experience the power of our mission-driven approach, visionary outlook, and core values. We look forward to serving as your trusted partner in your B2B trade journey and helping you achieve your business goals.</strong>
                                </p>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
}

export default About;
