import React from "react";
import Search from "../ecommerce/Search";
import { Link } from "react-router-dom";
import TopHeader from "../elements/TopHeader";

const Header = ({
    toggleClick
}) => {

    return (
        <>
            <header className="header-area header-style-1 header-height-2">
                {/*Mobile Top Mini Header */}
                <div className="mobile-promotion">
                    <ul>
                        <li>
                            Need help? Call Us :{" "}
                            <strong>
                                {" "}
                                +91-8810200550
                            </strong>
                        </li>
                    </ul>
                </div>

                {/*Top Mini Header */}
                <div className={"header-bottom header-bottom-bg-color sticky-bar stick"} >
                    <div className="container">
                        {/*Top Mini Header */}
                        <TopHeader />
                        <div className="header-wrap header-space-between position-relative">

                            <div className="header-nav d-none d-lg-flex w-100">
                                <div className="logo logo-width-1">
                                    <Link to="/">
                                        <img
                                            src="/logo.png"
                                            alt="logo"
                                        />
                                    </Link>
                                </div>
                                <div className="header-right">
                                    <div className="search-style-2">
                                        <Search />
                                    </div>
                                    <div className="header-action-right">
                                        <div className="header-action-2">
                                            <div className="header-action-icon-2">
                                                <div className="main-categori-wrap d-none d-lg-block">
                                                    <Link to={"/requirement"} className="categories-button-active">
                                                        <span className="fi-rs-paper-plane"></span>
                                                        <span className="et">Post</span> your Requirement
                                                    </Link>

                                                </div>
                                            </div>

                                            <div className="header-action-icon-2">
                                                <div className="main-categori-wrap d-none d-lg-block">
                                                    <a href="/login" target="_blank" className="categories-button-active">
                                                        <span className="fi-rs-user"></span>
                                                        <span className="et">User</span> Panel
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="logo logo-width-1 d-block d-lg-none">
                                <Link to="/">

                                    <img
                                        src="/logo.png"
                                        alt="logo"
                                    />

                                </Link>
                            </div>

                            <div className="header-action-right d-block d-lg-none">
                                <div className="header-action-2">
                                    <div className="header-action-icon-2 d-block d-lg-none">
                                        <div
                                            className="burger-icon burger-icon-white"
                                            onClick={toggleClick}
                                        >
                                            <span className="burger-icon-top"></span>
                                            <span className="burger-icon-mid"></span>
                                            <span className="burger-icon-bottom"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};


export default Header;
