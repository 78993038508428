import React, { useEffect } from 'react'
import { Route, Routes } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { mainReduxFunction } from "../../components/util/mainRedux";
import CatalogLayout from '../../components/Layout/CatalogLayout';
import CatalogHome from './CatalogHome';
import CatalogProducts from './CatalogProducts';
import CatalogContact from './CatalogContact';
import CatalogGallery from './CatalogGallery';

export default function CatalogRout() {
    const disPatch = useDispatch();
    useEffect(() => {
        mainReduxFunction(disPatch)
    }, [disPatch])
    return (
        <Routes>
            <Route path="/" element={<CatalogLayout />}>
                <Route path="/" element={<CatalogHome />} />
                <Route path="/products" element={<CatalogProducts />}></Route>
                <Route path="/contact" element={<CatalogContact />}></Route>
                <Route path="/gallery" element={<CatalogGallery />}></Route>
            </Route>
        </Routes>
    )
}
