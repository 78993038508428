import { useEffect, useState } from "react";
import { SweetAlert, formValidation } from "../components/util/validation";
import axios from "axios";
import { api } from "../components/util/Data";
import { Link } from "react-router-dom";

function Join() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [])
    
    const [formData, setFormData] = useState({ userName: "", email: "", password: "", confirmPassword: "", firstName: "", lastName: "", company: "", contact: "", catalogLink: "", joinYear:0, companyAddress: "", membershipPlan:0, leadsCredits:0 })
    const [submiting, setSubmiting] = useState(false)

    const changeHandler = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });                                                    
    }

    const makeLink = (str) => {
        console.log(str);
        
        return str.replace(/^\s+|\s+$/g, '').replaceAll(" ", "_").toLowerCase();
      }

    const createCatalogLink = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value, catalogLink : makeLink(e.target.value)});                                                    
        // var format = /[!@#$%^*+]/g;
        // if (!format.test(e.target.value) || e.target.value) { setFormData({ ...formData, catalogLink: e.target.value.toLowerCase().replace(" ", "") }); }
    }

    const submitHandler = (e) => {
        e.preventDefault();

        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (formValidation([formData.userName, formData.firstName, formData.catalogLink, formData.email, formData.contact, formData.company, formData.companyAddress])) {
            if (filter.test(formData.email)) {
                if(formData.password == formData.confirmPassword){
                    axios.post(api.url + "/signup", formData).then(function (response) {
                        console.log(response);
                        if (response.data.success) {
                            SweetAlert({
                                title: 'You have Join Successfull',
                                icon: 'success',
                                footer: '<a href="/login" class="btn btn-light">Go to login page</a>'
                            })
                            setSubmiting(false)
                        } else {
                            SweetAlert({
                                title: response.data.message,
                                icon: 'error',
                            })
                            setSubmiting(false)
                            // alert(response.data.message);
                        }
                    }).catch(function (error) {
                        console.log(error);
                        SweetAlert({
                            title: 'Please contact admin',
                            icon: 'error',
                        })
                        setSubmiting(false)
                    });
                }else{
                    SweetAlert({
                        title: 'Password are not same',
                        icon: 'warning',
                    })
                    setSubmiting(false)
                }

            } else {
                SweetAlert({
                    title: 'Please enter valid email',
                    icon: 'warning',
                })
                setSubmiting(false)
            }
        } else {
            SweetAlert({
                title: 'All fields are required',
                icon: 'error'
            })
            setSubmiting(false)
        }
    };

    return (
        <>
                <div className="page-content pt-20 pb-20">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 col-md-12 m-auto">
                                <div className="row align-items-center">
                                    <div className="col-lg-5 pr-30 d-none d-lg-block">
                                        <img className="border-radius-15" src="assets/imgs/page/login-1.webp" alt="" />
                                    </div>
                                    <div className="col-lg-7 col-md-8">
                                        <div className="login_wrap widget-taber-content background-white">
                                            <div className="padding_eight_all bg-white p-3">
                                                <div className="heading_s1 mb-30">
                                                    <h1 className="mb-5">Join Information</h1>
                                                </div>
                                                <form onSubmit={submitHandler} className="row g-2" >
                                                    <div className="form-group col-md-6">
                                                        <input type="text" name="userName" onChange={changeHandler} placeholder="Username *" />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <input type="text" name="email" onChange={changeHandler} placeholder="Email *" />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <input type="password" name="password" onChange={changeHandler} placeholder="Your password *" />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <input type="text" name="confirmPassword" onChange={changeHandler} placeholder="Confirm password*" />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <input type="text" name="firstName" onChange={changeHandler} placeholder="First Name *" />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <input type="text" name="lastName" onChange={changeHandler} placeholder="Last Name *" />
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <input type="text" name="company" onChange={(e)=>createCatalogLink(e)} placeholder="Company Name *" />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <input type="number" name="contact" onChange={changeHandler} placeholder="Contact *" />
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <textarea type="text" name="companyAddress" onChange={changeHandler} placeholder="Company Address *" />
                                                    </div>
                                                    <div className="form-group">
                                                        <button type="submit" className="btn btn-heading btn-block hover-up" name="login">Join Now</button>
                                                    </div>
                                                </form>
                                                <h5 className="mb-30 MT-30">Already Join ? <Link to="/login">Login</Link></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
}

export default Join;
