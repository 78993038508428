import React, { useEffect, useMemo, useState } from "react";
import style from "./style.module.css";
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import countriesArray from '../../components/elements/country'
import SelectJS from "../../components/elements/SelectJS";
import { api } from "../../components/util/Data";
import CreatableSelect from 'react-select/creatable';
import { getCookie } from "../../components/util/Cokkies";
import { formValidation } from "../../components/util/validation";
import { Button } from "react-bootstrap";
import { FormGroup, Input, Label } from "reactstrap";
import axios from "axios";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useLocation, useNavigate } from "react-router-dom";

function UpdateCompany() {
  const options = useMemo(() => countriesArray.map((el) => { return { label: el.name, value: el.name, code: el.callingCodes[0], flag: el.flags.svg } }), [])
  let token = getCookie("token");
  const navigate = useNavigate()
  const location = useLocation()
  const [formData, setFormData] = useState(location.state);
  const [uploadLogo, setUploadLogo] = useState({});
  const [imageName, setImageName] = useState("");
  const [fileSelected, setFileSelected] = useState(false);
  const changeHandler = (e) => { setFormData({ ...formData, [e.target.name]: e.target.value }) };
  const planArray = [{ plan: "Guest", leads: 0 }, { plan: "Economy", leads: 10 }, { plan: "Gold", leads: 30 }, { plan: "Platinum", leads: 60 }, { plan: "Platinum Pro", leads: 80 }, { plan: "Global", leads: 100 }]

  useEffect(() => {
    setFormData({ ...formData, "preImage": formData.companyLogo });
  }, [])

  const customThemeFn = (theme) => ({
    ...theme, spacing: { ...theme.spacing, controlHeight: 30, baseUnit: 2 },
  });

  const onSelecteChange = (e) => {
    setFormData({ ...formData, "dealsIn": e.map((el) => el.value) });
  };

  const selectPlan = (e) => {
    setFormData({ ...formData, "membershipPlan": e.target.value, leadsCredits: planArray[e.target.value].leads });
  }
  
  const setShowfunc = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  
  const setIsVerified = (e) => {
    setFormData({ ...formData, "isVerified": e.target.value }) 
  }

  const submitHandler = async (e) => {
    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (formValidation([formData.firstName, formData.contact, formData.company, formData.companyAddress, formData.companyCEO, formData.yearEstb, formData.catalogLink, formData.location, formData.dealsIn.length > 0, formData.aboutCompany, formData.businessType, formData.country])) {
      if (filter.test(formData.email)) {
        if (!fileSelected) {
          updateCompany(formData, formData.companyLogo)
        } else {
          let request = await fetch(api.url + "/selectFile", {
            method: "post",
            body: uploadLogo,
            headers: { authorization: "Bearer " + token },
          });
          const response = await request.json();
          if (response.success) {
            updateCompany(formData, response.newFileName + "-" + imageName);
          }
        }
      } else {
        alert("Please enter valid email / contact");
      }
    } else {
      alert("Please fill the required fields");
    }
  };

  const updateCompany = (data, newFile) => {
    let newData = data;
    newData = { ...newData, image: newFile };

    axios
      .post(api.url + "/update-company", newData, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        if (response.data) {
          alert("Update Successfull");
          navigate("/admin/company-list")
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const selectedOptionOutput = value => {
    setFormData({ ...formData, country: value.value, countryFlag: value.flag, callCode: value.code });
  }

  const uploadLogoImg = (e) => {
    if (e.target.files[0] !== undefined && e.target.files[0].size < 41500) {
      const form = new FormData();
      if (e.target.files[0] === undefined) {
        setFileSelected(false);
      } else {
        setImageName(e.target.files[0].name);
        form.append("files", e.target.files[0]);
        setFileSelected(true);
        setUploadLogo(form);
      }
    } else {
      alert("Logo sould be less then 40kb")
      setFileSelected(false);
    }
  };

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Update Details</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        {/* Login Information */}
        <Grid container spacing={1} className="form">
          {/* <UploadFile/> */}
          <Input
            bsSize="sm"
            className={style.fileInput + " py-2 my-2"}
            name="image"
            accept="image/*"
            onChange={(e) => uploadLogoImg(e)}
            type="file"
          />
          <Grid item md={4} sm={6} className={"w-100"}>
            <TextField size="small" className={style.formInput} name="firstName" type="text" required label="First Name" margin="normal" defaultValue={formData.firstName} onChange={(e) => changeHandler(e)} variant="outlined" />
          </Grid>

          <Grid item md={4} sm={6} className={"w-100"}>
            <TextField size="small" className={style.formInput} name="lastName" type="text" required label="Enter Last Name" margin="normal" defaultValue={formData.lastName} onChange={(e) => changeHandler(e)} variant="outlined" />
          </Grid>

          <Grid item md={4} sm={6} className={"w-100"}>
            <TextField size="small" className={style.formInput} name="contact" type="number" required label="Enter Contact" margin="normal" defaultValue={formData.contact} onChange={(e) => changeHandler(e)} variant="outlined" />
          </Grid>

          <Grid item md={4} sm={6} className={"w-100"}>
              <TextField size="small" className={style.formInput} name="email" type="text" required label="Enter Last Email" margin="normal" defaultValue={formData.email} onChange={(e) => changeHandler(e)} variant="outlined" />
            </Grid>

          <Grid item md={4} sm={6} className={"w-100 pt-4"}>
            <SelectJS options={options} defValue={{value: formData.country, label:formData.country }} placeholder={"Select Country*"} selectedOption={selectedOptionOutput} />
          </Grid>

          <Grid item md={4} sm={6} className={"w-100"}>
            <TextField size="small" className={style.formInput} name="company" required type="text" label="Company Name" margin="normal" defaultValue={formData.company} onChange={(e) => changeHandler(e)} variant="outlined" />
          </Grid>

          <Grid item md={4} sm={6} className={"w-100"}>
            <TextField size="small" className={style.formInput} name="catalogLink" required type="text" label="Catalog Link" margin="normal" defaultValue={formData.catalogLink} onChange={(e) => changeHandler(e)} variant="outlined" />
          </Grid>
          <Grid item md={4} sm={6} className={"w-100"}>
            <TextField size="small" className={style.formInput} name="secBusiness" type="text" label="Secondary Business" margin="normal" defaultValue={formData.secBusiness} onChange={(e) => changeHandler(e)} variant="outlined" />
          </Grid>
          <Grid item md={4} sm={6} className={"w-100"}>
            <TextField size="small" className={style.formInput} name="companyCEO" required type="text" label="CEO Name" margin="normal" defaultValue={formData.companyCEO} onChange={(e) => changeHandler(e)} variant="outlined" />
          </Grid>

          <Grid item md={4} sm={6} className={"w-100"}>
            <TextField size="small" className={style.formInput} name="yearEstb" required type="text" label="Establishment Year" margin="normal" defaultValue={formData.yearEstb} onChange={(e) => changeHandler(e)} variant="outlined" />
          </Grid>
          <Grid item md={4} sm={6} className={"w-100"}>
            <TextField size="small" className={style.formInput} name="website" type="text" label="Website Link" margin="normal" defaultValue={formData.website} onChange={(e) => changeHandler(e)} variant="outlined" />
          </Grid>

          <Grid item md={4} sm={6} className={"w-100"}>
            <TextField size="small" className={style.formInput} name="gstn" type="text" label="GST No." margin="normal" defaultValue={formData.gstn} onChange={(e) => changeHandler(e)} variant="outlined" />
          </Grid>
          
          <Grid item md={2} sm={6} className={"w-100"}>
            <TextField size="small" className={style.formInput} name="joinYear" type="number" label="Join Year" margin="normal" defaultValue={formData.joinYear} onChange={(e) => changeHandler(e)} variant="outlined" />
          </Grid>
          
          <Grid item md={2} sm={6} className={"w-100"}>
            <TextField size="small" className={style.formInput} name="leadsCredits" type="number" label="Leads Credits" margin="normal" value={formData.leadsCredits} onChange={(e) => changeHandler(e)} variant="outlined" />
          </Grid>

          <Grid item md={4} sm={6} className={"w-100 "}>
            <FormControl fullWidth size="small" className="my-3">
              <InputLabel id="demo-simple-select-label">Membership Plan</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.membershipPlan}
                label="Membership Plan"
                required
                onChange={(e) => selectPlan(e)}
              >
                <MenuItem value={0}>Guest</MenuItem>
                <MenuItem value={1}>Economy</MenuItem>
                <MenuItem value={2}>Gold</MenuItem>
                <MenuItem value={3}>Platinum</MenuItem>
                <MenuItem value={4}>Platinum Pro</MenuItem>
                <MenuItem value={5}>Global</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item md={2} sm={3} className={"w-100 "}>
            <FormControl fullWidth size="small" className="my-3">
              <InputLabel id="demo-simple-select-label">Home Brand</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.homeBrand}
                label="homeBrand"
                name="homeBrand"
                required
                onChange={(e) => setShowfunc(e)}
              >
                <MenuItem value={false}>Hide</MenuItem>
                <MenuItem value={true}>Show</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item md={2} sm={3} className={"w-100 "}>
            <FormControl fullWidth size="small" className="my-3">
              <InputLabel id="demo-simple-select-label">Show Contact</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.showContact}
                label="showContact"
                name="showContact"
                required
                onChange={(e) => setShowfunc(e)}
              >
                <MenuItem value={false}>Hide</MenuItem>
                <MenuItem value={true}>Show</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={2} sm={3} className={"w-100 "}>
            <FormControl fullWidth size="small" className="my-3">
              <InputLabel id="demo-simple-select-label">Verified</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.isVerified}
                label="homeBrand"
                required
                onChange={(e) =>{ setIsVerified(e)}}
              >
                <MenuItem value={false}>Unverify</MenuItem>
                <MenuItem value={true}>Verify</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={4} sm={6} className={"w-100 "}>
            <LocalizationProvider className="m-0" dateAdapter={AdapterMoment}>
              <DatePicker
                label="Activation Date"
                className={style.datePicker}
                slotProps={{ textField: { variant: 'outlined' } }}
                format="DD-MM-YYYY"
                value={moment(formData.activationDate)}
                onChange={(newValue) => {
                  setFormData({ ...formData, activationDate: moment(newValue._d).format() });
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item sm={8} className={"w-100 pt-4"}>
            <CreatableSelect
              defaultValue={formData.dealsIn.map((el) => {
                return { value: el, label: el }
              })}
              isMulti
              onChange={(e) => {
                onSelecteChange(e);
              }}
              theme={customThemeFn}
              placeholder={<div>Dealing Products...*</div>}
              // options={subCategories}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </Grid>
          <Grid item md={4} sm={6} className={"w-100"}>
            <TextField size="small" className={style.formInput} name="location" type="text" required label="Location" margin="normal" defaultValue={formData.location} onChange={(e) => changeHandler(e)} variant="outlined" />
          </Grid>

          <Grid item sm={12} className={"w-100"}>
          <FormGroup>
              <Label className={style.formLabel} for="aboutCompany">
                About Company{" *"}
              </Label>
              <Input
                bsSize="sm"
                id="aboutCompany"
                value={formData.aboutCompany}
                style={{ minHeight: "70px" }}
                onChange={(e) => changeHandler(e)}
                name="aboutCompany"
                type="textarea"
              />
            </FormGroup>
          </Grid>
          {/* <Grid item sm={12} className={"w-100"}>
            <TextField id="outlined-multiline-flexible" className={style.formInput} maxRows={4} required name="aboutCompany" defaultValue={formData.aboutCompany} onChange={(e) => changeHandler(e)} label="About Company" multiline />
          </Grid> */}

          <Grid item sm={12} className={"w-100 pt-4"}>
            <TextField id="outlined-multiline-flexible" className={style.formInput} maxRows={4} required name="companyAddress" defaultValue={formData.companyAddress} onChange={(e) => changeHandler(e)} label="Company Address" multiline />
          </Grid>

          <Grid item sm={3} className={"w-100"}>
            <TextField size="small" className={style.formInput} name="facebook" type="text" label="Facebook Link" margin="normal" defaultValue={formData.facebook} onChange={(e) => changeHandler(e)} variant="outlined" />
          </Grid>
          <Grid item sm={3} className={"w-100"}>
            <TextField size="small" className={style.formInput} name="linkedIn" type="text" label="LinkedIn Link" margin="normal" defaultValue={formData.linkedIn} onChange={(e) => changeHandler(e)} variant="outlined" />
          </Grid>

          <Grid item sm={3} className={"w-100"}>
            <TextField size="small" className={style.formInput} name="twitter" type="text" label="Twitter Link" margin="normal" defaultValue={formData.twitter} onChange={(e) => changeHandler(e)} variant="outlined" />
          </Grid>
          <Grid item sm={3} className={"w-100"}>
            <TextField size="small" className={style.formInput} name="instagram" type="text" label="Instagram Link" margin="normal" defaultValue={formData.instagram} onChange={(e) => changeHandler(e)} variant="outlined" />
          </Grid>
          <Button onClick={(e) => submitHandler(e)} className="btn btn-sm px-3 ml-2">
            Update
          </Button>
        </Grid>
      </div>
    </div>
  );
}

export default UpdateCompany;
