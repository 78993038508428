
const ISSERVER = typeof window === "undefined";

export const setLocalStorage = (key, value) => {
    if (!ISSERVER) {
        localStorage.setItem(key, JSON.stringify(value))
    }
}

export const getLocalStorage = (key) => {
    if (!ISSERVER) {
        return JSON.parse(localStorage.getItem(key))
    }
}

export const removeLocalStorage = (key) => {
    if (!ISSERVER) {
        localStorage.removeItem(key)
    }
}
export const clearLocalStorage = () => {
    if (!ISSERVER) {
        localStorage.clear()
    }
}