import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

export default function Faq() {

    const faqs = [
        { Q: "What is World Business Mart?", A: "World Business Mart is a leading B2B marketplace in India, connecting businesses from various industries and facilitating trade between buyers and sellers." },
        { Q: "How does World Business Mart work?", A: "World Business Mart operates as an online platform where businesses can create profiles, showcase their products or services, and connect with potential buyers or suppliers. It provides a digital marketplace for businesses to explore new opportunities and expand their reach." },
        { Q: "Is registration on World Business Mart free?", A: "Yes, registration on World Business Mart is absolutely free. Businesses can create an account, build their profile, and start leveraging the platform's features to connect with other businesses." },
        { Q: "Can I sell products and services on World Business Mart?", A: "Absolutely! World Business Mart offers a platform for businesses to promote and sell their products or services. You can create product listings, provide detailed descriptions, and engage with potential buyers." },
        { Q: "How can I find suitable buyers or suppliers on World Business Mart?", A: "World Business Mart provides robust search and filtering options to help you find relevant buyers or suppliers. You can search by industry, product category, location, and more to narrow down your search and connect with the right business partners." },
        { Q: "Is my information secure on World Business Mart?", A: "World Business Mart takes data security seriously. The platform employs various measures to protect user information and maintain the confidentiality of transactions. However, it is advisable to exercise caution and follow best practices while engaging with potential partners." },
        { Q: "Can I communicate directly with buyers or suppliers on World Business Mart?", A: "Yes, World Business Mart facilitates direct communication between businesses. Once you find a potential buyer or supplier, you can use the platform's messaging system to initiate conversations and negotiate terms." },
        { Q: "Are there any additional advertising or promotional opportunities on World Business Mart?", A: "Yes, World Business Mart offers various advertising and promotional options to enhance your visibility on the platform. You can explore banner ads, featured listings, or sponsored content to increase your chances of reaching a wider audience." },
        { Q: "Can I track my performance and analytics on World Business Mart?", A: "World Business Mart provides analytics and insights to help you track your performance on the platform. You can monitor views, inquiries, and interactions to measure the effectiveness of your listings and marketing efforts." },
        { Q: "What industries are covered on World Business Mart?", A: "World Business Mart caters to a wide range of industries, including manufacturing, agriculture, textiles, electronics, automotive, and more. Businesses" }
    ]
    return (
        <div className="container px-0">
            <div className="page-header breadcrumb-wrap undefined mb-10">
                <div className="container">
                    <div className="breadcrumb"><Link to="/">Home</Link><span></span>Faqs</div>
                </div>
            </div>
            <div className="row flex-row-reverse pb-10">
                <div className="col-lg-9 m-auto">
                    {faqs.map((el, i) => {
                        return <Accordion key={i}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography>{el.Q}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {el.A}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    })}

                </div>
            </div>
        </div>
    )
}
