import React, { useEffect, useState } from "react";
import { FormGroup, FormText, Input, Label } from "reactstrap";
import style from "./style.module.css";
import { api } from "../../components/util/Data";
import { userValidate } from "../../components/util/validation";
import { getCookie } from "../../components/util/Cokkies";
import SelectJS from "../../components/elements/SelectJS";
import { Fab, Grid } from "@mui/material";
import { Button } from "react-bootstrap";
import axios from "axios";
import { getLocalStorage } from "../../components/util/localStore";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useSelector } from "react-redux";

function BannerPage() {
    const currentCompanyUser = useSelector((state) => state.CompanyUser.data);
    const user = getLocalStorage("user")
    const [bannersData, setBannersData] = useState([])
    const [formData, setFormData] = useState({ company: "", image: "" });
    const [imageUrl, setImageUrl] = useState("");
    const [companiesList, setCompaniesList] = useState([]);
    const [uploadFiles, setUploadFiles] = useState([]);
    const [fileSelected, setFileSelected] = useState(false);
    const verify_admin_hr = userValidate(['admin', 'hr'], "token");
    let token = getCookie("token");

    useEffect(() => {
        if (currentCompanyUser._id) {
            setFormData({ ...formData, company: currentCompanyUser._id });
            setBannersData(currentCompanyUser.banners)
        }
        if (verify_admin_hr) {
            axios
                .post(api.url + "/get-all-companies", { id: "id" })
                .then(function (response) {
                    console.log(response.data);
                    const selectCompanies = response.data.companies.map((el) => {
                        return { value: el, label: el.company, type: "company" };
                    });
                    setCompaniesList(selectCompanies);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }, [currentCompanyUser, verify_admin_hr]);

    const getSelected = (select) => {
        setFormData({ ...formData, company: select.value });
    };

    const uploadLogoImg = (e) => {
        const file = e.target.files[0]
        var reader = new FileReader();
        const form = new FormData();
        form.append("company", formData.company);
        if (e.target.files && file) {
            form.append("bannerFile", file, file.name);
            setUploadFiles(form);
            // processFile(file);
            setImageUrl(URL.createObjectURL(file));
            reader.readAsDataURL(file);
            if (file.size > 200000) {
                alert("Size sould less then 200 kb ");
                setFileSelected(false);
            } else {
                reader.onload = function (e) {
                    var image = new Image();
                    image.src = e.target.result;
                    image.onload = function () {
                        var height = this.height;
                        var width = this.width;
                        if (width <= height) {
                            alert("Image's height & width sould be same");
                            setFileSelected(false);
                        } else {
                            setFileSelected(true);
                        }
                    };
                };
            }
        } else {
            setFileSelected(false);
        }
    };

    const submitHandler = async () => {
        if (fileSelected) {
            axios
                .post(api.url + "/upload-banner", uploadFiles)
                .then(function (response) {
                    // console.log(response)
                    if (response.data.success) {
                        alert("Banner Add Successfull")
                        setBannersData(bannersData => [...bannersData, response.data.file]);
                        setFileSelected(false);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            alert("Please fill the required fields");
        }
    };

    const deleteBanner = (img, id) => {
        axios
            .post(api.url + "/delete-banner", { img, id })
            .then(function (response) {
                setBannersData('')
                const filter = bannersData.filter((el) => el !== img)
                setBannersData(filter)
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    return (
        <div>
            <div className="content-header p-0">
                <div className="container-fluid">
                    <h4 className="m-0 text-dark">Add Banner</h4>
                    <hr className="my-2" />
                </div>
            </div>
            <div className="container px-4">
                {/* Add Categories */}
                <div>
                    <Grid container spacing={1} className="form align-items-center">
                        {verify_admin_hr ? (
                            <FormGroup className="col-12">
                                <Label className={style.formLabel}>
                                    Behalf Of
                                    <small>
                                        <sup className="text-danger"> (Required)</sup>
                                    </small>
                                </Label>
                                {companiesList.length ? <SelectJS sm={"sm"} defValue={""} options={companiesList} selectedOption={getSelected} /> : ""}
                            </FormGroup>
                        ) : (
                            ""
                        )}


                        <Grid item md={6} className={"w-100"}>
                            <FormGroup>
                                <Label className={style.formLabel} for="image">
                                    Banner Image 
                                </Label>
                                <Input
                                    bsSize="sm"
                                    className={style.fileInput + " py-2"}
                                    name="image"
                                    accept="image/*"
                                    onChange={(e) => uploadLogoImg(e)}
                                    type="file"
                                />
                                <FormText><strong>Selected Image :  {formData.image} </strong></FormText>
                            </FormGroup>
                        </Grid>
                        <Grid item md={6} className={"w-100"}>
                            <Button onClick={(e) => submitHandler(e)} className="btn px-3 ml-2 pb-10">
                                Add Product
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                <div className="banner-list">
                    <h4>Banners List : </h4>
                    <div className="row g-2">
                        {bannersData.map((el, i) => {
                            return <div key={i} className={"col-md-6 banner-item " + style.bannerItem}>
                                <div className={style.bannerDelete}>
                                    <button className="btn" onClick={(e) => deleteBanner(el, currentCompanyUser._id)}>
                                        <i className="fa-solid fa-trash"></i>
                                    </button>
                                </div>
                                <img key={i} src={api.bannerUrl + el} alt={"banner-" + i} />
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BannerPage;
