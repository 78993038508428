import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { api } from '../components/util/Data';
import { Link, useParams } from 'react-router-dom';
import { Pagination } from '@mui/material';

export default function CompanyPage() {
    const params = useParams()
    const [companiesList, setLeadsList] = useState({ totalCompanies: 0, companies: [] })
    // const [loading, setLoading] = useState(true);
    const [listPage, setListPage] = useState(1);
    const planArray = [{ plan: "Guest", leads: 0 }, { plan: "Economy", leads: 10 }, { plan: "Gold", leads: 30 }, { plan: "Platinum", leads: 60 }, { plan: "Platinum Pro", leads: 80 }, { plan: "Global", leads: 100 }]

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth", });
        axios
            .post(api.url + "/get-companies-list", params.country ? { page: listPage, country: params.country, key: "" } : { page: listPage, country: "", key: (params.key === "all" ? "" : params.key)})
            .then(function (response) {
                // console.log(response);
                setLeadsList({ totalCompanies: response.data.total, companies: response.data.companies })
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [listPage, params])

    const handlePageChange = (event, value) => {
        // setLoading(true)
        setListPage(value);
    };

    return (
        <div className="container px-0">
            <div className="page-header breadcrumb-wrap undefined mb-10"><div className="container"><div className="breadcrumb"><Link to="/">Home</Link><span></span> Company List</div></div></div>

            <div className="row flex-row-reverse pb-10">
                <div className="col-lg-9  m-auto ">
                    {companiesList.companies.length ? companiesList.companies.map((el, i) => {
                        return <div key={i} className="vendor-wrap mb-20  style-2">
                            <div className="product-badges product-badges-position product-badges-mrg">
                                <span className="hot bg-info">{planArray[el.membershipPlan].plan} Member</span>
                            </div>

                            <div className="vendor-img-action-wrap">
                                <div className="vendor-img">
                                    <a href={"/company/" + el.catalogLink} target='_'>
                                        <img className="default-img" src={api.companyUrl + el.companyLogo} alt="" />
                                    </a>
                                </div>
                            </div>
                            <div className="vendor-content-wrap w-100">
                                <div>
                                    <h4 className="mb-5"><a href={"/company/" + el.catalogLink} target='_'>{el.company}</a> &nbsp; {el.isVerified ? <img width={20} height={20} src="/verified.png" alt="verified" /> : ""}</h4>
                                    <div className="product-rate-cover">
                                        <span className="font-small ml-5 mr-5 bg-main-primary text-white px-3 py-1">{el.businessType.join(", ")}</span> {el.joinYear ? <strong className='text-dark'><i className="fa-regular fa-handshake"></i> {el.joinYear} Years</strong> : ""}
                                    </div>
                                    <div className="vendor-info d-flex justify-content-between flex-wrap align-items-end mt-15">
                                        <ul className="contact-infor">
                                            <li><i style={{ width: "30px", textAlign: "center" }} className="fa-solid fa-circle-info"></i><strong>About : </strong> <span>{el.aboutCompany.slice(0, 200) + '...'}<a href={"/company/" + el.catalogLink} target='_'>More</a></span></li>

                                            <li><i style={{ width: "30px", textAlign: "center" }} className="fa-solid fa-location-dot"></i><strong>Address : </strong> <span>{el.companyAddress}</span></li>

                                            <li><i style={{ width: "30px", textAlign: "center" }} class="fas fa-thumbs-up"></i> <strong>Product : </strong> <span style={{ "textTransform": "capitalize" }}>{el.dealsIn.join(", ")}</span></li>

                                            {el.website ? <li><i style={{ width: "30px", textAlign: "center" }} class="fas fa-link"></i> <strong>Site : </strong>  <span> <a className="text-main-secondary" href={el.website} target='_blank' rel="noreferrer"> {el.website} </a> </span> </li> : ""}
                                        </ul>
                                        <div className="venter-button">
                                            <Link to={"/company/" + el.catalogLink} className="btn btn-xs bg-main-secondary m-2">Show Details <i className="fi-rs-arrow-small-right"></i></Link>
                                            <Link to={"/requirement"} className="btn btn-xs my-2">Send Enquiry <i className="fi-rs-arrow-small-right"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }) : ""}

                    <div style={{ display: "flex" }}>
                        <h5>Total : {companiesList.totalCompanies}</h5>
                        <Pagination count={Math.ceil(companiesList.totalCompanies / 20)} color="primary" onChange={handlePageChange} />
                    </div>
                </div>
            </div>
        </div>
    )
}
