import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { api } from '../components/util/Data';

export default function LeadDetail() {
  const [leadsList, setLeadsList] = useState([])
  const [loading, setLoading] = useState(true)
  const location = useLocation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    console.log(location.state)
    setLoading(true)
    axios
      .post(api.url + "/get-related-leads", { id: location.state.leadId })
      .then(function (response) {
        console.log(response);
        setLeadsList(response.data.leads)
        setLoading(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [location.state.leadId])

  return (
    <div className="container px-0">
      <div className="page-header breadcrumb-wrap undefined mb-10"><div className="container"><div className="breadcrumb"><Link to="/">Home</Link><span></span> Lead Details </div></div></div>

      <div className="row flex-row-reverse pb-10">
        <div className="col-lg-8 m-auto row my-4">
          <div className="cd-bg cd-rd row g-2 py-3 px-4">
            <sub style={{ fontSize: "small" }}>(ID: {location.state.leadId})</sub>
            <h4 className="mb-5">{location.state.leadTitle} </h4>
            <div className="col-md-7">

              <div className="vendor-info mt-1 mb-3">
                <ul className="contact-infor">
                  <li><span className='text-muted'> Message :</span> {location.state.leadMessage}</li>
                  <hr className='my-2' />
                  {location.state.quantity ?  <li className='row m-0'><span className='text-muted col-sm-5'> Order Quantity :</span> <span className='col-sm-6'>: {location.state.quantity}</span></li> :""}
                  { location.state.urgency ? <li className='row m-0'><span className='text-muted col-sm-5'> Urgency :</span> <span className='col-sm-6'>: {location.state.urgency}</span></li> :""}
                  { location.state.purpose ? <li className='row m-0'><span className='text-muted col-sm-5'> Purpose of Purchase :</span> <span className='col-sm-6'>: {location.state.purpose}</span></li> :""}
                  { location.state.frequency ? <li className='row m-0'><span className='text-muted col-sm-5'> Requirement Frequency :</span> <span className='col-sm-6'>: {location.state.frequency}</span></li> :""}
                  { location.state.paymentMode ? <li className='row m-0'><span className='text-muted col-sm-5'> Payment Mode :</span> <span className='col-sm-6'>: {location.state.paymentMode}</span></li> :""}
                </ul>
              </div>
              <Link to={"/login"} className="btn btn-xs bg-main-secondary">Buy now</Link>  <Link to={"/requirement"} className="btn btn-xs bg-main-secondary mx-1">Inquiry Request</Link>

            </div>
            <div className="col-md-5">
              <h6 className='mb-10'><i style={{ width: "20px" }} className="fa-regular fa-building"></i> {location.state.company}</h6>
              <p style={{ fontSize: "small" }}> <i style={{ width: "20px" }} className="fas fa-map-marker-alt"></i> Location : {location.state.location} </p>
              <p style={{ fontSize: "small" }}> <i style={{ width: "20px" }} className="fa-solid fa-globe"></i> Country : {location.state.country} </p>
              <p style={{ fontSize: "small" }} className="text-muted">Date Posted : {moment(location.state.createdAt).format("DD/MM/YYYY")}</p>
            </div>
          </div>
        </div>
        <div className='col-lg-8 m-auto row pr-40'>
          <hr />
          <h5>Related Leads : </h5>
          {loading ? <h5>Loading...</h5> : leadsList.map((el, i) => {
            return <div key={i} className="vendor-wrap my-3 style-2">
              <div className="product-badges product-badges-position product-badges-mrg">
                <span className='bg-white'><img style={{ border: "solid 1px gray", borderRadius: "5px", height: "20px" }} src={el.countryFlag} alt={el.country} /></span>
              </div>
              <div className="vendor-content-wrap row align-items-center g-2 w-100">
                <div className="col-md-7">
                  <div className="product-category">
                    <span>ID: {el.leadId}</span>
                  </div>
                  <h4 className="mb-5"><Link to={"/lead-detail/" + el.leadId} state={el}>{el.leadTitle}</Link></h4>
                  <div className="vendor-info d-flex justify-content-between align-items-end mt-1">
                    <ul className="contact-infor">
                      <li>{el.leadMessage.substring(0, 150) + "..."}<Link to={"/lead-detail/" + el.leadId} state={el}>more</Link></li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-5">
                  <p style={{ fontSize: "small" }}> <i className="fa-solid fa-globe"></i> Country : {el.country} </p>
                  <p style={{ fontSize: "small" }} className="text-muted">Date Posted : {moment(el.createdAt).format("DD/MM/YYYY")}</p>
                  <Link to={"/login"} className="btn btn-xs bg-main-secondary mx-1">Buy now</Link>
                  <Link to={"/requirement"} className="btn btn-xs bg-main-secondary mx-1">Inquiry Request</Link>
                </div>
              </div>
            </div>
          })}
        </div>
      </div>
    </div>
  )
}
