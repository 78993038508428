import React, { useEffect, useState } from "react";
import { Fab, Tooltip } from "@mui/material";
import style from "./style.module.css";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

export default function GoToTop() {
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, [])


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      {showButton ? (
        <div className="scroll-to-top-show">
          <Tooltip title="Go to Top" placement="top">
            <Fab className={style.floatBtn} size="small" onClick={scrollToTop}>
            <KeyboardDoubleArrowUpIcon/>
            </Fab>
          </Tooltip>
        </div>
      ) : (
        <div className="scroll-to-top-hide">
          <Tooltip title="Go to Top">
            <Fab className={style.floatBtn} size="small" onClick={scrollToTop}>
            <KeyboardDoubleArrowUpIcon/>
            </Fab>
          </Tooltip>
        </div>
      )}
    </div>
  );
}
