import { useEffect } from "react";
import { Link } from "react-router-dom";

function TermsConditions() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [])
    return (
        <>
            <div className="page-content pt-50">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 col-lg-12 m-auto">
                            <div className="row">
                                <div className="col-lg-9 m-auto">
                                    <div className="single-page pr-30 mb-lg-0 mb-sm-5">
                                        <div className="single-header style-2">
                                            <h2>Terms of Service</h2>
                                            <p className="text-muted">Terms & Conditions</p>
                                        </div>
                                        <div className="single-content mb-50">
                                            <h5>
                                                Please read these Terms and Conditions carefully before using the World Business Mart website <Link to={"/"}> www.worldbusinessmart.com</Link> or engaging in any transactions on the platform.
                                            </h5>
                                            <p>
                                            <h4>Acceptance of Terms:</h4>
                                                 By accessing or using the Website, you acknowledge and agree to be bound by these Terms and Conditions, which constitute a legally binding agreement between you and World Business Mart. If you do not agree to these Terms and Conditions, you may not use the Website.
                                            </p>
                                            <p>
                                            <h4>Registration:</h4>
                                                 In order to use certain features of the Website, you may need to register for an account. You agree to provide accurate and complete information during the registration process and to keep your account information updated. You are solely responsible for maintaining the confidentiality of your account and password, and for all activities that occur under your account. You agree to notify World Business Mart immediately of any unauthorized use of your account or any other breach of security.
                                            </p>
                                            <p>
                                            <h4>B2B Marketplace:</h4>
                                                 World Business Mart is a B2B marketplace that facilitates transactions between manufacturers, exporters, and suppliers of bulk products. While World Business Mart strives to verify the authenticity of its users, it does not guarantee the accuracy, reliability, or legality of the products, services, or information listed on the platform. Users are solely responsible for conducting their own due diligence and entering into transactions at their own risk.
                                            </p>
                                            <p>
                                            <h4>Listing and Trading:</h4>
                                             Users may list and promote their products on the Website, and engage in trading activities with other users. Users agree to comply with all applicable laws, regulations, and industry standards, and to be solely responsible for the accuracy and legality of their listings and transactions. World Business Mart reserves the right to remove any listings or terminate any user accounts that violate these Terms and Conditions or are deemed inappropriate or fraudulent.
                                            </p>
                                            <p>
                                            <h4>Privacy:</h4>
                                                 World Business Mart respects your privacy and handles your personal information in accordance with its Privacy Policy. By using the Website, you consent to the collection, use, and disclosure of your personal information as described in the Privacy Policy.
                                            </p>
                                            <p>
                                            <h4>Intellectual Property:</h4>
                                                 The content and materials on the Website, including but not limited to text, graphics, logos, trademarks, images, videos, and software, are owned by or licensed to World Business Mart and are protected by intellectual property laws. Users are prohibited from copying, modifying, distributing, or otherwise using any content from the Website without the prior written consent of World Business Mart.
                                            </p>
                                            <p>
                                                Disclaimer of Warranties: World Business Mart provides the Website on an "as is" and "as available" basis, without any warranties or representations of any kind, express or implied. World Business Mart does not warrant that the Website will be error-free, uninterrupted, or free from viruses or other harmful components. Users use the Website at their own risk.
                                            </p>
                                            <p>
                                            <h4>Limitation of Liability:</h4>
                                                To the maximum extent permitted by law, World Business Mart and its affiliates, officers, directors, employees, agents, and partners shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in connection with the use of the Website or the transactions conducted on the platform, even if World Business Mart has been advised of the possibility of such damages.
                                            </p>
                                            <p>
                                            <h4>Indemnification:</h4>
                                                Users agree to indemnify, defend, and hold harmless World Business Mart and its affiliates, officers, directors, employees, agents, and partners from and against any and all claims, losses, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising out of or in connection with their use of the Website, their listings, or their transactions on the platform.
                                            </p>
                                            <p>
                                                <h4>Termination:</h4>
                                                World Business Mart may terminate or suspend your access to the Website or your account at any time, with or without cause, without prior notice. Upon termination, these Terms and Conditions shall continue to apply to any
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TermsConditions;
