import { useEffect, useState } from "react";
import { formValidation } from '../components/util/validation';
import axios from 'axios';
import { api } from '../components/util/Data';

function Contact() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [])

    const [formData, setFormData] = useState({message : "", name:"", contact:"", email:""})

    const changeHandler = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const submitHandler = async (e) => {
        if (formValidation([formData.message, formData.name, formData.contact, formData.email])) {
          axios
            .post(api.url + "/add-requirement", formData)
            .then(function (response) {
              if (response.data.success) {
                alert("We will contact you quickly");
                setFormData({message : "", name:"", contact:"", email:""})
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          alert("All fields are required");
        }
      };

    return (
        <>
            <div className="page-content pt-50">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 col-lg-12 m-auto">
                            <section className="mb-50">
                                <div className="row mb-60">
                                    <div className="col-md-6">
                                        <h4 className="mb-15 text-brand">Office</h4>
                                        RZH-20, Raghu Nager, Pankha Road, <br /> Dabri, New Delhi 110045, India<br />
                                    </div>
                                    <div className="col-md-6">
                                        <h4 className="mb-15 text-brand">Contact</h4>
                                        <abbr title="Phone">Phone : </abbr> +91 - 8810200550<br />
                                        <abbr title="Email">Email : </abbr>sales@webtradesolution.com
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-8">
                                        <div className="contact-from-area padding-20-row-col">
                                            <h5 className="text-brand mb-10">Contact form</h5>
                                            <h2 className="mb-10">Drop Us a Line</h2>
                                            <p className="text-muted mb-30 font-sm">Your email address will not be published. Required fields are marked *</p>
                                            <form className="contact-form-style mt-30" id="contact-form" action="#" method="post">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="input-style mb-20">
                                                            <input className="py-4" onChange={(e) => changeHandler(e)} name="name" placeholder="First Name" type="text" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="input-style mb-20">
                                                            <input className="py-4" name="email" onChange={(e) => changeHandler(e)} placeholder="Your Email" type="email" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="input-style mb-20">
                                                            <input className="py-4" name="contact" onChange={(e) => changeHandler(e)} placeholder="Your Phone" type="tel" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="input-style mb-20">
                                                            <input className="py-4" name="subject" onChange={(e) => changeHandler(e)}placeholder="Subject" type="text" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="textarea-style mb-30">
                                                            <textarea name="message" onChange={(e) => changeHandler(e)}placeholder="Message"></textarea>
                                                        </div>
                                                        <button className="submit submit-auto-width" type="submit">Send message</button>
                                                    </div>
                                                </div>
                                            </form>
                                            <p className="form-messege"></p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 pl-50 d-lg-block d-none">
                                        <img className="border-radius-15 mt-50" src="assets/imgs/page/contact-2.png" alt="" />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <section className="container mb-50 d-none d-md-block">
                    <div className="border-radius-15 overflow-hidden">
                        <div id="map-panes" className="leaflet-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.624062944179!2d77.09179971244197!3d28.611052767657064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1b4d50271865%3A0x166e1abbc6f98592!2sRaghu%20Nagar%2C%20Dabri%2C%20New%20Delhi%2C%20Delhi!5e0!3m2!1sen!2sin!4v1681674799288!5m2!1sen!2sin" width="100%" height="450" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Contact;
