import React, { useMemo, useState } from "react";
import style from "./style.module.css";
import CreatableSelect from 'react-select/creatable';
import { getCookie } from "../../components/util/Cokkies";
import { formValidation } from "../../components/util/validation";
import { api } from "../../components/util/Data";
import SelectJS from "../../components/elements/SelectJS";
import axios from "axios";
import countriesArray from "../../components/elements/country";
import { Grid, InputAdornment, TextField } from "@mui/material";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

function UpdateLead() {
  const location = useLocation()
  const navigate = useNavigate()
  const options = useMemo(() => countriesArray.map((el) => { return { label: el.name, value: el.name, code: el.callingCodes[0], flag: el.flags.svg } }), [])
  const [formData, setFormData] = useState(location.state);
  let token = getCookie("token");

  const selectedOptionOutput = value => {
    setFormData({ ...formData, country: value.value, countryFlag: value.flag, callCode: value.code });
  }

  const customThemeFn = (theme) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 30,
      baseUnit: 2,
    },
  });

  const onSelecteChange = (e) => {
    setFormData({ ...formData, tagKeywords: e.map((el) => el.value) });
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitHandler = async (e) => {
    if (formValidation([formData.leadMessage, formData.contact, formData.leadTitle, formData.leadingName, formData.tagKeywords.length > 0, formData.country])) {
      axios
        .post(api.url + "/update-lead", formData, { headers: { authorization: "Bearer " + token } })
        .then(function (response) {
          if (response.data.success) {
            alert("Update Successfull");
            navigate("/admin/leads-list")
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      alert("Please fill the required fields");
    }
  };

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Update Lead</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        {/* Add Categories */}
        <div>
          <Grid container spacing={1} className="mt-4">

            <Grid item md={6} className={"w-100"}>
              <TextField size="small" className={style.formInput} name="company" type="text" required label="Behalf Of" margin="normal" defaultValue={formData.company} onChange={(e) => changeHandler(e)} variant="outlined" />
            </Grid>

            <Grid item md={6} className={"w-100"}>
              <TextField size="small" className={style.formInput} name="leadTitle" type="text" required label="Lead Title" margin="normal" defaultValue={formData.leadTitle} onChange={(e) => changeHandler(e)} variant="outlined" />
            </Grid>

            <Grid item md={6} className="w-100 pt-4">
              <SelectJS sm={"sm"} className={style.formInput} options={options} placeholder={"Country...*"} defValue={{value : formData.country, label :formData.country }} selectedOption={selectedOptionOutput} />
            </Grid>

            <Grid item md={6} className={"w-100"}>
              <TextField size="small" className={style.formInput} name="location" type="text" required label="Location" margin="normal" defaultValue={formData.location} onChange={(e) => changeHandler(e)} variant="outlined" />
            </Grid>
            
            <Grid item md={6} className={"w-100"}>
              <TextField size="small" className={style.formInput} name="leadingName" type="text" required label="Contact Person" margin="normal" defaultValue={formData.leadingName} onChange={(e) => changeHandler(e)} variant="outlined" />
            </Grid>

            <Grid item md={6} className={"w-100 pt-4"}>
              <TextField
                label="Contact No..."
                required
                type="number"
                name="contact"
                className={style.formInput}
                defaultValue={formData.contact}
                onChange={(e) => changeHandler(e)}
                size="small"
                InputProps={{
                  startAdornment: <InputAdornment position="start">+{formData.callCode}</InputAdornment>,
                }}
              />
            </Grid>

            <Grid item md={6} className={"w-100"}>
              <TextField size="small" className={style.formInput} name="email" type="text" label="Email" margin="normal" defaultValue={formData.email} onChange={(e) => changeHandler(e)} variant="outlined" />
            </Grid>

            <Grid item md={6} className={"w-100 pt-4"}>
              <CreatableSelect
                defaultValue={formData.tagKeywords.map((el) => {
                  return { value: el, label: el }
                })}
                placeholder={"Tag Keywords...*"}
                isMulti
                onChange={(e) => {
                  onSelecteChange(e);
                }}
                theme={customThemeFn}
                name="tagKeywords"
                id="tagKeywords"
                // options={subCategories}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </Grid>

            
            <Grid item md={6} className={"w-100"}>
              <TextField size="small" className={style.formInput} name="quantity" type="text" label="Order Quantity" margin="normal" defaultValue={formData.quantity} onChange={(e) => changeHandler(e)} variant="outlined" />
            </Grid>
            <Grid item md={6} className={"w-100"}>
              <TextField size="small" className={style.formInput} name="urgency" type="text" label="Urgency" margin="normal" defaultValue={formData.urgency} onChange={(e) => changeHandler(e)} variant="outlined" />
            </Grid>
            <Grid item md={6} className={"w-100"}>
              <TextField size="small" className={style.formInput} name="purpose" type="text" label="Purpose of Purchase" margin="normal" defaultValue={formData.purpose} onChange={(e) => changeHandler(e)} variant="outlined" />
            </Grid>
            <Grid item md={6} className={"w-100"}>
              <TextField size="small" className={style.formInput} name="frequency" type="text" label="Requirement Frequency" margin="normal" defaultValue={formData.frequency} onChange={(e) => changeHandler(e)} variant="outlined" />
            </Grid>
            <Grid item md={6} className={"w-100"}>
              <TextField size="small" className={style.formInput} name="paymentMode" type="text" label="Payment Mode" margin="normal" defaultValue={formData.paymentMode} onChange={(e) => changeHandler(e)} variant="outlined" />
            </Grid>

            <Grid item md={12} className={"w-100 pt-4"}>
              <TextField className={style.formInput} maxRows={4} required name="leadMessage" defaultValue={formData.leadMessage} onChange={(e) => changeHandler(e)} label="Lead Message" multiline />
            </Grid>

            <Grid item className={"w-100 pt-4"}>
              <Button onClick={(e) => submitHandler(e)} className="btn btn-sm px-3 ml-2">
                Update Lead
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default UpdateLead;
