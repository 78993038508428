import React, { useEffect } from 'react'
import { Navigate, Route, Routes } from "react-router-dom";
import AdminLayout from '../../components/Layout/AdminLayout';
import Dashboard from './Dashboard';
import CompanyDetails from './CompanyDetails';
import UpdateDetails from './UpdateDetails';
import ProductAdd from './ProductAdd';
import ProductsList from './ProductsList';
import AddCategoryGroup from './AddCategoryGroup';
import AddCategories from './AddCategories';
import AddSubCategories from './AddSubCategories';
import CompanyList from './CompanyList';
import MyLeads from './MyLeads';
import LeadsList from './LeadsList';
import AddLeads from './AddLeads';
import ChangePassword from './ChangePassword';
import UpdateCompany from './UpdateCompany';
import UpdateLead from './UpdateLead';
import { useDispatch } from 'react-redux';
import { mainReduxFunction } from "../../components/util/mainRedux";
import ProductUpdate from './ProductUpdate';
import GalleryPage from './GalleryPage';
import BannerPage from './BannerPage';
import AddTestimonial from './AddTestimonial';
import TestimonialsList from './TestimonialsList';
import RequirementsList from './RequirementsList';
import MainBannerPage from './MainBannerPage';
import AddCareers from './AddCareers';

export default function AdminLayoutRout() {
  const disPatch = useDispatch();
  useEffect(() => {
    mainReduxFunction(disPatch)
  }, [disPatch])
  return (
    <Routes>
      <Route path="/" element={<AdminLayout />}>
      <Route path="/" element={<Navigate replace to="dashboard" />} />
        <Route path="dashboard" element={<Dashboard />}></Route>
        <Route path="company-details" element={<CompanyDetails />}></Route>
        <Route path="update-details" element={<UpdateDetails />}></Route>
        <Route path="update-company" element={<UpdateCompany />}></Route>
        <Route path="company-list" element={<CompanyList />}></Route>
        <Route path="add-product" element={<ProductAdd />}></Route>
        <Route path="products-list" element={<ProductsList />}></Route>
        <Route path="update-product" element={<ProductUpdate />}></Route>
        <Route path="category-group" element={<AddCategoryGroup />}></Route>
        <Route path="categories" element={<AddCategories />}></Route>
        <Route path="sub-categories" element={<AddSubCategories />}></Route>
        <Route path="add-leads" element={<AddLeads />}></Route>
        <Route path="my-leads" element={<MyLeads />}></Route>
        <Route path="leads-list" element={<LeadsList />}></Route>
        <Route path="update-lead/:id" element={<UpdateLead />}></Route>
        <Route path="gallery" element={<GalleryPage />}></Route>
        <Route path="add-careers" element={<AddCareers />}></Route>
        <Route path="banners" element={<BannerPage />}></Route>
        <Route path="main-banners" element={<MainBannerPage />}></Route>
        <Route path="change-password" element={<ChangePassword />}></Route>
        <Route path="change-user-password" element={<ChangePassword />}></Route>
        <Route path="change-admin-password" element={<ChangePassword />}></Route>
        <Route path="add-testimonial" element={<AddTestimonial />}></Route>
        <Route path="testimonials-list" element={<TestimonialsList />}></Route>
        <Route path="requirements" element={<RequirementsList />}></Route>
      </Route>
      {/* <Route path="*" element={<Navigate replace to="404" />} />
      <Route path="/404" element={<Notfound direction={'admin'} />}></Route> */}
    </Routes>
  )
}
