
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Search = () => {
    const location = useLocation()
    const [searchTerm, setSearchTerm] = useState("");
    const [searchFor, setSearchFor] = useState("product");
    const navigate = useNavigate()

    const makeLink = (str) => {
        return str.replace(/^\s+|\s+$/g, '').replaceAll(" ", "_").toLowerCase();
    }
    
    useEffect(() => {
      if(location.pathname.includes("lead")){
        setSearchFor("buy_lead")
    }else if(location.pathname.includes("company")){
        setSearchFor("company")
    }else{
        setSearchFor("product")
    }
    }, [location])
    

    const handleInput = (e) => {
        e.preventDefault();
        if(searchTerm.length >2){
            navigate({
                pathname: "/search_" + searchFor + "/" + makeLink(searchTerm),
            });
            setSearchTerm("");
        } else{
            alert("Text shoud be more than 3 for search !!!")
        }
    };
    const changeHandel = (e) => {
        setSearchFor(e.target.value)
    }

    return (
        <>
            <form onSubmit={handleInput}>
                <select className="form-select" value={searchFor} onChange={(e) => changeHandel(e)}>
                    <option value={"product"} >Product</option>
                    <option value={"company"} >Company</option>
                    <option value={"buy_lead"} >Buy Lead</option>
                </select>
                <input
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    type="text"
                    placeholder="Search..."
                />
                <button type="submit">Search</button>
            </form>
        </>
    );
};

export default Search;
