import React, { useEffect, useState } from "react";
import moment from "moment";
import { getCookie } from "../../components/util/Cokkies";
import { api } from "../../components/util/Data";
import { getLocalStorage } from "../../components/util/localStore";
import axios from "axios";
import { Button, TextField } from "@mui/material";
import DataTableComponent from "../../components/elements/DataTableComponent";

function MyLeads() {
  const user = getLocalStorage("user")
  const [currentUser, setCurrentUser] = useState({});
  const [pending, setPending] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [filterValue, setFilterValue] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [listData, setListData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  let token = getCookie("token");
  const [tableOptions, setTableOptions] = useState({ perPage: 10, pageNo: 1, sortDirection: "desc", sortField: "createdAt" });

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      sortable: true,
      width: "65px",
    },
    {
      name: <strong>Unique ID</strong>,
      selector: (row) => row.lead.leadId,
      sortable: true,
      width: "150px",
    },
    {
      name: <strong>Title</strong>,
      selector: (row) => row.lead.leadTitle,
      sortable: true,
      width: "250px",
    },
    {
      name: <strong>Contact Person</strong>,
      selector: (row) => row.lead.leadingName,
      sortable: true,
      width: "200px",
    },
    {
      name: <strong>ADD ON</strong>,
      selector: (row) => moment(row.lead.createdAt).format("DD/MM/YYYY"),
      sortable: true,
      width: "120px",
    },
    {
      name: <strong>Purchased On</strong>,
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      sortable: true,
      width: "120px",
    },
  ];

  useEffect(() => {
    axios
    .post(api.url + "/get-current-company", { id: user.id, userRole : user.role },  { headers: { authorization: "Bearer " + token } })
    .then(function (response) {
      setCurrentUser(response.data.data)
      getMyLeads(response.data.data._id)
    })
    .catch(function (error) {
      console.log(error);
    });
  }, []);

  const getMyLeads =(id)=>{
    axios
    .post(api.url + "/get-purchasedLeads-for-admin", { userId: id }, { headers: { authorization: "Bearer " + token } })
    .then(function (response) {
      // console.log(response.data);
      response.data.leads.forEach((item, index) => {
        item.serial = index + 1;
      });
      setListData(response.data.leads);
      setFilterItems(response.data.leads);
      setTotalRows(response.data.total);
      setIsSearch(false)
      setPending(false);
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const expComponent = (data) => {
    return (
      <div style={{ width: "100%", fontWeight: "bolder" }}>
        <ul>
          <li>
            <u>Title</u> : {data.lead.leadTitle}
          </li>
          <li>
            <u>Message</u> : {data.lead.leadMessage}
          </li>
          <li>
            <u>Company Name</u> : {data.lead.company}
          </li>
          <li>
            <u>Contact No.</u> : {data.lead.contact}
          </li>
          <li>
            <u>Email</u> :{data.lead.email}
          </li>
          <li>
            <u>Country</u> : {data.lead.country}
          </li>
        </ul>
      </div>
    );
  };

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">My Leads</h4>
          <hr className="my-2" />
        </div>
      </div>

      <DataTableComponent
        pageChange={(e) => { setTableOptions({ ...tableOptions, pageNo: e }) }}
        perRowsChange={(e) => setTableOptions({ ...tableOptions, perPage: e.perPage, pageNo: 1 })}
        setHandleSort={(e) => setTableOptions({ ...tableOptions, sortDirection: e.sortDirection, sortField: e.sortField })}
        expandedComponent={e => expComponent(e)}
        expandable={true}
        inputFilterValue={filterValue}
        setSearchTrue={(e) => {setIsSearch(true)}}
        inputFilter={(e) => setFilterValue(e)}
        dataLoad={pending}
        columnsData={columns}
        totalRowsData={totalRows}
        data={filterItems} />
    </div>
  );
}

export default MyLeads;
