import IntroPopup from "../components/elements/IntroPopup";
import CategorySlider2 from '../components/sliders/Category2';
import Intro2 from "../components/sliders/Intro2";
import Marquee from "react-marquee-master";
import CategoryCard from '../components/elements/CategoryCard'
import CategoryProduct from '../components/ecommerce/Filter/CategoryProduct'
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { api } from "../components/util/Data";
import countriesArray from '../components/elements/country'
import InquiryForm from "../components/elements/InquiryForm";
import Testimonials from "../components/elements/Testimonials";
import CountryItem from "../components/elements/CountryItem";
import style from './style.module.css'
import Slider from "react-slick";
import SmallBanners from "../components/elements/SmallBanners";

const categoryCards = ["Apparel, Clothing ,Garments & Fashion Accessories", "Food Products & Beverage", "Agriculture & Farming", "Realestate, Building & Construction", "Mineral & Metals", "Health & Beauty Products", "Chemicals", "Hospital & Diagnostics", "Gift & Crafts", "Home Furnishings", "Industrial Machinery"]

const countries = ["United States of America", "Canada", "United Arab Emirates", "Saudi Arabia", "China", "Australia"]

const menu = [{ title: "Health & Beauty", link: "Health & Beauty Products" }, { title: "Apparel & Fashion", link: "Apparel, Clothing ,Garments & Fashion Accessories" }, { title: "Chemicals", link: "Chemicals" }, { title: "Machinery", link: "Industrial Machinery" }, { title: "Construction & Real Estate", link: "Realestate, Building & Construction" }, { title: "Electronics & Electrical Supplies", link: "Electronics & Electrical" }, { title: "Hospital & Medical Supplies", link: "Hospital & Diagnostics" }, { title: "Gift & Crafts", link: "Gift & Crafts" }, { title: "Packaging & Paper", link: "Packaging & Paper Material" }, { title: "Agriculture", link: "Agriculture & Farming" }]

function Home() {
    const [categoriesGroup, setCategoriesGroup] = useState([])
    const [categories, setCategories] = useState([])
    const [subCategories, setsubCategories] = useState([])
    const [categoryData, setCategoryData] = useState([])
    const [buyLeads, setBuyLeads] = useState({ latestLeads: [], outLeads: [] })
    const [countriesList, setCountriesList] = useState([])
    const [testimonials, setTestimonials] = useState([])
    const [smallBanners, setSmallBanners] = useState([])
    const [secondBanners, setSecondBanners] = useState([])
    const [mainBanners, setMainBanners] = useState([])
    const customSlider = useRef();
    const [premiumBrands, setPremiumBrands] = useState([])

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth"});
        setCountriesList(countriesArray.filter(({ name: name1 }) => countries.some((e) => e === name1)))
        axios
            .post(api.url + "/get-data-for-home", { type: "sub-category" })
            .then(function (response) {
                // console.log(response);
                setCategoriesGroup(response.data.categoriesgroup)
                setCategories(response.data.categories)
                setsubCategories(response.data.subCategories)
                setTestimonials(response.data.testimonials)
                setPremiumBrands(response.data.premiumBrands)
                setMainBanners(response.data.banners.filter((el)=> el.type === "main"))
                setSmallBanners(response.data.banners.filter((el)=> el.type === "ads"))
                setSecondBanners(response.data.banners.filter((el)=> el.type === "ads-2"))
                setBuyLeads({ latestLeads: response.data.leads, outLeads: response.data.outLeads })

                const newList = response.data.categories.map((el)=>{
                    const listing = response.data.subCategories.filter( ele=> ele.category === el._id)
                    return {...el, items : listing}
                })
                console.log(newList);
                setCategoryData(newList)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    const preBrand = {
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 20000,
        slidesToScroll: 1,
        cssEase: "linear",
        arrows: false,
        pauseOnHover: false,
        rows: 1,
        slidesToShow: 5,
        dots: true,
        responsive: [
            {
                breakpoint: 550,
                settings: {
                    slidesPerRow: 3,
                },
            },
            {
                breakpoint: 400,
                settings: {
                    slidesPerRow: 2,
                },
            },
        ],
    };

    return (
        <>
            <IntroPopup />
            <div>
                <div className="container mb-10">
                    <div className="row flex-row-reverse">
                        <div className="col-lg-9">
                            <section className="home-slider position-relative">
                                <div className="home-slide-cover mt-30">
                                    {mainBanners.length ? <Intro2 banners={mainBanners} /> :""}
                                </div>
                            </section>
                        </div>
                        <div className="col-lg-3 primary-sidebar sticky-sidebar pt-30">
                            <div className="sidebar-widget widget-category-2 mb-30 bg-white">
                                <h5 className="section-title style-1 mb-30">
                                    Category
                                </h5>
                                <ul>
                                    {
                                        menu.map((e, i) => categoriesGroup.map((ele) => {
                                            if (i < 10) {
                                                return ele.categoryGroup === e.link ? <CategoryProduct key={i} title={e.title} groupData={ele} catData={categories.filter((el) => el.categoryGroup === ele._id)} subData={subCategories} /> : ""
                                            }
                                        }))
                                    }
                                    <li><Link to="/product-directory"><strong>All Categories</strong></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="popular-categories section-padding">
                    <div className="container">
                        <div className="section-title">
                            <div className="title">
                                <h3>Trending Categories</h3>
                                <Link to="/product-directory" className="show-all">
                                    All Categories
                                    <i className="fi-rs-angle-right"></i>
                                </Link>
                            </div>
                        </div>
                        <div className="carausel-8-columns-cover position-relative">
                            <div className="carausel-8-columns" id="carausel-8-columns" >
                                {subCategories.length ? <CategorySlider2 data={subCategories} /> : ""}
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-padding mb-30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mb-sm-5 mb-md-0 wow animate__animated animate__fadeInUp" data-wow-delay="0">
                                <h4 className="section-title style-1 mb-15 animated animated">Latest Leads
                                    <Link to={"/buy_leads"} className="show-all">
                                        More <i className="fi-rs-angle-right"></i>
                                    </Link></h4>
                                <div className="product-list-small animated animated" style={{ borderRadius: "15px" }}>
                                    {buyLeads.latestLeads.length ? <Marquee direction="down" inverseMarqueeItems={true} marqueeItems={buyLeads.latestLeads.map((el, i) => {
                                        return <p style={{ background: "white" }} key={i}><Link to={"/lead-detail/" + el.leadId} className="px-2" state={el}>
                                            <img width={30} src={el.countryFlag} alt={el.country} />
                                            <p className="lead-title">{el.leadTitle}</p>
                                            <span className="">{moment(el.createdAt).format("DD/MM/YYYY")}</span>
                                        </Link></p>
                                    })}
                                    /> : ''}
                                </div>
                            </div>
                            <div className="col-lg-6 mb-sm-5 mb-md-0 wow animate__animated animate__fadeInUp" data-wow-delay="0">
                                {/* <InquiryForm formTitle={"Fill Your Requirement"} /> */}
                                <h4 className="section-title style-1 mb-15 animated animated">International Leads
                                    <Link to={"/buy_leads"} className="show-all">
                                        More <i className="fi-rs-angle-right"></i>
                                    </Link></h4>
                                <div className="product-list-small animated animated" style={{ borderRadius: "15px" }}>
                                    {buyLeads.outLeads.length ? <Marquee direction="down" inverseMarqueeItems={true} marqueeItems={buyLeads.outLeads.map((el, i) => {
                                        return <p style={{ background: "white" }} key={i}><Link to={"/lead-detail/" + el.leadId} className="px-2" state={el}>
                                            <img width={30} src={el.countryFlag} alt={el.country} />
                                            <p className="lead-title">{el.leadTitle}</p>
                                            <span className="">{moment(el.createdAt).format("DD/MM/YYYY")}</span>
                                        </Link></p>
                                    })}
                                    /> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-padding mb-30 py-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mb-sm-5 mb-md-0 wow animate__animated animate__fadeInUp" data-wow-delay="0">
                                {smallBanners.length ? <SmallBanners data={smallBanners.filter((el, i)=> i % 2 === 0 )} delay={1010} /> : ""}
                            </div>
                            <div className="col-lg-6 mb-sm-5 mb-md-0 wow animate__animated animate__fadeInUp" data-wow-delay="0">
                                {smallBanners.length ? <SmallBanners data={smallBanners.filter((el, i)=> i % 2 !== 0 )} delay={1000} /> : ""}
                            </div>
                        </div>
                    </div>
                </section>

                {
                    categoryCards.map((e) => categoriesGroup.map((ele, i) => {
                        return ele.categoryGroup === e ? <CategoryCard key={i} groupData={ele} catData={categories.filter((el) => el.categoryGroup === ele._id)} mainData={categoryData.filter((el) => el.categoryGroup === ele._id)} /> : ""
                    }))
                }

                <section className="section-padding mb-30 py-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mb-sm-5 mb-md-0 wow animate__animated animate__fadeInUp" data-wow-delay="0">
                                {secondBanners.length ? <SmallBanners data={secondBanners.filter((el, i)=> i % 2 === 0 )} delay={1010} /> : ""}
                            </div>
                            <div className="col-lg-6 mb-sm-5 mb-md-0 wow animate__animated animate__fadeInUp" data-wow-delay="0">
                                {secondBanners.length ? <SmallBanners data={secondBanners.filter((el, i)=> i % 2 !== 0 )} delay={1000} /> : ""}
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section-padding pb-5">
                    <div className="container">
                        <div className="section-title wow animate__animated animate__fadeIn mb-2" data-wow-delay="0">
                            <h3 className="">Premium Brands</h3>
                        </div>
                        <hr style={{ height: "3px", marginTop: "0px", color: "rgb(30 151 216)", opacity: "1" }} />
                        <div className="row py-4 align-items-center">
                            <Slider {...preBrand} ref={(slider) => (customSlider.current = slider)}>
                                {premiumBrands.length
                                    ? premiumBrands.map((el, i) => {
                                        return <div key={i}>
                                            <span className={style.premiumItem}>
                                                <img src={api.companyUrl + el.companyLogo} alt="" />
                                            </span>
                                        </div> 
                                    })
                                    : ""}
                            </Slider>
                        </div>
                    </div>
                </section>


                <section className="section-padding pb-5">
                    <div className="container">
                        <div className="section-title wow animate__animated animate__fadeIn mb-2" data-wow-delay="0">
                            <h3 className="">Suppliers by Country</h3>
                        </div>
                        <hr style={{ height: "3px", marginTop: "0px", color: "rgb(30 151 216)", opacity: "1" }} />
                        <div className="row py-4 align-items-center">
                            {countriesList.length ? countriesList.map((el, i) => {
                                if (i < 5) {
                                    return <div key={i} className="col-md-2 col-sm-4 col-6">
                                        <CountryItem country={el} />
                                    </div>
                                }
                            }) : ""}
                            <div className="col-md-2 col-sm-4 col-6">
                                <CountryItem country={""} />
                            </div>
                        </div>
                    </div>
                </section >

                <section className="section-padding mb-30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 mb-sm-5 mb-md-0 wow animate__animated animate__fadeInUp" data-wow-delay="0">
                                <InquiryForm formTitle={"Fill Your Requirement"} />
                            </div>
                            <div className="col-lg-6 mb-sm-5 mb-md-0 wow animate__animated animate__fadeInUp" data-wow-delay="0" style={{ position: "relative" }}>
                                <h4 className="section-title style-1 mb-30 animated animated">Testimonials
                                    {/* <Link to={"/buy_leads"} className="show-all">
                                        More <i className="fi-rs-angle-right"></i>
                                    </Link> */}
                                </h4>
                                <Testimonials testimonials={testimonials} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
export default Home;