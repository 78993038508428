import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { api } from '../components/util/Data';
import { Link, useParams } from 'react-router-dom';
import { Pagination } from '@mui/material';
import moment from 'moment';

export default function LeadsPages() {
    const params = useParams()
    const [leadsList, setLeadsList] = useState({ totalLeads: 0, leads: [] })
    // const [loading, setLoading] = useState(true);
    const [listPage, setListPage] = useState(1);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        axios
            .post(api.url + "/get-leads-list", { page: listPage, key: params.key ? params.key : "" })
            .then(function (response) {
                // console.log(response);
                setLeadsList({ totalLeads: response.data.total, leads: response.data.leads })
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [listPage, params.country, params.key])

    const handlePageChange = (event, value) => {
        // setLoading(true)
        setListPage(value);
    };

    return (
        <div className="container px-0">
            <div className="page-header breadcrumb-wrap undefined mb-10"><div className="container"><div className="breadcrumb"><Link to="/">Home</Link><span></span> Buy Leads</div></div></div>

            <div className="row flex-row-reverse pb-10">
                <div className="col-lg-8  m-auto">
                    {leadsList.leads.length ? leadsList.leads.map((el, i) => {
                        return <div key={i} className="vendor-wrap my-3  style-2">
                            <div className="product-badges product-badges-position product-badges-mrg">
                                <span className='bg-white'><img style={{ border: "solid 1px gray", borderRadius: "5px", height: "20px" }} src={el.countryFlag} alt={el.country} /></span>
                            </div>
                            <div className="vendor-content-wrap row align-items-center g-2 w-100">
                                <div className="col-md-7">
                                    <div className="product-category">
                                        <span>ID: {el.leadId}</span>
                                    </div>
                                    <h4 className="mb-5"><Link to={"/lead-detail/" + el.leadId} state={el}>{el.leadTitle}</Link></h4>
                                    <div className="vendor-info d-flex justify-content-between align-items-end mt-1">
                                        <ul className="contact-infor">
                                            <li>{el.leadMessage.substring(0, 150) + "..."}<Link to={"/lead-detail/" + el.leadId} state={el}>more</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <p style={{ fontSize: "small" }}> <i className="fa-solid fa-globe"></i> Country : {el.country} </p>
                                    <p style={{ fontSize: "small" }} className="text-muted">Date Posted : {moment(el.createdAt).format("DD/MM/YYYY")}</p>
                                    <Link to={"/login"} className="btn btn-xs bg-main-secondary mx-1">Buy now</Link>
                                    <Link to={"/requirement"} className="btn btn-xs bg-main-secondary mx-1">Inquiry Request</Link>
                                </div>
                            </div>
                        </div>
                    }) : ""}
                <div style={{ display: "flex" }}>
                    <h5>Total : {leadsList.totalLeads}</h5>
                    <Pagination count={Math.ceil(leadsList.totalLeads / 20)} color="primary" onChange={handlePageChange} />
                </div>
                </div>
            </div>
        </div>
    )
}
