import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { Grid, TextField } from "@mui/material";
import { getLocalStorage } from "../../components/util/localStore";
import { getCookie } from "../../components/util/Cokkies";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { api } from "../../components/util/Data";
import axios from 'axios';

function CompanyDetails() {
  let token = getCookie("token");
  const user = getLocalStorage("user")
  const [formData, setFormData] = useState({});
  const [dataFetch, setDataFetch] = useState(false)
  const navigate = useNavigate()
  const planArray = [{ plan: "Guest", leads: 0 }, { plan: "Economy", leads: 10 }, { plan: "Gold", leads: 30 }, { plan: "Platinum", leads: 60 }, { plan: "Platinum Pro", leads: 80 }, { plan: "Global", leads: 100 }]

  useEffect(() => {
    axios.post(api.url + "/getDataForUpdateCompany", { id: user.id }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        console.log(response);
        setFormData(response.data.data)
        setDataFetch(true)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [dataFetch])

  const gotopage = (url) => {
    if(dataFetch){
      navigate(url, {state: formData })
    }
  }

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid d-flex justify-content-between">
          <h4 className="m-0 text-dark">Company Details</h4>
          <div className="actions">
          <Button size="sm" className="mx-2" variant="contained" onClick={(e)=>gotopage("/admin/change-user-password")}>
            Change Password
          </Button>
          <Button size="sm" variant="contained" onClick={(e)=>gotopage("/admin/update-details")}>
            Update Details
          </Button>
          </div>
        </div>
        <hr className="my-2" />
      </div>
      <div className="container px-4">
        {/* Login Information */}
        {dataFetch ?
          <Grid container spacing={1} className="form">
            {/* <UploadFile/> */}

            <Grid item className={"w-100"}>
              <img src={api.companyUrl + formData.companyLogo} alt="Company Logo" style={{ width: "200px", border: "solid 1px gray" }} />

              <h4>{formData.company}<small> ( {planArray[formData.membershipPlan].plan} Mambership )</small> </h4>
            </Grid>

            <Grid item md={4} sm={6} className={"w-100"}>
              <TextField disabled size="small" className={style.formInput} name="firstName" type="text" required label="First Name" margin="normal" defaultValue={formData.firstName} variant="outlined" />
            </Grid>

            <Grid item md={4} sm={6} className={"w-100"}>
              <TextField disabled size="small" className={style.formInput} name="lastName" type="text" required label="Enter Last Name" margin="normal" defaultValue={formData.lastName} variant="outlined" />
            </Grid>

            <Grid item md={4} sm={6} className={"w-100"}>
              <TextField disabled size="small" className={style.formInput} name="contact" type="number" required label="Enter Contact" margin="normal" defaultValue={formData.contact} variant="outlined" />
            </Grid>

            <Grid item md={4} sm={6} className={"w-100"}>
              <TextField disabled size="small" className={style.formInput} name="contact" type="text" required label="Enter Contact" margin="normal" defaultValue={formData.country} variant="outlined" />
            </Grid>

            <Grid item md={4} sm={6} className={"w-100"}>
              <TextField disabled size="small" className={style.formInput} name="email" type="text" required label="Email Address" margin="normal" defaultValue={formData.email} variant="outlined" />
            </Grid>

            <Grid item md={4} sm={6} className={"w-100"}>
              <TextField disabled size="small" className={style.formInput} name="company" required type="text" abel="Company Name" margin="normal" defaultValue={formData.company} variant="outlined" />
            </Grid>

            <Grid item md={4} sm={6} className={"w-100"}>
              <TextField disabled size="small" className={style.formInput} name="catalogLink" required type="text" label="Catalog Link" margin="normal" defaultValue={formData.catalogLink} variant="outlined" />
            </Grid>
            <Grid item md={4} sm={6} className={"w-100"}>
              <TextField disabled size="small" className={style.formInput} name="secBusiness" type="text" label="Secondary Business" margin="normal" defaultValue={formData.secBusiness} variant="outlined" />
            </Grid>
            <Grid item md={4} sm={6} className={"w-100"}>
              <TextField disabled size="small" className={style.formInput} name="companyCEO" required type="text" label="CEO Name" margin="normal" defaultValue={formData.companyCEO} variant="outlined" />
            </Grid>

            <Grid item md={4} sm={6} className={"w-100"}>
              <TextField disabled size="small" className={style.formInput} name="yearEstb" required type="text" label="Establishment Year" margin="normal" defaultValue={formData.yearEstb} variant="outlined" />
            </Grid>
            <Grid item md={4} sm={6} className={"w-100"}>
              <TextField disabled size="small" className={style.formInput} name="website" required type="text" label="Website Link" margin="normal" defaultValue={formData.website} variant="outlined" />
            </Grid>
            <Grid item md={4} sm={6} className={"w-100"}>
              <TextField disabled size="small" className={style.formInput} name="gstn" type="text" label="GST No." margin="normal" defaultValue={formData.gstn} variant="outlined" />
            </Grid>

            <Grid item md={4} sm={6} className={"w-100 "}>
              <TextField disabled size="small" className={style.formInput} name="businessType" type="text" label="Business Type." margin="normal" defaultValue={formData.businessType} variant="outlined" />
            </Grid>

            <Grid item sm={8} className={"w-100"}>
              <TextField disabled size="small" className={style.formInput} name="dealsIn" type="text" required label="Dealing Products" margin="normal" defaultValue={formData.dealsIn} variant="outlined" />
            </Grid>
            <Grid item md={4} sm={6} className={"w-100"}>
              <TextField disabled size="small" className={style.formInput} name="location" type="text" required label="Location" margin="normal" defaultValue={formData.location} variant="outlined" />
            </Grid>

            <Grid item sm={12} className={"w-100"}>
              <TextField disabled id="outlined-multiline-flexible" className={style.formInput} maxRows={4} required name="aboutCompany" defaultValue={formData.aboutCompany} label="About Company" multiline />
            </Grid>

            <Grid item sm={12} className={"w-100"}>
              <TextField disabled id="outlined-multiline-flexible" className={style.formInput} maxRows={4} required name="companyAddress" defaultValue={formData.companyAddress} label="Company Address" multiline />
            </Grid>

            <Grid item sm={3} className={"w-100"}>
              <TextField disabled size="small" className={style.formInput} name="facebook" type="text" label="Facebook Link" margin="normal" defaultValue={formData.facebook} variant="outlined" />
            </Grid>
            <Grid item sm={3} className={"w-100"}>
              <TextField disabled size="small" className={style.formInput} name="linkedIn" type="text" label="LinkedIn Link" margin="normal" defaultValue={formData.linkedIn} variant="outlined" />
            </Grid>

            <Grid item sm={3} className={"w-100"}>
              <TextField disabled size="small" className={style.formInput} name="twitter" type="text" label="Twitter Link" margin="normal" defaultValue={formData.twitter} variant="outlined" />
            </Grid>
            <Grid item sm={3} className={"w-100"}>
              <TextField disabled size="small" className={style.formInput} name="instagram" type="text" label="Instagram Link" margin="normal" defaultValue={formData.instagram} variant="outlined" />
            </Grid>
          </Grid>
          : ""}
      </div>
    </div>
  );
}

export default CompanyDetails;
