import React from 'react'
import { Link } from 'react-router-dom'
import style from './style.module.css'
export default function CountryItem(props) {
    const makeLink = (str) => {
        return str.replace(/^\s+|\s+$/g, '').replaceAll(" ", "_").toLowerCase();
    }

    return (
        props.country ?
            <Link to={"/country-directory/" + makeLink(props.country.name)} className={style.countryItem}>
                <div>
                    <Link to={"/country-directory/" + makeLink(props.country.name)}>
                        <img className="mx-auto" alt={props.country.name} src={props.country.flag} />
                    </Link>
                    <Link to={"/country-directory/" + makeLink(props.country.name)}>
                        <strong>{props.country.name}</strong>
                    </Link>
                </div>
            </Link>
            :
            <Link to={"/regional-directory"} className={style.countryItem}>
                <div>
                    <Link to={"/regional-directory"}>
                        <strong>More</strong>
                    </Link>
                </div>
            </Link>
    )
}
