import { useState } from "react";
import useClickOutside from "../util/outsideClick";
import Search from "../ecommerce/Search";
import { Link } from "react-router-dom";


const CatalogMobileMenu = ({ isToggled, toggleClick }) => {
    const [isActive, setIsActive] = useState({
        status: false,
        key: "",
    });

    const handleToggle = (key) => {
        if (isActive.key === key) {
            setIsActive({
                status: false,
            });
        } else {
            setIsActive({
                status: true,
                key,
            });
        }
    };

    let domNode = useClickOutside(() => {
        setIsActive({
            status: false,
        });
    });
    return (
        <>
            <div
                className={
                    isToggled
                        ? "mobile-header-active mobile-header-wrapper-style sidebar-visible"
                        : "mobile-header-active mobile-header-wrapper-style"
                }
            >
                <div className="mobile-header-wrapper-inner">
                    <div className="mobile-header-top">
                        <div className="mobile-header-logo">
                            <Link to="/">
                                    <img
                                        src="/logo.png"
                                        alt="logo"
                                    />
                            </Link>
                        </div>
                        <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
                            <button
                                className="close-style search-close"
                                onClick={toggleClick}
                            >
                                <i className="icon-top"></i>
                                <i className="icon-bottom"></i>
                            </button>
                        </div>
                    </div>
                    <div className="mobile-header-content-area">
                        <div className="mobile-search search-style-3 mobile-header-border">
                        <Search />
                        </div>
                        <div className="mobile-menu-wrap mobile-header-border">
                            <div className="main-categori-wrap mobile-header-border">
                                <Link to="#">
                                        <span className="fi-rs-apps"></span> For Buyers
                                </Link>

                                <Link to="/buy_leads">
                                        <span className="fi-rs-apps"></span> For Seller
                                </Link>

                                <Link to="/about">
                                        <span className="fi-rs-apps"></span> About Us
                                </Link>

                                <Link to="/login">
                                        <span className="fi-rs-apps"></span> User Panel
                                </Link>

                                <div className="categori-dropdown-wrap categori-dropdown-active-small">
                                    <ul>
                                        <li>
                                           <Link to="/shop-grid-right">
                                                    <i className="evara-font-dress"></i>
                                                    Women's Clothing
                                            </Link>
                                        </li>
                                        <li>
                                           <Link to="/shop-grid-right">
                                                    <i className="evara-font-tshirt"></i>
                                                    Men's Clothing
                                            </Link>
                                        </li>
                                        <li>
                                            {" "}
                                           <Link to="/shop-grid-right">
                                                    <i className="evara-font-smartphone"></i>{" "}
                                                    Cellphones
                                            </Link>
                                        </li>
                                        <li>
                                           <Link to="/shop-grid-right">
                                                    <i className="evara-font-desktop"></i>
                                                    Computer & Office
                                            </Link>
                                        </li>
                                        <li>
                                           <Link to="/shop-grid-right">
                                                    <i className="evara-font-cpu"></i>
                                                    Consumer Electronics
                                            </Link>
                                        </li>
                                        <li className="menu-item-has-children">
                                           <Link to="/shop-grid-right">
                                                    <i className="evara-font-home"></i>
                                                    Home & Garden
                                            </Link>
                                        </li>
                                        <li>
                                           <Link to="/shop-grid-right">
                                                    <i className="evara-font-high-heels"></i>
                                                    Shoes
                                            </Link>
                                        </li>
                                        <li>
                                           <Link to="/shop-grid-right">
                                                    <i className="evara-font-teddy-bear"></i>
                                                    Mother & Kids
                                            </Link>
                                        </li>
                                        <li>
                                           <Link to="/shop-grid-right">
                                                    <i className="evara-font-kite"></i>
                                                    Outdoor fun
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <nav>
                                <ul className="mobile-menu" ref={domNode}>
                                    {/* <li
                                        className={
                                            isActive.key == 1
                                                ? "menu-item-has-children active"
                                                : "menu-item-has-children"
                                        }
                                    >
                                        <span
                                            className="menu-expand"
                                            onClick={() => handleToggle(1)}
                                        >
                                            <i className="fi-rs-angle-small-down"></i>
                                        </span>
                                        <Link to="/index">
                                           Home
                                        </Link>
                                        <ul
                                            className={
                                                isActive.key == 1
                                                    ? "dropdown"
                                                    : "d-none"
                                            }
                                        >
                                            <li>
                                                <Link to="/index">
                                                   Home 1
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/index-2">
                                                   Home 2
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/index-3">
                                                   Home 3
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/index-4">
                                                   Home 4
                                                </Link>
                                            </li>
                                        </ul>
                                    </li> */}
                                    {/* <li
                                        className={
                                            isActive.key == 2
                                                ? "menu-item-has-children active"
                                                : "menu-item-has-children"
                                        }
                                    >
                                        <span
                                            className="menu-expand"
                                            onClick={() => handleToggle(2)}
                                        >
                                            <i className="fi-rs-angle-small-down"></i>
                                        </span>
                                       <Link to="/shop-grid-right">
                                           shop
                                        </Link>
                                        <ul
                                            className={
                                                isActive.key == 2
                                                    ? "dropdown"
                                                    : "d-none"
                                            }
                                        >
                                            <li>
                                               <Link to="/shop-grid-right">
                                                   
                                                        Shop Grid – Right
                                                        Sidebar
                                                    
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/shop-grid-left">
                                                   
                                                        Shop Grid – Left Sidebar
                                                    
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/shop-list-right">
                                                   
                                                        Shop List – Right
                                                        Sidebar
                                                    
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/shop-list-left">
                                                   
                                                        Shop List – Left Sidebar
                                                    
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/shop-fullwidth">
                                                   Shop - Wide
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/shop-filter">
                                                   Shop – Filter
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/shop-wishlist">
                                                   Shop – Wishlist
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/shop-cart">
                                                   Shop – Cart
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/shop-checkout">
                                                   Shop – Checkout
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/shop-compare">
                                                   Shop – Compare
                                                </Link>
                                            </li>
                                        </ul>
                                    </li> */}
                                </ul>
                            </nav>
                        </div>
                        <div className="mobile-header-info-wrap mobile-header-border">
                            <div className="single-mobile-header-info">
                                <Link to="/login">
                                   Log In / Sign Up 
                                </Link>
                            </div>
                            <div className="single-mobile-header-info text-dark">
                                +91-8810200550
                            </div>
                        </div>
                        <div className="mobile-social-icon">
                            <h5 className="mb-15 text-grey-4">Follow Us</h5>
                            <Link to="#">
                               
                                    <img
                                        src="/assets/imgs/theme/icons/icon-facebook.svg"
                                        alt=""
                                    />
                                
                            </Link>
                            <Link to="#">
                               
                                    <img
                                        src="/assets/imgs/theme/icons/icon-twitter.svg"
                                        alt=""
                                    />
                                
                            </Link>
                            <Link to="#">
                               
                                    <img
                                        src="/assets/imgs/theme/icons/icon-instagram.svg"
                                        alt=""
                                    />
                                
                            </Link>
                            <Link to="#">
                               
                                    <img
                                        src="/assets/imgs/theme/icons/icon-pinterest.svg"
                                        alt=""
                                    />
                                
                            </Link>
                            <Link to="#">
                               
                                    <img
                                        src="/assets/imgs/theme/icons/icon-youtube.svg"
                                        alt=""
                                    />
                                
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CatalogMobileMenu;
