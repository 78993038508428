import React from 'react'
import CatCardItem from './CatCardItem'
export default function DirCategoryCard(props) {
    return (

        <section className="section-padding pb-5">
            <div className="container">
                <div className="section-title wow animate__animated animate__fadeIn mb-2" data-wow-delay="0">
                    <h3 className="">{props.groupData.categoryGroup}</h3>
                </div>
                <hr style={{ height: "3px", marginTop: "0px", color: "rgb(30 151 216)", opacity: "1" }} />
                <div className="row">
                    {props.catData.map((el, i) => {
                        return el.categoryGroup === props.groupData._id ? <div key={i} className="col-lg-3 col-md-6 col-sm-6">
                            <CatCardItem data={el} />
                        </div> : ""
                    })}
                </div>
            </div>
        </section>
    )
}
