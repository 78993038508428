import React from 'react'
import Select from 'react-select'

export default function SelectJS(props) {
    const customThemeFn = (theme) => ({ 
        ...theme,
        spacing: {
          ...theme.spacing,
          controlHeight: 30,
          baseUnit: 2
        }
      })
      
      const colourStyles = {
        option: (base, state) => ({
          ...base,
          fontWeight: state.isSelected ? "400" : "400",
          zIndex: "100000 !important"
        }),
        control: provided => ({
          ...provided,
          fontWeight: "400"
        }),
        singleValue: provided => ({
          ...provided,
          fontWeight: "400"
        })
      }


  return (
    <Select onChange={(e)=>{props.selectedOption(e)}} placeholder={<div>{props.placeholder}</div>} defaultValue={props.defValue} theme={props.sm?customThemeFn:''} options={props.options} styles={colourStyles} />
  )
}
