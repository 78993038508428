import Slider from "react-slick";
import { api } from "../util/Data";
import { useRef } from "react";


const SmallBanners = (props) => {

    const slider = useRef(null);
    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: props.delay * 3,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <>
            <Slider ref={slider} {...settings} className="style-4 dot-style-1 dot-style-1-position-1">
                {props.data.map((item, i) => (
                    <div key={i}
                        className=" single-animation-wrap"
                    >
                        <a href={item.link} target="_blank" rel="noreferrer">
                            <img style={{ width: "100%", borderRadius: "30px" }} src={api.bannerUrl + item.image} alt="" />
                        </a>
                    </div>
                ))}
            </Slider>
        </>
    );
};

export default SmallBanners;
