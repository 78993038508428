import React, { useEffect, useState } from 'react'
import { api } from '../../components/util/Data';
import { useOutletContext } from "react-router-dom";
import axios from "axios";
import { Card } from 'antd';
import style from './style.module.css'

export default function CatalogProducts() {
  const { Meta } = Card;
  const [companyDetails] = useOutletContext();
  const [loading, setLoading] = useState(true)
  const [productsList, setProductsList] = useState([])

  useEffect(() => {
    setLoading(true)
    axios
      .post(api.url + "/get-products-for-catalog", { company: companyDetails._id })
      .then(function (response) {
        // console.log(response.data);
        setProductsList(response.data.products)
        setLoading(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [companyDetails._id])

  return (
    <div className="col-xl-10 m-auto">
      <div className="my-4">
        <h4 className="section-title style-1 mb-15"><span className='text-muted'>Our Products : </span></h4>

        {loading ? <h5>Loading...</h5> :
          <div className="row g-4">
            {productsList.map((el, i) => {
              return <div className="col-md-3">
                <div className={style.productCard}>
                  <div className="card">
                    <div className="img-head p-3" style={{ maxHeight: "250p", overflow: "hidden" }}>
                      <a href={"/product-detail/" + el.productLink} rel="noreferrer" target={"_blank"}>
                        <img alt={el.productName} src={api.productImg + el.image} className="card-img-top" />
                      </a>
                    </div>
                    <div className="card-body pt-0">
                      <a href={"/product-detail/" + el.productLink} rel="noreferrer" target={"_blank"}><h5 className="card-title">{el.productName}</h5></a>
                      <p className="card-text" style={{minHeight: "150px"}}>{el.productDetails.substring(0, 150)}...</p>
                      <a href={"/product-detail/" + el.productLink} rel="noreferrer" target={"_blank"} className="btn btn-primary py-1">View</a>
                    </div>
                  </div>
                </div>
              </div>
            })}
          </div>
        }
      </div>
    </div>
  )
}
