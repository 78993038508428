import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { api } from '../components/util/Data';
import { Link, useParams } from 'react-router-dom';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { Pagination } from '@mui/material';
import SingleProduct from '../components/ecommerce/SingleProduct';

export default function ProductPage() {
    const params = useParams()
    const [productsList, setProductList] = useState({ totalProducts: 0, products: [] })
    const [loading, setLoading] = useState(true);
    const [listPage, setListPage] = useState(1);
    const [typeFilterValue, setTypeFilterValue] = useState("");

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        axios
            .post(api.url + "/get-products-list", { page: listPage, bType: typeFilterValue, key: params.category ? `cat_${params.category.replaceAll("_", " ")}` : params.key.replaceAll("_", " ") })
            .then(function (response) {
                // console.log(response);
                const newList = response.data.product.sort((a, b) => b.sort - a.sort)
                newList.map((el) => {
                    console.log(el.sort);
                })
                setLoading(false)
                setProductList({ totalProducts: response.data.total, products: response.data.product })
            })
            .catch(function (error) {
                setLoading(false)
                console.log(error);
            });
    }, [listPage, params.category, params.key, typeFilterValue])


    const typeFilter = (value) => {
        setLoading(true)
        setTypeFilterValue(value)
    }

    const handlePageChange = (event, value) => {
        setLoading(true)
        setListPage(value);
    };

    return (
        <div className="container px-0">
            <div className="page-header breadcrumb-wrap undefined mb-20"><div className="container"><div className="breadcrumb"><Link to="/">Home</Link><span></span> Product List</div></div></div>

            <div className="p-10 px-5 row">
                <div className="col-lg-3 primary-sidebar sticky-sidebar">
                    <div className="sidebar-widget widget-category-2 mb-30 bg-white">
                        <h5 className="section-title style-3 mb-20">
                            Business Type : </h5>
                        <MenuList dense>
                            Service
                            <MenuItem onClick={(e) => typeFilter("Manufacturer")}>
                                <ListItemText inset>Manufacturer</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={(e) => typeFilter("Exporter")}>
                                <ListItemText inset>Exporter</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={(e) => typeFilter("Supplier")}>
                                <ListItemText inset>Supplier</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={(e) => typeFilter("Importer")}>
                                <ListItemText inset>Importer</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={(e) => typeFilter("Trader")}>
                                <ListItemText inset>Trader</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={(e) => typeFilter("Service Provider")}>
                                <ListItemText inset>Service Provider</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </div>
                </div>

                <div className="col-lg-9 row">
                    <h6 style={{ marginBottom: "15px" }}>Total result of <span className='text-brand'>{params.category ? params.category.replaceAll("_", " ") : params.key.replaceAll("_", " ")}</span> : {productsList.totalProducts}</h6>
                    {loading ? <h4>loading...</h4> : productsList.products.length ? productsList.products.map((el, i) => {
                        return <div className='col-md-4 col-sm-6' key={i}>
                            <SingleProduct product={el} />
                        </div>
                    }) : <h4>Result Not found</h4>}
                    <div style={{ display: "flex" }}>
                        <Pagination count={Math.ceil(productsList.totalProducts / 30)} color="primary" onChange={handlePageChange} />
                    </div>
                </div>
            </div>
        </div>
    )
}
