import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { api } from '../util/Data';

export default function CatalogHeader({ toggleClick, company }) {

  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY >= 100;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    });
  });
  return (
    <header className="header-area header-style-1 header-height-2">
      {/*Mobile Top Mini Header */}
      <div className="mobile-promotion">
        <ul>
          <li>
            Need help? Call Us :{" "}
            <strong>
              {" "}
              +91-8810200550
            </strong>
          </li>
        </ul>
      </div>


      {/*Top Mini Header */}
      <div
        className={
          scroll
            ? "header-bottom header-bottom-bg-color sticky-bar stick"
            : "header-bottom header-bottom-bg-color sticky-bar"
        }
      >
        <div className="container">
          <div className="header-wrap header-space-between position-relative">

            <div className="header-nav d-none d-lg-flex w-100">
              <div className="logo logo-width-1">
                <Link to={"/company/" + company.catalogLink} className='text-center'>
                  {company.companyLogo !== 'noImg.jpg' ? <><img style={{ filter: "drop-shadow(0px 0px 4px black)", height: "40px", width: "auto" }} src={api.companyUrl + company.companyLogo} alt="logo" /> <h6 style={{ whiteSpace: "nowrap" }}>{company.company}</h6></> : <h4 style={{ whiteSpace: "nowrap" }}>{company.company}</h4>
                  }
                </Link>
              </div>
              <strong className='text-dark' style={{ display: "inherit", width:"100%" }}>{company.gstn ? `GSTN: ${company.gstn}` : ""}  &nbsp; {company.showContact ? <> | &nbsp; Contact : {company.contact}</> : ""}</strong>
              <div className="header-right justify-content-end">
                <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading">
                  <nav>
                    <ul>
                      <li><Link to={"/company/" + company.catalogLink}>Home</Link> </li>
                      <li><Link to={"products"}>Products</Link> </li>
                      <li><Link to={"gallery"}>Gallery</Link> </li>
                      <li><Link to={"contact"}>Contact</Link> </li>
                      <li>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

            <div className="logo logo-width-1 d-block d-lg-none">
              <Link to="/" className='text-center'>
                {company.companyLogo !== 'noImg.jpg' ? <><img style={{ filter: "drop-shadow(0px 0px 4px black)", height: "40px", width: "auto" }} src={api.companyUrl + company.companyLogo} alt="logo" /> <h6 style={{ whiteSpace: "nowrap" }}>{company.company}</h6></> : <h4 style={{ whiteSpace: "nowrap" }}>{company.company}</h4>
                }
              </Link>
            </div>

            <div className="header-action-right d-block d-lg-none">
              <div className="header-action-2">
                <div className="header-action-icon-2 d-block d-lg-none">
                  <div
                    className="burger-icon burger-icon-white"
                    onClick={toggleClick}
                  >
                    <span className="burger-icon-top"></span>
                    <span className="burger-icon-mid"></span>
                    <span className="burger-icon-bottom"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
