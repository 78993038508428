import React from "react";
import { Link } from "react-router-dom";
import { api } from "../util/Data";

const CatCardItem = ({ data }) => {

    const makeLink = (str) => {
        return str.replace(/^\s+|\s+$/g, '').replaceAll(" ", "_").toLowerCase();
    }

    return (
        <>
            <div className="product-cart-wrap style-2 wow animate__animated animate__fadeInUp" data-wow-delay="0">
                <Link to={"/category-directory/" + makeLink(data.category)}>
                    <div className="product-img-action-wrap">
                        <h4 className="cat-title" style={{ color: "white", textShadow: "2px 2px 5px black" }}>{data.category}</h4>
                        <div className="product-img" style={{}}>
                            <img src={api.categoryImg + data.image} alt="" />
                        </div>
                    </div>
                </Link>
                <div className="product-content-wrap">
                    <div className="deals-content">
                        {data.items.map((el, i) => {
                            if (i < 5) {
                                return <h2 key={i}>
                                    <Link to={"/products/" + makeLink(el.subCategory)} style={{ display: "flex", flexWrap: "nowrap" }}>
                                        <span>-&nbsp;</span><span>{el.subCategory}</span>
                                    </Link>
                                </h2>
                            }
                        })}
                        <h2>
                            <Link to={"/category-directory/" + makeLink(data.category)} style={{ display: "flex", flexWrap: "nowrap" }}>
                                <span className="text-brand">- More <i class="fa-solid fa-angle-right"></i></span>
                            </Link>
                        </h2>
                    </div>
                </div>
            </div>
        </>
    );
};


export default CatCardItem;
