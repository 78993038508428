import React from 'react'
import { Link } from 'react-router-dom'
import CatCardItem from './CatCardItem'

export default function CategoryCard(props) {
    const makeLink = (str) => {
        return str.replace(/^\s+|\s+$/g, '').replaceAll(" ", "_").toLowerCase();
    }
    return (
        <section className="section-padding pb-5">
            <div className="container">
                <div className="section-title wow animate__animated animate__fadeIn mb-2" data-wow-delay="0">
                    <h3 className="">{props.groupData.categoryGroup}</h3>
                    <Link to={"/group-directory/" + makeLink(props.groupData.categoryGroup)} state={{ group: props.groupData, category: props.catData.filter((ele) => ele.categoryGroup === props.groupData._id), subData : props.subData}} className="show-all"> More <i className="fi-rs-angle-right"></i> </Link>
                </div>
                <hr style={{ height: "3px", marginTop: "0px", color: "rgb(30 151 216)", opacity: "1" }} />
                <div className="row">
                    {props.mainData.map((el, i) => {
                        if (i < 4) {
                            return <div key={i} className="col-lg-3 col-md-6 col-sm-6">
                                <CatCardItem data={el} />
                            </div>
                        }
                    })}
                </div>
            </div>
        </section>
    )
}
