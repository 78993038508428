import React, { useEffect, useState } from 'react'
import countriesArray from '../components/elements/country'
import CountryItem from '../components/elements/CountryItem'
import { Link } from 'react-router-dom'

export default function RegionalPage() {
  const [countriesList, setCountriesList] = useState([])
  const [countries, setcountries] = useState(["United States of America", "Canada", "India", "United Arab Emirates", "Saudi Arabia", "Malaysia", "Japan", "China", "Afghanistan", "Australia", "Bangladesh", "Brazil", "Hong Kong", "Italy", "Kuwait", "Sri Lanka", "Nepal", "Turkey", "Vietnam", "Bhutan", "Singapore", "France", "Indenasia", "Thailand", "Greece"])

  useEffect(() => {
    setCountriesList(countriesArray.filter(({ name: name1 }) => countries.some((e) => e === name1)))
  }, [countries])

  return (
    <div className="container p-0">
      <div className="page-header breadcrumb-wrap undefined mb-10 w-100"><div className="container"><div className="breadcrumb"><Link to="/">Home</Link><span></span> Regional Directory</div></div></div>
      <div className="col-lg-10 mx-auto row g-3 py-5">
        {countriesList.length ? countriesList.map((el, i) => {
          return <div key={i} className="col-md-2 col-sm-4 col-6">
            <CountryItem country={el} />
          </div>
        }) : ""}
      </div>
    </div>
  )
}
